<mat-toolbar class="secondary">
    <button mat-mini-fab color="" (click)="openNotificationEditor(null)" title="Add Notification">
        <mat-icon class="cursorPointer">add</mat-icon>
    </button>
    <span style="flex: 1 1 auto;"></span>
    <div>
        Notifications
    </div>
    <span style="flex: 1 1 auto;"></span>
    <mat-form-field style="width: 200px;font-size: 14px;">
        <mat-label>Order by</mat-label>
        <mat-select style="display: inline;" [(ngModel)]="orderBy" (selectionChange)="onOrderBySelectionChange($event)">
            <mat-option *ngFor="let orderByOption of orderByOptions" [value]="orderByOption.key">
                {{orderByOption.value}}
              </mat-option>
        </mat-select>
    </mat-form-field>
</mat-toolbar>
<div class="contentBox backgroundWhite overflowAuto" style="height: calc(100vh - 130px);">
    <div class="centered Padding5px" *ngIf="notificationList == null || notificationList.length == 0">
        <mat-label>No Notifications found</mat-label>
    </div>
    <div *ngIf="notificationList != null && notificationList.length > 0">
        <div *ngFor="let notification of notificationList">
            <div class="notification-row-grid borderBottomBlue" style="padding:10px;">
                <div>
                    <div class="Padding5px">
                        <label>{{notification.title}}</label>
                    </div>
                
                    <div style="margin-left:10px;">
                        <div>
                            {{notification.detail}}
                        </div>
                        <label>Start: </label> {{ (notification.startDateTime).replace('T', ' ') }}
                        &nbsp;&nbsp;<label>Expire: </label> {{ (notification.expireDateTime).replace('T', ' ') }}
                    </div>
                </div>
                <div>
                    <div class="alignRight Caption">
                        <button mat-icon-button style="margin-right:10px;" (click)="openNotificationEditor(notification)" title="Edit">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button (click)="deleteNotification(notification)" title="Delete">
                            <mat-icon class="cursorPointer">delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>            
        </div>
    </div>


    
</div>