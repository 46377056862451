<h2 mat-dialog-title class="DialogCaptionLabel">
    Worklist Settings Editor
</h2>
<mat-dialog-content>
    <div class="CaptionLabel">Time Filters:</div>
      <div *ngFor="let wlEditorTimeFilter of wlEditorTimeFilters" class="backgroundWhite">
          <div class="Padding5px">
            <div class="borderBottomBlue Padding5px">
                <label style="margin-left: 20px;width: 250px;">{{wlEditorTimeFilter.filterName}}</label>
                <mat-slide-toggle style="margin-left: 360px;"
                    [id]="wlEditorTimeFilter.wlTimeFilterId"
                    [checked]="!wlEditorTimeFilter.isDisabled" 
                    [ngModel]="!wlEditorTimeFilter.isDisabled" (ngModelChange)="wlEditorTimeFilter.isDisabled = $event"
                    (change)="onChangeWLTimeFilterSettings($event, wlEditorTimeFilter.wlTimeFilterId, wlEditorTimeFilter.filterName)"
                    [disabled]="wlEditorTimeFilter.wlTimeFilterId == 1">
                </mat-slide-toggle>
            </div>
          </div>
      </div>
      <div class="CaptionLabel">Consent Filters:</div>
      <div *ngFor="let wlSMSConsentFilter of wlEditorSMSConsentFilters" class="backgroundWhite">
        <div class="Padding5px">
          <div class="borderBottomBlue Padding5px">
              <label style="margin-left: 20px;width: 250px;">{{wlSMSConsentFilter.filterName}}</label>
              <mat-slide-toggle style="margin-left: 360px;"
                  [checked]="!wlSMSConsentFilter.isDisabled" 
                  [ngModel]="!wlSMSConsentFilter.isDisabled" (ngModelChange)="wlSMSConsentFilter.isDisabled = $event"
                  (change)="onChangeWLSMSConsentFilterSettings($event, wlSMSConsentFilter.wlsmsConsentFilterId, wlSMSConsentFilter.filterName)"
                  [disabled]="wlSMSConsentFilter.wlsmsConsentFilterId == 1"></mat-slide-toggle>
          </div>
        </div>
      </div>
</mat-dialog-content>

<mat-dialog-actions class="alignCenter">
    <button mat-raised-button class="btn-icon-default" style="margin-left:5px;" (click)="closeDialog(true)">Done
      <mat-icon>cancel</mat-icon>
    </button>   
</mat-dialog-actions>