<h2 mat-dialog-title class="DialogCaptionLabel">Customer Editor</h2>
<mat-dialog-content>
  <div class="alignCenter">
    <mat-form [formGroup]="customerEditorForm">
      <mat-form-field>
        <mat-label>Customer Name</mat-label>
        <input matInput type="text" formControlName="customerName"/>
        <mat-error>
          <div *ngIf="customerName.hasError('required')">
            Required
          </div>
          <div *ngIf="customerName.hasError('maxlength')">
            255 chars only
          </div>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Customer Short Name</mat-label>
        <input matInput type="text" formControlName="customerShortName"/>
        <mat-error>
          <div *ngIf="customerShortName.hasError('required')">
              Required
          </div>
          <div *ngIf="customerShortName.hasError('maxlength')">
            25 chars only
          </div>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Street Address</mat-label>
        <input matInput type="text" formControlName="streetAddress"/>
        <mat-error>
          <div *ngIf="streetAddress.hasError('required')">
            Required
          </div>
          <div *ngIf="streetAddress.hasError('maxlength')">
            255 chars only
          </div>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>City</mat-label>
        <input matInput type="text" formControlName="city"/>
        <mat-error>
          <div *ngIf="city.hasError('maxlength')">
            50 chars only
          </div>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field>
          <mat-label>Zipcode</mat-label>
          <input matInput type="text" formControlName="zipcode"/>
          <mat-error>
            <div *ngIf="zipcode.hasError('maxlength')">
              10 chars only
            </div>
          </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field>
          <mat-label>Country</mat-label>
          <input matInput type="text" formControlName="country"/>
          <mat-error>
            <div *ngIf="country.hasError('maxlength')">
              50 chars only
            </div>
          </mat-error>
      </mat-form-field>

      <br>
      <mat-form-field>
          <mat-label>State</mat-label>
          <input matInput type="text" formControlName="state"/>
          <mat-error>
            <div *ngIf="state.hasError('maxlength')">
              50 chars only
            </div>
          </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field style="font-size: 14px;">
        <mat-label>Region</mat-label>
        <mat-select style="display: inline;" formControlName="region">
            <mat-option *ngFor="let countryRegion of countryRegions" [value]="countryRegion.key">
                {{countryRegion.value}}
              </mat-option>
        </mat-select>
        <mat-error>
          <div *ngIf="region.hasError('required')">
            Required
          </div>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field>
          <mat-label>Phone</mat-label>
          <input matInput type="text" formControlName="phone"/>
          <mat-error>
            <div *ngIf="phone.hasError('maxlength')">
              12 chars only
            </div>
            <div *ngIf="phone.hasError('pattern')">
              Required format 310-227-7302
            </div>
          </mat-error>
      </mat-form-field>
      <br>      
      <mat-form-field>
          <mat-label>Install Start Date</mat-label>
          <input matInput [matDatepicker]="installStartDate" formControlName="installStartDate"/>
          <mat-datepicker-toggle matSuffix [for]="installStartDate"></mat-datepicker-toggle>
          <mat-datepicker #installStartDate></mat-datepicker>
      </mat-form-field>      
      <br>
      <mat-form-field>
          <mat-label>GoLive Date</mat-label>
          <input matInput [matDatepicker]="goLiveDate" formControlName="goLiveDate"/>
          <mat-datepicker-toggle matSuffix [for]="goLiveDate"></mat-datepicker-toggle>
          <mat-datepicker #goLiveDate></mat-datepicker>
      </mat-form-field>
      <mat-error>
        <div *ngIf="customerEditorForm.hasError('fromToDate')">
          Install date cannot be after golive date
        </div>
      </mat-error>      
    </mat-form>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="alignCenter">
  <button mat-raised-button class="btn-icon-default" style="margin-left:5px;" (click)="closeDialog(false)">Cancel
    <mat-icon>cancel</mat-icon>
  </button>   
  <button mat-raised-button class="btn-icon-default" style="margin-left:10px;margin-right: 5px;" [disabled]="!customerEditorForm.dirty" (click)="saveCustomer()">Save
    <mat-icon>save</mat-icon>
  </button>
</mat-dialog-actions>