// Angular
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

// Shared Lib
import { HandleErrorService, ApplicationConfig, ApiService, EnvService } from 'kscigcorelib';


@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService: ApiService,
    private envService: EnvService
  ) { }


  
  public clearCacheAll(): Observable<any> {
    return this.http.post(this.envService.apiUrl + '/ClearCache/all', null, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('clearCacheAll', []))
    );
  }

  public clearCacheByCustomer(customerId: string): Observable<any> {
    return this.http.post(this.envService.apiUrl + '/ClearCache/customer?ecid=' + this.apiService.secureApiQueryString(customerId), null, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('clearCacheByCustomer', []))
    );
  }

  public clearCacheByApi(api: string): Observable<any> {
    return this.http.post(this.envService.apiUrl + '/ClearCache/api?api=' + api, null, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('clearCacheByCustomer', []))
    );
  }
}
