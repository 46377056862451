// Angular
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,FormControl,Validators } from '@angular/forms';

// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Shared Lib
import { LoggingService, LoadingBarService, NotificationBarService } from 'kscigcorelib';
import { CustomerConnectionInfo } from 'kscigcorelib';

// Component
import { CustomerService } from '../customer.service';

@Component({
  selector: 'app-customer-connection-info-editor',
  templateUrl: './customer-connection-info-editor.component.html',
  styleUrls: ['./customer-connection-info-editor.component.css']
})
export class CustomerConnectionInfoEditorComponent implements OnInit {

  public customerConnectionInfoEditorForm: UntypedFormGroup;
  public customerConnectionInfoData:CustomerConnectionInfo;
 

  constructor(private loadingBarService: LoadingBarService,
    private loggingService:LoggingService,
    private notificationBarService: NotificationBarService,
    private customerService:CustomerService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CustomerConnectionInfoEditorComponent>, 
    @Inject(MAT_DIALOG_DATA) public inputData: CustomerConnectionInfo
    ) {       
      this.customerConnectionInfoEditorForm = this.fb.group({
        cloudDatabaseName: [inputData.CloudDatabaseName, [Validators.required, Validators.maxLength(50)]],
        onPremAPIServerURL: [inputData.OnPremAPIServerURL, [Validators.required, Validators.maxLength(255)]],
        onPremAPIServerIPAddress: [inputData.OnPremAPIServerIPAddress, [Validators.maxLength(100)]],
        cloudDomainName: [inputData.CloudDomainName, [Validators.required, Validators.maxLength(255)]],
        cloudTenantId: [inputData.CloudTenantId, [Validators.required, Validators.maxLength(255)]],
        idleTimeoutMinutes: [inputData.IdleTimeoutMinutes, [Validators.maxLength(6)]],
        isADT: [inputData.ADT],
        isSIU: [inputData.SIU]
      });
    }

  ngOnInit(): void {
    this.loadPageData();
  }

  private loadPageData(){
    if(this.inputData != null){
      this.loggingService.logVerbose("Edit ConnectionInfo");
      this.loggingService.logVerbose(this.inputData);
      this.customerConnectionInfoData = this.inputData;

    } else {
      this.loggingService.logVerbose("New ConnectionInfo");
    }
  }

  public closeDialog(refresh:boolean) {
    this.dialogRef.close(refresh);
  }

  get cloudDatabaseName() {
    return this.customerConnectionInfoEditorForm.get('cloudDatabaseName');
  }

  get onPremAPIServerURL() {
    return this.customerConnectionInfoEditorForm.get('onPremAPIServerURL');
  }

  get onPremAPIServerIPAddress() {
    return this.customerConnectionInfoEditorForm.get('onPremAPIServerIPAddress');
  }

  get cloudDomainName() {
    return this.customerConnectionInfoEditorForm.get('cloudDomainName');
  }

  get cloudTenantId() {
    return this.customerConnectionInfoEditorForm.get('cloudTenantId');
  }

  get idleTimeoutMinutes() {
    return this.customerConnectionInfoEditorForm.get('idleTimeoutMinutes');
  }


  private getCustomerConnectionInfoFormData():CustomerConnectionInfo{
    let customerConnectionInfo:CustomerConnectionInfo = new CustomerConnectionInfo();
    if(this.inputData.CustomerId != null){
      customerConnectionInfo.CustomerId = this.inputData.CustomerId;
    }
    customerConnectionInfo.CloudDatabaseName =  this.cloudDatabaseName.value;
    customerConnectionInfo.OnPremAPIServerURL = this.onPremAPIServerURL.value;
    customerConnectionInfo.OnPremAPIServerIPAddress =  this.onPremAPIServerIPAddress.value;
    customerConnectionInfo.CloudDomainName =  this.cloudDomainName.value;
    customerConnectionInfo.CloudTenantId =  this.cloudTenantId.value;
    customerConnectionInfo.IdleTimeoutMinutes =  this.idleTimeoutMinutes.value;
    customerConnectionInfo.ADT = this.customerConnectionInfoEditorForm.get('isADT').value;
    customerConnectionInfo.SIU = this.customerConnectionInfoEditorForm.get('isSIU').value;
    return customerConnectionInfo;
  }

  public saveCustomerConnectionInfo(){
    if (this.customerConnectionInfoEditorForm.valid) {
      let customerConnectionInfo:CustomerConnectionInfo = this.getCustomerConnectionInfoFormData();
      this.loggingService.logVerbose(customerConnectionInfo);
      this.loggingService.logVerbose("Adding Connection Info");
      this.customerService.setCustomerConnectionInfo(customerConnectionInfo)
        .subscribe({
            next: () => {  
                  this.notificationBarService.showSuccess("Saved Customer connection info");
                  this.loggingService.logInformation("Saved Customer connection info");
                  this.closeDialog(true);
                },
            error: () => {
                  this.notificationBarService.showError("Error Saving Customer connection info");
                  this.loggingService.logError("Error Saving Customer connection info");
                },
            complete: () => { this.loggingService.logVerbose("Completed Saving Customer connection info"); }
        });
    } else {
      this.loggingService.logVerbose("Form has errors.");
    } 
  }

}
