<body class="bodyBaseColor">
  <div class="app-grid-admin" *ngIf="!networkStatus">
    <div></div>
    <div style="margin-left: 30%;">
      <h2> Oh Snap! Please check your internet connection. <mat-icon>wifi_off</mat-icon> </h2>
    </div>
    <div></div>
  </div>

  <div class="app-grid-admin" *ngIf="networkStatus"> 

    <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
    <!-- Loading Bar -->
    <lib-loading-bar></lib-loading-bar>
    <div style="margin-top: 2px;">
      <mat-sidenav-container>
        <mat-sidenav #sidenav>
        <app-side-nav-list (sidenavClose)="sidenav.close()"></app-side-nav-list>
        </mat-sidenav>
        <mat-sidenav-content>
          <div class="bodyBaseColor">
              
              <router-outlet></router-outlet>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>
  </div>
  
  <noscript>
    <div style="text-align: center; color: #052850">
      <h2>JavaScript is not enabled!</h2>
    </div>
</noscript>
</body>