// Angular
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,FormControl,Validators } from '@angular/forms';

// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

// Shared Lib
import { LoggingService, LoadingBarService, NotificationBarService, WLSMSConsentFilter, WLTimeFilter, CustomerWLSMSConsentFilter, CustomerWLTimeFilter } from 'kscigcorelib';

// component
import { WorklistEditorData } from '../worklist-model';
import { WorklistService } from '../worklist.service';


@Component({
  selector: 'app-worklist-settings-editor',
  templateUrl: './worklist-settings-editor.component.html',
  styleUrls: ['./worklist-settings-editor.component.css']
})
export class WorklistSettingsEditorComponent implements OnInit {

  //public worklistSettingsEditorForm: UntypedFormGroup;
  public wlEditorData: WorklistEditorData;
  public wlEditorSMSConsentFilters: WLSMSConsentFilter[] = [];
  public wlEditorTimeFilters: WLTimeFilter[] = [];

  constructor(private loadingBarService: LoadingBarService,
    private loggingService:LoggingService,
    private worklistService:WorklistService,
    private notificationBarService: NotificationBarService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<WorklistSettingsEditorComponent>, 
    @Inject(MAT_DIALOG_DATA) public inputData: WorklistEditorData
    ) { }

  ngOnInit(): void {
    this.loadPageData();
  }

  private loadPageData(){
    this.loggingService.logVerbose(this.inputData);
    if(this.inputData != null && this.inputData.wlSMSConsentFilters != null && this.inputData.wlTimeFilters != null){
      this.loggingService.logVerbose("Edit Worklist settings");
      this.wlEditorData = this.inputData;
      this.wlEditorSMSConsentFilters = this.wlEditorData.wlSMSConsentFilters;
      this.wlEditorTimeFilters = this.wlEditorData.wlTimeFilters;
    } else {
      this.loggingService.logVerbose("New");
    }
  }

  public closeDialog(refresh:boolean) {
    this.dialogRef.close(refresh);
  }

  onChangeWLTimeFilterSettings(event:MatSlideToggleChange, wlTimeFilterId:number, wlTimeFilterName:string){
    var status:string;
    if(event.checked){
      status = " Enabled";
      this.loggingService.logVerbose("TimeFilter True: " + wlTimeFilterId);
    } else{
      status = " Disabled";
      this.loggingService.logVerbose("TimeFilter False" + wlTimeFilterId);
    }

    var customerWLTimeFilter:CustomerWLTimeFilter = new CustomerWLTimeFilter();
    customerWLTimeFilter.customerId = this.inputData.customerId;
    customerWLTimeFilter.wlTimeFilterId = wlTimeFilterId;
    customerWLTimeFilter.isEnabled = event.checked;

    this.worklistService.UpdateWLTimeFilter(customerWLTimeFilter)
      .subscribe({
          next: (bIsUpdated)=>{
                if(bIsUpdated){
                  this.notificationBarService.showSuccess("TimeFilter, " + wlTimeFilterName + status);
                  // There was some issue with Data binding on the toggle button so need to explicitly set this
                  this.wlEditorTimeFilters.forEach(wlEditorTimeFilter => {
                    if(wlEditorTimeFilter.wlTimeFilterId == wlTimeFilterId){
                      wlEditorTimeFilter.isDisabled = !event.checked;
                    }
                  });
                } else {
                  this.notificationBarService.showInfo("TimeFilter update failed");
                }
              },
          error: () => { 
                  this.loggingService.logError("TimeFilter update failed");
                  this.notificationBarService.showInfo("TimeFilter update failed");
                },
          complete: () => { this.loggingService.logVerbose("Completed TimeFilter update"); } 
      }); 

  }

  onChangeWLSMSConsentFilterSettings(event:MatSlideToggleChange, wlSMSConsentFilterId:number, wlSMSConsentFilterName:string){
    var status:string;
    if(event.checked){
      status = " Enabled";
      this.loggingService.logVerbose("SMSConsentFilter True: " + wlSMSConsentFilterId);
    } else{
      status = " Disabled";
      this.loggingService.logVerbose("SMSConsentFilter False: " + wlSMSConsentFilterId);
    }

    var customerWLSMSConsentFilter:CustomerWLSMSConsentFilter = new CustomerWLSMSConsentFilter();
    customerWLSMSConsentFilter.customerId = this.inputData.customerId;
    customerWLSMSConsentFilter.wlsmsConsentFilterId = wlSMSConsentFilterId;
    customerWLSMSConsentFilter.isEnabled = event.checked;
    
    this.worklistService.UpdateWLSMSConsentFilter(customerWLSMSConsentFilter)
      .subscribe({
          next: (bIsUpdated) => {
                if(bIsUpdated){
                  this.notificationBarService.showSuccess("ConsentFilter, " + wlSMSConsentFilterName + status);
                  // There was some issue with Data binding on the toggle button so need to explicitly set this
                  this.wlEditorSMSConsentFilters.forEach(wlEditorSMSConsentFilter => {
                    if(wlEditorSMSConsentFilter.wlsmsConsentFilterId == wlSMSConsentFilterId){
                      wlEditorSMSConsentFilter.isDisabled = !event.checked;
                    }
                  });
                } else {
                  this.notificationBarService.showInfo("ConsentFilter update failed");
                }
              },
          error: () => {
                this.loggingService.logError("ConsentFilter update failed");
                this.notificationBarService.showInfo("ConsentFilter update failed");
              },
          complete: () => { this.loggingService.logVerbose("Completed ConsentFilter update"); }
      }); 
  }
}
