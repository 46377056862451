import { Component } from '@angular/core';

import { CloudApiResponse, LoggingService, LoadingBarService, NotificationBarService, EncryptionService, Customer } from 'kscigcorelib'
import { CacheService } from './cache.service';
import { CustomerService } from 'src/app/customer/customer.service';

@Component({
  selector: 'app-application-cache',
  templateUrl: './application-cache.component.html',
  styleUrls: ['./application-cache.component.css']
})
export class ApplicationCacheComponent {

  clearCacheOptions: string[] = ['all', 'customer', 'api'];
  selectedClearCacheOption: string;
  selectedCustomerId:string;
  customerList: Customer[] = [];
  selectedApi:string;
  apiList:string[] = [ 'AuthAPI', 'AppCoreAPI', 'MediaAPI', 'NotifyAPI', 'WorklistAPI' ]

  constructor(
    private loggingService: LoggingService,
    private loadingBarService: LoadingBarService,
    private notificationBarService: NotificationBarService,    
    private cacheService: CacheService,
    private customerService: CustomerService,
    private encryptionService: EncryptionService
  ) { }

  ngOnInit(): void {
    this.getCustomers();
  }

  private getCustomers() {
    this.customerService.getCustomers(false)
      .subscribe({
          next: (result: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
                this.loggingService.logVerbose(decryptedPayload);
                this.customerList = JSON.parse(decryptedPayload);                                  
                this.loadingBarService.stopBar();                
              },
          error: () => { this.loggingService.logError("Error loading Customers"); },
          complete: () => { this.loggingService.logVerbose("Completed loading Customers"); }
      });
  }

  clearCache() {
    this.loadingBarService.startBar();
    if (this.selectedClearCacheOption == 'all') {
      this.cacheService.clearCacheAll()
        .subscribe({
            next: (result: CloudApiResponse) => {
                  let updated: boolean = result.payload;
                  if (!updated)
                    this.loggingService.logError("ClearCacheAll failed");                 
                },
            error: () => {
                    this.notificationBarService.showError("Error calling service");
                    this.loggingService.logError("Error in clearCacheAll");
                    this.loadingBarService.stopBar();
                },
            complete: () => { 
              this.loggingService.logVerbose("Completed clearCacheAll"); 
              this.notificationBarService.showSuccess("Completed Clear Cache All");
              this.loadingBarService.stopBar();
            }
        });
    } else if (this.selectedClearCacheOption == 'customer' && this.selectedCustomerId != undefined) {
      this.cacheService.clearCacheByCustomer(this.selectedCustomerId)
        .subscribe({
            next: (result: CloudApiResponse) => {
                  let updated: boolean = result.payload;
                  if (!updated)
                    this.loggingService.logError("ClearCachebyCustomer failed");                 
                },
            error: () => {
                    this.notificationBarService.showError("Error calling service");
                    this.loggingService.logError("Error in clearCacheByCustomer");
                    this.loadingBarService.stopBar();
                },
            complete: () => { 
                    this.loggingService.logVerbose("Completed clearCacheByCustomer"); 
                    this.notificationBarService.showSuccess("Completed Clear Cache By Customer");
                    this.loadingBarService.stopBar();
                  }
        });
      } else if (this.selectedClearCacheOption == 'api' && this.selectedApi != undefined) {
        this.cacheService.clearCacheByApi(this.selectedApi)
          .subscribe({
              next: (result: CloudApiResponse) => {
                    let updated: boolean = result.payload;
                    if (!updated)
                      this.loggingService.logError("ClearCacheByApi failed");                 
                  },
              error: () => {
                      this.notificationBarService.showError("Error calling service");
                      this.loggingService.logError("Error in clearCacheByApi");
                      this.loadingBarService.stopBar();
                  },
              complete: () => { 
                      this.loggingService.logVerbose("Completed clearCacheByApi"); 
                      this.notificationBarService.showSuccess("Completed Clear Cache By Api " + this.selectedApi);
                      this.loadingBarService.stopBar();
                    }
          });
        } else {
        this.loadingBarService.stopBar();
      }
  }
}
