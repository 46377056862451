// Angular
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,FormControl,Validators } from '@angular/forms';

// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Shared Lib
import { LoggingService, LoadingBarService, NotificationBarService } from 'kscigcorelib';
import { Contact } from 'kscigcorelib';

// component
import { ContactEditorData } from './../customer-model';
import { CustomerService } from '../customer.service';

@Component({
  selector: 'app-customer-contact-editor',
  templateUrl: './customer-contact-editor.component.html',
  styleUrls: ['./customer-contact-editor.component.css']
})
export class CustomerContactEditorComponent implements OnInit {

  public contactEditorForm: UntypedFormGroup;
  public contactEditorData:ContactEditorData;

  constructor(
    private loadingBarService: LoadingBarService,
    private loggingService:LoggingService,
    private notificationBarService: NotificationBarService,
    private customerService:CustomerService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CustomerContactEditorComponent>, 
    @Inject(MAT_DIALOG_DATA) public inputData: ContactEditorData
    ) { 
      this.contactEditorForm = this.fb.group({
        FirstName: [null, [Validators.required,Validators.maxLength(50), Validators.pattern("^[a-zA-Z ,.'-]*$")]],
        MiddleName: [null, [Validators.maxLength(50), Validators.pattern("^[a-zA-Z ,.'-]*$")]],
        LastName: [null, [Validators.maxLength(50), Validators.pattern("^[a-zA-Z ,.'-]*$")]],
        Alias: [null, [Validators.maxLength(50), Validators.pattern("^[a-zA-Z ,.'-]*$")]],
        Email: [null, [Validators.email]],
        Mobile: [null, [Validators.maxLength(12), Validators.pattern("^([0-9]{3})[-]?([0-9]{3})[-]?([0-9]{4})?")]],
      });
    }

  ngOnInit(): void {
    this.loadPageData();
  }

  private loadPageData(){
    this.loggingService.logVerbose(this.inputData);
    if(this.inputData != null && this.inputData.contactData != null  && this.inputData.contactData.ContactId != null){
      this.loggingService.logVerbose("Edit Contact");
      this.contactEditorData = this.inputData;
      this.setPageData();
    } else {
      this.loggingService.logVerbose("New Contact");
    }
  }

  public closeDialog(refresh:boolean) {
    this.dialogRef.close(refresh);
  }

  get FirstName() {
    return this.contactEditorForm.get('FirstName');
  }
  get MiddleName() {
    return this.contactEditorForm.get('MiddleName');
  }
  get LastName() {
    return this.contactEditorForm.get('LastName');
  }

  get Alias() {
    return this.contactEditorForm.get('Alias');
  }

  get Email() {
    return this.contactEditorForm.get('Email');
  }

  get Mobile() {
    return this.contactEditorForm.get('Mobile');
  }

  private setPageData(){
    if(this.contactEditorData.contactData != null){
      this.contactEditorForm.controls['FirstName'].setValue(this.contactEditorData.contactData.FirstName);
      this.contactEditorForm.controls['MiddleName'].setValue(this.contactEditorData.contactData.MiddleName);
      this.contactEditorForm.controls['LastName'].setValue(this.contactEditorData.contactData.LastName);
      this.contactEditorForm.controls['Alias'].setValue(this.contactEditorData.contactData.Alias);
      this.contactEditorForm.controls['Mobile'].setValue(this.contactEditorData.contactData.Mobile);
      this.contactEditorForm.controls['Email'].setValue(this.contactEditorData.contactData.Email);
    }
  }

  private getPageData():Contact{
    let contact:Contact = new Contact();
    if(this.inputData.contactData.ContactId != null){
      contact.ContactId = this.inputData.contactData.ContactId;
    }
    contact.FirstName =  this.FirstName.value;
    contact.MiddleName =  this.MiddleName.value;
    contact.LastName =  this.LastName.value;
    contact.Alias =  this.Alias.value;
    contact.Email =  this.Email.value;
    contact.Mobile =  this.Mobile.value;
    return contact;
  }

  public saveContact(){
    if (this.contactEditorForm.valid) {
      let contact:Contact = this.getPageData();
      this.loggingService.logVerbose(contact);
      // Add New
      if(this.inputData.contactData.ContactId == null){
        this.loggingService.logVerbose("Adding Contact");
        this.customerService.addCustomerContact(this.inputData.customerId, contact)
          .subscribe({
              next: () => {  
                    this.notificationBarService.showSuccess("Added " +  contact.FirstName + " " + contact.LastName);
                    this.loggingService.logInformation("Contact " +  contact.FirstName + " " + contact.LastName + " has been added");
                    this.closeDialog(true);
                  },
              error: () => {
                    this.notificationBarService.showError("Error adding " + contact.FirstName + " " + contact.LastName);
                    this.loggingService.logError("Error adding Contact " +  contact.FirstName + " " + contact.LastName);
                  },
              complete: () => { this.loggingService.logVerbose("Completed adding Contact " +  contact.FirstName + " " + contact.LastName); }
          });
      } else {
         // Update 
         this.loggingService.logVerbose("Updating Contact");
         this.customerService.updateCustomerContact(contact)
          .subscribe({
              next: () => {  
                    this.notificationBarService.showSuccess("Updated " +  contact.FirstName + " " + contact.LastName);
                    this.loggingService.logInformation("Contact " +  contact.FirstName + " " + contact.LastName + " has been updated");
                    this.closeDialog(true);
                  },
              error: () => {
                    this.notificationBarService.showError("Error updating " +  contact.FirstName + " " + contact.LastName);
                    this.loggingService.logInformation("Error updating Contact " +  contact.FirstName + " " + contact.LastName);
                  },
              complete: () => { this.loggingService.logVerbose("Completed updating Contact " +  contact.FirstName + " " + contact.LastName); }
          });
      }
    } else {
      this.loggingService.logVerbose("Form has errors.");
    } 
  }
}
