// Angular
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,FormControl,Validators } from '@angular/forms';

// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange } from '@angular/material/select';
import { MatCheckboxChange } from '@angular/material/checkbox';

// Shared Lib
import { CloudApiResponse, TranslationProvider, EncryptionService } from 'kscigcorelib';
import { LoggingService, LoadingBarService, NotificationBarService } from 'kscigcorelib';

import { NotifyTranslatorService } from '../notify-translator.service';

@Component({
  selector: 'app-notify-translator-editor',
  templateUrl: './notify-translator-editor.component.html',
  styleUrls: ['./notify-translator-editor.component.css']
})
export class NotifyTranslatorEditorComponent implements OnInit {

  public notifyTranslatorForm: UntypedFormGroup;
  public translationProviderData:TranslationProvider;

  constructor(
    private loadingBarService: LoadingBarService,
    private loggingService:LoggingService,
    private encryptionService:EncryptionService,
    private notificationBarService: NotificationBarService,
    private notifyTranslatorService:NotifyTranslatorService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<NotifyTranslatorEditorComponent>, 
    @Inject(MAT_DIALOG_DATA) public inputData: TranslationProvider
    ) { 
      this.notifyTranslatorForm = this.fb.group({
        providerName: [null, [Validators.required,Validators.maxLength(200)]],
        endPoint: [null, [Validators.required,Validators.maxLength(512)]],
        queryString: [null, [Validators.maxLength(512)]],
        token: [null, [Validators.maxLength(512)]],
        pricePerTranslation: [null, [Validators.pattern(/^[0-9]+(\.[0-9]{1,5})?$/)]],
      });
    }

    ngOnInit() {
      this.loadPageData();
     }
   
     private loadPageData(){
       if(this.inputData != null){
         this.loggingService.logVerbose("Edit Translator");
         this.loggingService.logVerbose(this.inputData);
         this.translationProviderData = this.inputData;
         this.setPageData();
       } else {
         this.loggingService.logVerbose("New Translator");
       }
     }

    private setPageData(){
      if(this.translationProviderData != null){
        this.notifyTranslatorForm.controls['providerName'].setValue(this.translationProviderData.TranslationProviderName);
        this.notifyTranslatorForm.controls['endPoint'].setValue(this.translationProviderData.EndPoint);
        var decryptedToken = this.translationProviderData.Token;
        if(this.translationProviderData.Token != null){
          decryptedToken = this.encryptionService.decryptUsingAES256(this.translationProviderData.Token); 
        }
        this.notifyTranslatorForm.controls['token'].setValue(decryptedToken);
        this.notifyTranslatorForm.controls['queryString'].setValue(this.translationProviderData.QueryString);
        this.notifyTranslatorForm.controls['pricePerTranslation'].setValue(this.translationProviderData.PricePerTranslation);
      }
     }

    public closeDialog(refresh:boolean) {
      this.dialogRef.close(refresh);
    }

    get providerName() {
      return this.notifyTranslatorForm.get('providerName');
    }

    get endPoint() {
      return this.notifyTranslatorForm.get('endPoint');
    }

    get queryString() {
      return this.notifyTranslatorForm.get('queryString');
    }

    get token() {
      return this.notifyTranslatorForm.get('token');
    }

    get pricePerTranslation() {
      return this.notifyTranslatorForm.get('pricePerTranslation');
    }

    public saveTranslator(){
      let translationProvider:TranslationProvider = this.getTranslatorFormData();

      // Update Translator
      this.notifyTranslatorService.updateTranslationProvider(translationProvider)
        .subscribe({
            next: (result: CloudApiResponse) => {  
                  this.notificationBarService.showSuccess("Updated " +  translationProvider.TranslationProviderName);
                  this.loggingService.logInformation("Translator " +  translationProvider.TranslationProviderName + " has been updated");
                  this.closeDialog(true);
                },
            error: () => {
                  this.notificationBarService.showError("Error updating " +  translationProvider.TranslationProviderName);
                  this.loggingService.logError("Error updating Translator " +  translationProvider.TranslationProviderName);
                },
            complete: () => { this.loggingService.logVerbose("Completed updating Translator " +  translationProvider.TranslationProviderName); }
        });
    }

    private getTranslatorFormData():TranslationProvider{
      let translationProvider:TranslationProvider = new TranslationProvider();
      translationProvider.TranslationProviderId = this.inputData.TranslationProviderId;
      translationProvider.TranslationProviderName = this.providerName.value;
      translationProvider.EndPoint = this.endPoint.value;
      translationProvider.QueryString = this.queryString.value;
      var encToken = this.token.value;
      if(this.token.value != null) {
        encToken = this.encryptionService.encryptUsingAES256(this.token.value);
      }
      translationProvider.Token = encToken;
      translationProvider.PricePerTranslation = Number(this.pricePerTranslation.value);
      return translationProvider;
    }
}
