// Angular
import { Component, OnInit } from '@angular/core';

// Material
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDialog } from '@angular/material/dialog';

// Shared Lib
import { LoggingService, LoadingBarService, NotificationBarService, EncryptionService, CloudApiResponse } from 'kscigcorelib';
import { ApplicationConfig, ApplicationConfigViewModel } from 'kscigcorelib';

// Component
import { MediaSettingsService } from '../media-settings.service';


@Component({
  selector: 'app-media-relaxation',
  templateUrl: './media-relaxation.component.html',
  styleUrls: ['./media-relaxation.component.css']
})
export class MediaRelaxationComponent implements OnInit {
  public mediaAppConfigs:ApplicationConfig[] = [];
  public mediaAppConfigsViewModel:ApplicationConfigViewModel[] = [];


  constructor(
    private loggingService: LoggingService,
    private loadingBarService: LoadingBarService,
    private notificationBarService: NotificationBarService,
    public dialog: MatDialog,
    private settingsService: MediaSettingsService,
    private encryptionService: EncryptionService
  ) { }

  ngOnInit(): void {
    this.loadingBarService.startBar();
    this.loadMediaConfigs();
  }

  private loadMediaConfigs() {    
    this.settingsService.getMediaApplicationConfigsByCategory("Relaxation")
      .subscribe({
          next: (result: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);      
                let applicationConfigs: ApplicationConfig[] = JSON.parse(decryptedPayload);
                this.loggingService.logVerbose(applicationConfigs);
                this.mediaAppConfigs = applicationConfigs;
                if(applicationConfigs != null){
                  applicationConfigs.forEach(applicationConfig => {
                    // Create the application Config View Model
                    let _mediaAppConfig = new ApplicationConfigViewModel();
                    _mediaAppConfig.Name = applicationConfig.Name;
                    _mediaAppConfig.Value = applicationConfig.IsEncrypted && applicationConfig.Value ? this.encryptionService.decryptUsingAES256(applicationConfig.Value) : applicationConfig.Value;
                    _mediaAppConfig.IsEncrypted = applicationConfig.IsEncrypted;
                    
                    this.mediaAppConfigsViewModel.push(_mediaAppConfig);
                    
                  });          
                }
                this.loadingBarService.stopBar(); 
              },
          error: () => {
                this.loggingService.logError("Error fetching Relaxation media configs");
                this.notificationBarService.showError("Error calling service");
                this.loadingBarService.stopBar(); 
              },
          complete: () => { this.loggingService.logVerbose("Completed fetching Relaxation media configs"); }
      });
  }

  private updateMediaConfig(appConfig: ApplicationConfig) {
    if(appConfig != null){      
      this.settingsService.setMediaApplicationConfig(appConfig)
        .subscribe({
            next: (result: CloudApiResponse) => {
                  let updated: boolean = result.payload;
                  this.loggingService.logVerbose(updated);
                  if(updated) {
                    this.mediaAppConfigs.find(m => m.Name == appConfig.Name).Value = appConfig.Value;
                    this.notificationBarService.showSuccess("Updated " + appConfig.Name);
                    this.loggingService.logInformation("Updated " + appConfig.Name);
                  } else {
                    this.notificationBarService.showError("Error Updating " + appConfig.Name);
                    this.loggingService.logError("Error Updating " + appConfig.Name);
                  }
                },
            error: () => {
                    this.notificationBarService.showError("Error calling service");
                    this.loggingService.logError("Error updating relaxation Media config " + appConfig.Name);
                },
            complete: () => { this.loggingService.logVerbose("Completed updating relaxation Media config " + appConfig.Name); }
        });
    } else {
      this.loggingService.logError("Error applicationConfig is null");
    }
  }

  public startEdit(appConfig: ApplicationConfigViewModel): void {
    appConfig.IsEditing = true;
  }

  public cancelEdit(appConfig: ApplicationConfigViewModel): void {
    let config = this.mediaAppConfigs.find(m => m.Name == appConfig.Name);
    if (config != undefined) {
      appConfig.Value = config.IsEncrypted && config.Value ? this.encryptionService.decryptUsingAES256(config.Value) : config.Value;
    } 
    appConfig.IsEditing = false;
  }

  public editConfig(appConfigVm: ApplicationConfigViewModel){
    this.loggingService.logVerbose(appConfigVm);
    
    let appConfig = new ApplicationConfig();
    appConfig.Name = appConfigVm.Name;
    appConfig.Value = appConfigVm.IsEncrypted && appConfigVm.Value ? this.encryptionService.encryptUsingAES256(appConfigVm.Value) : appConfigVm.Value;
    appConfig.IsEncrypted = appConfigVm.IsEncrypted;

    this.updateMediaConfig(appConfig);
    appConfigVm.IsEditing = false;
  }

  public syncMedia() {
    this.settingsService.syncMedia("Relaxation")
        .subscribe({
            next: (result: CloudApiResponse) => {
                  let updated: boolean = result.payload;
                  this.loggingService.logVerbose(updated);
                  if(updated) {
                    this.notificationBarService.showSuccess("Relaxation Media Sync completed successfully");
                    this.loggingService.logInformation("Relaxation Media Sync completed successfully");
                  } else {
                    this.notificationBarService.showError("Relaxation Media Sync Failed");
                    this.loggingService.logError("Relaxation Media Sync Failed");
                  }
                },
            error: () => {
                    this.notificationBarService.showError("Error calling service");
                    this.loggingService.logError("Relaxation Media Sync Failed");
                },
            complete: () => { this.loggingService.logVerbose("Completed Relaxation Media Sync"); }
        });
  }
}
