// Angular
import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';

// Material
import { MatDialog } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

// Shared Lib
import { CloudApiResponse, LoggingService, LoadingBarService, EncryptionService, Customer } from 'kscigcorelib';
import { UtilizationGraphData, GraphSeriesData, GraphSeriesDataItem, UtilizationDrillDownData, UtilizationDrillDownModalData } from 'kscigcorelib';

// Module
import { CustomerService } from '../customer/customer.service';
import { ReportsService } from './reports.service';

import { GraphDrilldownComponent } from '../reports/graph-drilldown/graph-drilldown.component';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  range = new FormGroup({
    start: new FormControl<Date | null>(new Date()),
    end: new FormControl<Date | null>(new Date()),
  });

  public customerList: Customer[] = [];
  public selectedCustomerId:string = "1";
  public showGraphProperty:boolean = true;
  public hideGraphProperty:boolean = false;
  public xAxisLabel:string ="Time";
  public yAxisLabel:string ="Number";
  public allFiltersValid:boolean = false;
  public graphSeriesData:GraphSeriesData[] = [];
  public utilizationDrillDownLogins:UtilizationDrillDownData[] = [];
  public utilizationDrillDownMedia:UtilizationDrillDownData[] = [];
  public utilizationDrillDownLoginFailed:UtilizationDrillDownData[] = [];

  public totalLogins:number = 0;
  public totalLoginsFailed:number = 0;
  public totalSMSSent:number = 0;
  public totalSMSFailed:number = 0;
  public totalEmailSent:number = 0;
  public totalEmailFailed:number = 0;
  public totalTranslations:number = 0;
  public totalMediaPlayed:number = 0;

  colorScheme = {
    domain:  [
      'rgb(255,255,0)', // yellow 
      'rgb(127,255,212)', // aqua marine
      'rgb(255,0,255)', // Magenta
      'rgb(128,0,0)', // Maroon
      'rgb(75,0,130)', // Indido
      'rgb(0,128,128)', // Teal 
      'rgb(128,128,0)', // olive
      'rgb(0,0,128)', // Navy
      'rgb(0, 0, 255)', // blue 
      'rgb(139,69,19)', // Saddle Brown
      'rgb(173,255,47)', // yellow green 
      'rgb(153,50,204)', // dark Orchid
    ]
  };

  constructor(
    private loggingService:LoggingService,
    private loadingBarService: LoadingBarService,
    private encryptionService: EncryptionService,
    private customerService: CustomerService,
    private reportsService: ReportsService,
    public dialog: MatDialog,
    @Inject(LOCALE_ID) public locale: string
  ) { 
  }

  ngOnInit(): void {
    this.getPageData();
  }

  getPageData(){
    this.loadingBarService.startBar();
    this.getCustomers();
  }

  private getCustomers() {
    this.customerService.getCustomers(false)
      .subscribe({
          next: (result: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
                this.loggingService.logVerbose(decryptedPayload);
                this.customerList = JSON.parse(decryptedPayload);  
                var noneCustomer:Customer = new Customer();
                noneCustomer.CustomerId = "1";
                noneCustomer.CustomerName = "None";
                this.customerList.push(noneCustomer);                                                    
                this.loadingBarService.stopBar();                
              },
          error: () => { this.loggingService.logError("Error loading Customers"); },
          complete: () => { this.loggingService.logVerbose("Completed loading Customers"); }
      });
  }

  customerFilterChanged(_event) {  
    if(this.range.controls.start.value != null && this.range.controls.end.value != null && this.selectedCustomerId !="1") {
      this.allFiltersValid = true;
      this.loadGraphData();
    } else {
      this.allFiltersValid = false;
    }
  }

  dateFilterChanged(_type: string, _event:MatDatepickerInputEvent<Date>) {
    if(this.range.controls.start.value != null && this.range.controls.end.value != null && this.selectedCustomerId !="1") {
      this.allFiltersValid = true;
      this.loadGraphData();
    } else {
      this.allFiltersValid = false;
    }
  }

  public loadGraphData(){
    var formattedStartDate = formatDate(this.range.controls.start.value, 'yyyy-MM-dd', this.locale);
    var formattedEndDate = formatDate(this.range.controls.end.value, 'yyyy-MM-dd', this.locale);
    
    this.reportsService.getUtilizationGraphData(this.selectedCustomerId, formattedStartDate, formattedEndDate)
    .subscribe({
        next: (result: CloudApiResponse) => {
              this.loggingService.logVerbose(result);
              var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
              //var decryptedPayload = result.payload;
              var utilizationData: UtilizationGraphData[] = JSON.parse(decryptedPayload);  
              this.loggingService.logVerbose(utilizationData);
              // Get Drill Down Data
              this.getDrillDownData(formattedStartDate, formattedEndDate);
              this.populateGraphData(utilizationData);
              this.loadingBarService.stopBar();                
            },
        error: () => { this.loggingService.logError("Error loading UtilizationGraphData"); },
        complete: () => { this.loggingService.logVerbose("Completed loading UtilizationGraphData"); }
    });
  }


  private populateGraphData(utilizationData:UtilizationGraphData[]){
    this.totalLogins = 0;
    this.totalSMSSent = 0;
    this.totalSMSFailed = 0;
    this.totalEmailSent = 0;
    this.totalEmailFailed = 0;
    this.totalTranslations = 0;
    this.totalMediaPlayed = 0;

    if(utilizationData != null){

      var graphSeriesData_Logins:GraphSeriesData = new GraphSeriesData();
      var graphSeriesData_SMSSent:GraphSeriesData = new GraphSeriesData();
      var graphSeriesData_EmailSent:GraphSeriesData = new GraphSeriesData();
      var graphSeriesData_MediaPlayed:GraphSeriesData = new GraphSeriesData();
      var graphSeriesData_Translations:GraphSeriesData = new GraphSeriesData();

      graphSeriesData_Logins.name = "Logins";
      graphSeriesData_SMSSent.name = "SMS Sent";
      graphSeriesData_EmailSent.name = "Email Sent";
      graphSeriesData_MediaPlayed.name = "Media Played (hrs)";
      graphSeriesData_Translations.name = "Translations";

      var graphSeriesDataItem_Logins:GraphSeriesDataItem = new GraphSeriesDataItem();
      var graphSeriesDataItem_SMSSent:GraphSeriesDataItem = new GraphSeriesDataItem();
      var graphSeriesDataItem_EmailSent:GraphSeriesDataItem = new GraphSeriesDataItem();
      var graphSeriesDataItem_Translations:GraphSeriesDataItem = new GraphSeriesDataItem();
      var graphSeriesDataItem_MediaPlayed:GraphSeriesDataItem = new GraphSeriesDataItem();

      utilizationData.forEach(_utilizationData => {
        graphSeriesDataItem_Logins = new GraphSeriesDataItem();
        graphSeriesDataItem_SMSSent = new GraphSeriesDataItem();
        graphSeriesDataItem_EmailSent = new GraphSeriesDataItem();
        graphSeriesDataItem_Translations = new GraphSeriesDataItem();
        graphSeriesDataItem_MediaPlayed = new GraphSeriesDataItem();

        graphSeriesDataItem_Logins.name = _utilizationData.UIDisplayTime;
        graphSeriesDataItem_SMSSent.name = _utilizationData.UIDisplayTime;
        graphSeriesDataItem_EmailSent.name = _utilizationData.UIDisplayTime;
        graphSeriesDataItem_Translations.name = _utilizationData.UIDisplayTime;
        graphSeriesDataItem_MediaPlayed.name = _utilizationData.UIDisplayTime;

        graphSeriesDataItem_Logins.value = _utilizationData.Logins;
        graphSeriesDataItem_SMSSent.value = _utilizationData.SMSSent;
        graphSeriesDataItem_EmailSent.value = _utilizationData.EmailSent;
        graphSeriesDataItem_Translations.value = _utilizationData.Translations;
        graphSeriesDataItem_MediaPlayed.value = _utilizationData.MediaPlayedDuration;

        graphSeriesData_Logins.series.push(graphSeriesDataItem_Logins);
        graphSeriesData_SMSSent.series.push(graphSeriesDataItem_SMSSent);
        graphSeriesData_EmailSent.series.push(graphSeriesDataItem_EmailSent);
        graphSeriesData_Translations.series.push(graphSeriesDataItem_Translations);
        graphSeriesData_MediaPlayed.series.push(graphSeriesDataItem_MediaPlayed);

        // Totals
        this.totalLogins += _utilizationData.Logins;
        this.totalLoginsFailed += _utilizationData.LoginFailed;
        this.totalSMSSent += _utilizationData.SMSSent;
        this.totalSMSFailed += _utilizationData.SMSFailed;
        this.totalEmailSent += _utilizationData.EmailSent;
        this.totalEmailFailed += _utilizationData.EmailFailed;
        this.totalTranslations += _utilizationData.Translations;
        this.totalMediaPlayed += _utilizationData.MediaPlayedDuration;

      });
      var _graphSeries:GraphSeriesData[] = [];
      _graphSeries.push(graphSeriesData_Logins);
      _graphSeries.push(graphSeriesData_SMSSent);
      _graphSeries.push(graphSeriesData_EmailSent);
      _graphSeries.push(graphSeriesData_Translations);
      _graphSeries.push(graphSeriesData_MediaPlayed);

      this.graphSeriesData = _graphSeries;
    } else {
      this.loggingService.logDebug("No graphing data");
    }
  }

  private getDrillDownData(formattedStartDate:string, formattedEndDate:string){
    this.reportsService.getUtilizationDrillDownData(this.selectedCustomerId, formattedStartDate, formattedEndDate, "Logins")
    .subscribe({
        next: (result: CloudApiResponse) => {
              var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
              this.utilizationDrillDownLogins = JSON.parse(decryptedPayload);  
              this.loggingService.logVerbose(this.utilizationDrillDownLogins);
              
              this.reportsService.getUtilizationDrillDownData(this.selectedCustomerId, formattedStartDate, formattedEndDate, "Media")
              .subscribe({
                  next: (result: CloudApiResponse) => {
                        var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
                        this.utilizationDrillDownMedia = JSON.parse(decryptedPayload);  
                        this.loggingService.logVerbose(this.utilizationDrillDownMedia);

                        this.reportsService.getUtilizationDrillDownData(this.selectedCustomerId, formattedStartDate, formattedEndDate, "LoginFailed")
                        .subscribe({
                            next: (result: CloudApiResponse) => {
                                  var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
                                  this.utilizationDrillDownLoginFailed = JSON.parse(decryptedPayload);  
                                  this.loggingService.logVerbose(this.utilizationDrillDownLoginFailed);
                                  this.loadingBarService.stopBar();                
                                },
                            error: () => { this.loggingService.logError("Error loading UtilizationDrillDownData Login Failed"); },
                            complete: () => { this.loggingService.logVerbose("Completed loading UtilizationDrillDownData Login Failed"); }
                        });
                      },
                  error: () => { this.loggingService.logError("Error loading UtilizationDrillDownData Media"); },
                  complete: () => { this.loggingService.logVerbose("Completed loading UtilizationDrillDownData Media"); }
              });
            },
        error: () => { this.loggingService.logError("Error loading UtilizationDrillDownData Login"); },
        complete: () => { this.loggingService.logVerbose("Completed loading UtilizationDrillDownData Login"); }
    });
  }

  public openDrillDownModal(keyword:string){
    this.loggingService.logVerbose("Open Drill Down Modal");
    var drillDownModalData:UtilizationDrillDownModalData = new UtilizationDrillDownModalData();
    drillDownModalData.Name = keyword.toUpperCase();
    if(keyword.toLowerCase() == "logins"){
      drillDownModalData.modalData = this.utilizationDrillDownLogins;
    } else if (keyword.toLowerCase() == "media"){
      drillDownModalData.modalData = this.utilizationDrillDownMedia;
    } else if (keyword.toLowerCase() == "loginfailed"){
      drillDownModalData.modalData = this.utilizationDrillDownLoginFailed;
    }

    const dialogRef = this.dialog.open(GraphDrilldownComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: drillDownModalData,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

}
