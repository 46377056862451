// Angular
import { Component, OnInit } from '@angular/core';
import { KeyValue } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';

// Material
import { MatDialog } from '@angular/material/dialog';

// Shared Lib
import { LoadingBarService, LoggingService, CoreHelper, CloudApiResponse, EncryptionService } from 'kscigcorelib';
import { Customer } from 'kscigcorelib';

// Shared App
import { appPageName } from '../shared/constants/app.enum';
import { RouteHelper } from '../shared/helpers/route.helper';

// Module
import { DashboardChartData, DashboardGroupChartData, groupByOptionEnum, activityTimeOptionEnum, installsTimeOptionEnum, subscriptionEndTimeOptionEnum } from './dashboard-model';
import { DashboardService } from './dashboard.service';
import { CustomerService } from '../customer/customer.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public groupByOptions:KeyValue<groupByOptionEnum,string>[] = [];
  public installsTimeOptions:KeyValue<installsTimeOptionEnum,string>[] = [];
  public activityTimeOptions:KeyValue<activityTimeOptionEnum,string>[] = [];
  public subscriptionEndTimeOptions:KeyValue<subscriptionEndTimeOptionEnum,string>[] = [];

  public groupBy:groupByOptionEnum;
  public groupByValue:string = "";
  public installsTime:installsTimeOptionEnum;
  public installTimeValue:string = "";
  public activityTime:activityTimeOptionEnum;
  public subscriptionEndTime:subscriptionEndTimeOptionEnum;

  public totalCustomers:number;

  dataCustomersPieChart: DashboardChartData[] = [];
  dataCustomerInstallBarChart: DashboardGroupChartData[] = [];
  dataCustomerActivity: DashboardChartData[] = [];
  dataCustomerSubscriptionEndList:Customer[];

  dualColorScheme = {
    domain:  [
      'rgb(0, 255, 0)', // green
      'rgb(255,165,0)', // Orange
    ]
  };
  colorScheme = {
    domain:  [
      'rgb(255,255,0)', // yellow 
      'rgb(127,255,212)', // aqua marine
      'rgb(255,0,255)', // Magenta
      'rgb(128,0,0)', // Maroon
      'rgb(75,0,130)', // Indido
      'rgb(0,128,128)', // Teal 
      'rgb(128,128,0)', // olive
      'rgb(0,0,128)', // Navy
      'rgb(0, 0, 255)', // blue 
      'rgb(139,69,19)', // Saddle Brown
      'rgb(173,255,47)', // yellow green 
      'rgb(153,50,204)', // dark Orchid
    ]
  };

  colorScheme2 = {
    domain:  [
      'rgb(173,255,47)', // yellow green 
      'rgb(210,105,30)', // Chocolate
      'rgb(127,255,212)', // aqua marine
      'rgb(255,20,147)', // deep pink
      'rgb(255,255,0)', // yellow 
      'rgb(0, 0, 255)', // blue 
      'rgb(255,0,255)', // Magenta
      'rgb(128,0,0)', // Maroon
      'rgb(75,0,130)', // Indido
      'rgb(0,128,128)', // Teal 
      'rgb(128,128,0)', // olive
      'rgb(0,0,128)', // Navy
    ]
  };

  numberCardDimension: any[] = [400, 300]
  
  constructor( 
    private loggingService:LoggingService,
    private loadingBarService: LoadingBarService,
    private routeHelper: RouteHelper,
    private coreHelper:CoreHelper,
    private dasdhboardService:DashboardService,
    private customerService:CustomerService,
    private encryptionService:EncryptionService,
    public dialog: MatDialog
    
    ) { 
      this.totalCustomers = 0;
    }

  ngOnInit(): void {
    // Start loading bar
    this.loadingBarService.startBar();
    this.loadPageConstants()
    // Load Page Data
    this.loadPageData();
  }

  private loadPageConstants(){
    let _groupByOptions:KeyValue<groupByOptionEnum,string>[] = [];
    _groupByOptions.push({key:groupByOptionEnum.Modules,value:"Modules"});
    _groupByOptions.push({key:groupByOptionEnum.Hl7Feed,value:"Hl7 Feed"});
    _groupByOptions.push({key:groupByOptionEnum.Region,value:"Region"});
    _groupByOptions.push({key:groupByOptionEnum.Status,value:"Status"});
    this.groupByOptions = _groupByOptions;
    this.groupBy = groupByOptionEnum.Modules;
    this.groupByValue = this.groupByOptions.find(x=>x.key == this.groupBy).value;

    let _installsTimeOptions:KeyValue<installsTimeOptionEnum,string>[] = [];
    _installsTimeOptions.push({key:installsTimeOptionEnum.LastSixMonths,value:"Last 6 Months"});
    _installsTimeOptions.push({key:installsTimeOptionEnum.YTD,value:"YTD"});
    _installsTimeOptions.push({key:installsTimeOptionEnum.LastYear,value:"Last Year"});
    _installsTimeOptions.push({key:installsTimeOptionEnum.Last3Year,value:"Last 3 Years"});
    _installsTimeOptions.push({key:installsTimeOptionEnum.Last5Year,value:"Last 5 Years"});
    _installsTimeOptions.push({key:installsTimeOptionEnum.All,value:"All"});
    this.installsTimeOptions = _installsTimeOptions;
    this.installsTime = installsTimeOptionEnum.LastSixMonths;
    this.installTimeValue = this.installsTimeOptions.find(x=>x.key == this.installsTime).value;
    
    let _activityTimeOptions:KeyValue<activityTimeOptionEnum,string>[] = [];
    _activityTimeOptions.push({key:activityTimeOptionEnum.CurrentMonth,value:"Current Month"});
    _activityTimeOptions.push({key:activityTimeOptionEnum.LastMonth,value:"Last Month"});
    _activityTimeOptions.push({key:activityTimeOptionEnum.LastThreeMonths,value:"Last 3 Months"});
    _activityTimeOptions.push({key:activityTimeOptionEnum.LastSixMonths,value:"Last 6 Months"});
    _activityTimeOptions.push({key:activityTimeOptionEnum.LastYear,value:"Last Year"});
    this.activityTimeOptions = _activityTimeOptions;
    this.activityTime = activityTimeOptionEnum.CurrentMonth;

    let _subscriptionEndTime:KeyValue<subscriptionEndTimeOptionEnum,string>[] = [];
    _subscriptionEndTime.push({key:subscriptionEndTimeOptionEnum.Next3Months,value:"Next 3 Months"});
    _subscriptionEndTime.push({key:subscriptionEndTimeOptionEnum.NextSixMonths,value:"Next 6 Months"});
    _subscriptionEndTime.push({key:subscriptionEndTimeOptionEnum.Next12Months,value:"Next 12 Months"});
    this.subscriptionEndTimeOptions = _subscriptionEndTime;
    this.subscriptionEndTime = subscriptionEndTimeOptionEnum.Next3Months;

  }

  private loadPageData(){
    this.loadTotalCustomerCount(true);
    this.loadDataForCustomerGroupByPieChart(this.groupByOptions.find(x=>x.key == this.groupBy).value);
    this.loadDataForCustomerInstallBarChart(this.installsTimeOptions.find(x=>x.key == this.installsTime).value);
    this.loadDataForCustomerActivity(this.activityTimeOptions.find(x=>x.key == this.activityTime).value);
    this.loadDataForCustomerSubscriptionEnd(this.subscriptionEndTimeOptions.find(x=>x.key == this.subscriptionEndTime).value);
  }

  private loadTotalCustomerCount(isActiveOnly:boolean){
    this.loadingBarService.startBar();
    this.totalCustomers = 0;
    this.customerService.getCustomersCount(isActiveOnly)
      .subscribe({
          next: (result: CloudApiResponse) => {                
                let total: number = result.payload;
                this.loggingService.logVerbose(total);
                this.totalCustomers = total;
                this.loadingBarService.stopBar();
              },
          error: () => {
                this.loggingService.logError("Error loading total customer count");
                this.loadingBarService.stopBar();
              },
          complete: () => { this.loggingService.logVerbose("Completed loading total customer count"); }
      }); 
  }

  private loadDataForCustomerGroupByPieChart(groupBy:string){
    this.loadingBarService.startBar();
    this.dasdhboardService.GetCustomerGroupByData(groupBy)
      .subscribe({
          next: (result: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload); 
                this.loggingService.logVerbose(decryptedPayload);
                let dashboardPieChartData: DashboardChartData[] = JSON.parse(decryptedPayload);                
                this.dataCustomersPieChart = dashboardPieChartData;
                this.loadingBarService.stopBar();
              },
          error: () => {
                this.loggingService.logError("Error loading group by data");
                this.loadingBarService.stopBar();
              },
          complete: () => { this.loggingService.logVerbose("Completed loading group by data - " + groupBy); }
      });
         
  }

  private loadDataForCustomerInstallBarChart(installDate:string){
    this.loadingBarService.startBar();
    installDate = (installDate.toLowerCase()).replace(' ', '').replace(' ', '');
    this.dasdhboardService.GetCustomerInstallData(installDate)
      .subscribe({
          next: (result: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);                          
                let installBarChartData: DashboardGroupChartData[] = JSON.parse(decryptedPayload);   
                this.loggingService.logVerbose(installBarChartData);
                this.loadingBarService.stopBar();
                this.dataCustomerInstallBarChart = installBarChartData;
              },
          error: () => {
                this.loggingService.logError("Error loading install data");
                this.loadingBarService.stopBar();
              },
          complete: () => { this.loggingService.logVerbose("Completed loading install data"); }
      }); 
  }

  private loadDataForCustomerActivity(timeRange:string){
    this.loadingBarService.startBar();
          
    this.dataCustomerActivity = [];
    this.dasdhboardService.GetCustomerActivityData(timeRange)
      .subscribe({
          next: (result: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);      
                let data: DashboardChartData[] = JSON.parse(decryptedPayload);
                this.loggingService.logVerbose(data);
                this.dataCustomerActivity = data;
                this.loadingBarService.stopBar();
              },
          error: ()=> {
                this.loggingService.logError("Error loading subscription end list");
                this.loadingBarService.stopBar();
              },
          complete: () => { this.loggingService.logVerbose("Completed loading subscription end list"); }
      });     
  }

  private loadDataForCustomerSubscriptionEnd(timeRange:string){
    this.loadingBarService.startBar();
    this.dataCustomerSubscriptionEndList = [];
    this.dasdhboardService.GetSubscriptionEndList(timeRange)
      .subscribe({
          next: (result: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);      
                let subscriptionEndList: Customer[] = JSON.parse(decryptedPayload);
                this.loggingService.logVerbose(subscriptionEndList);
                this.dataCustomerSubscriptionEndList = subscriptionEndList;
                this.loadingBarService.stopBar();
              },
          error: ()=> {
                this.loggingService.logError("Error loading subscription end list");
                this.loadingBarService.stopBar();
              },
          complete: () => { this.loggingService.logVerbose("Completed loading subscription end list"); }
      }); 
  }

  onSelectCustomersPieChart(data): void {
    this.loggingService.logVerbose('Item clicked');
    this.loggingService.logVerbose(data);
    this.loggingService.logVerbose(this.groupByValue);
    let queryString = "filter=1&groupBy=" + this.groupByValue + "&groupByItem=" + data.name;
    this.routeHelper.NavigatePage(appPageName.customer, queryString);
  }

  onSelectCustomerInstallBarChart(data): void {
    this.loggingService.logVerbose('Item clicked');
    this.loggingService.logVerbose(data);
    this.loggingService.logVerbose(this.installTimeValue);
    let queryString = "filter=1&installDate=" + this.installTimeValue + "&time=" + data.series + "&state=" + data.name;
    this.routeHelper.NavigatePage(appPageName.customer, queryString);
  }

  onSelectCustomerActivityBarChart(data): void {
    this.loggingService.logVerbose('Item clicked' + JSON.parse(JSON.stringify(data)));
  }

  public onGroupBySelectionChange(event:Event){
    this.groupByValue = this.groupByOptions.find(x=>x.key == this.groupBy).value;
    this.loggingService.logVerbose(this.groupByValue);
    this.loadDataForCustomerGroupByPieChart(this.groupByValue);
    if(this.groupByValue == "Status"){
      this.loadTotalCustomerCount(false);
    } else {
      this.loadTotalCustomerCount(true);
    }
  }

  public onInstallationsDataSelectionChange(event:Event){
    this.installTimeValue = this.installsTimeOptions.find(x=>x.key == this.installsTime).value;
    this.loggingService.logVerbose(this.installTimeValue);
    this.loadDataForCustomerInstallBarChart(this.installTimeValue);
  }

  public onActivityDataSelectionChange(event:Event){
    let activityTime:string = '';
    activityTime = this.activityTimeOptions.find(x=>x.key == this.activityTime).value;
    this.loggingService.logVerbose(activityTime);
    this.loadDataForCustomerActivity(activityTime);
  }

  public onSubscriptionDataSelectionChange(event:Event){
    let subscriptionEndTime:string = '';
    subscriptionEndTime = this.subscriptionEndTimeOptions.find(x=>x.key == this.subscriptionEndTime).value;
    this.loggingService.logVerbose(subscriptionEndTime);
    this.loadDataForCustomerSubscriptionEnd(subscriptionEndTime);
  }

  public getDateOnly(datetime:string):string{
    return this.coreHelper.getDateOnly(datetime);
  }

  public getMatIcon(name: string) {
    if (name == "Login Count") {
      return "account_circle"
    } else if (name == "SMS Sent") {
      return "sms"
    } else if (name == "Email Sent") {
      return "email"
    } else if (name == "Media Played (hrs)") {
      return "theaters"
    }
  }
}
