// Angular
import { validateHorizontalPosition } from '@angular/cdk/overlay';
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,FormControl,Validators } from '@angular/forms';
import { KeyValue } from '@angular/common';

// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';

// Shared Lib
import { LoggingService, LoadingBarService, NotificationBarService, CoreHelper, CloudApiResponse } from 'kscigcorelib';
import { Notification } from 'kscigcorelib';

// Shared
import { CustomDateValidator } from '../../shared/helpers/CustomDateValidator';

// Component
import { NotificationService } from '../notification.service';
import { NotificationEditorData } from '../notification-model';
import { min } from 'rxjs/operators';

@Component({
  selector: 'app-notification-editor',
  templateUrl: './notification-editor.component.html',
  styleUrls: ['./notification-editor.component.css']
})
export class NotificationEditorComponent implements OnInit {

  public notificationEditorForm: UntypedFormGroup;
  public notificationEditorData:NotificationEditorData;

  public startTimeHourList:KeyValue<number,string>[] = [];
  public startTimeMinuteList:KeyValue<number,string>[] = [];
  public expireTimeHourList:KeyValue<number,string>[] = [];
  public expireTimeMinuteList:KeyValue<number,string>[] = [];

  constructor(private loadingBarService: LoadingBarService,
    private loggingService:LoggingService,
    private notificationBarService: NotificationBarService,
    private notificationService:NotificationService,
    private coreHelper:CoreHelper,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<NotificationEditorComponent>, 
    @Inject(MAT_DIALOG_DATA) public inputData: Notification
    ) { 
      this.notificationEditorForm = this.fb.group({
        title: [null, [Validators.required,Validators.maxLength(1024)]],
        detail: [null, [Validators.required,Validators.maxLength(4096)]],
        startDateTime: [null, [Validators.required]],
        startTimeHour: [null, [Validators.required]],
        startTimeMinute: [null, [Validators.required]],
        expireDateTime: [null, [Validators.required]],
        expireTimeHour: [null, [Validators.required]],
        expireTimeMinute: [null, [Validators.required]],
      }, {
          validator : [
            CustomDateValidator.startToEndDate('startDateTime', 'expireDateTime')
          ]
        });
    }

    ngOnInit(): void {
      this.loadPageData();
    }

    private loadPageData(){
      this.loggingService.logVerbose(this.inputData);
      this.loadPageConstants();
      if(this.inputData != null){
        this.loggingService.logVerbose("Edit Notification");
        this.notificationEditorData = this.getEditorData(this.inputData);
        this.loggingService.logVerbose(this.notificationEditorData);
      }
      else {
        this.loggingService.logVerbose("New Notification");
      }
      this.setPageData();
    }
  
    private getEditorData(notificationData:Notification): NotificationEditorData{
      let notificationEditorData:NotificationEditorData = new NotificationEditorData();
      notificationEditorData.notificationId = notificationData.notificationId;
      notificationEditorData.title = notificationData.title;
      notificationEditorData.detail = notificationData.detail;
      notificationEditorData.startDateTime = notificationData.startDateTime;
      notificationEditorData.startTimeHour = this.getHourFromDate(notificationData.startDateTime);
      notificationEditorData.startTimeMinute = this.getMinuteFromDate(notificationData.startDateTime);
      notificationEditorData.expireDateTime = notificationData.expireDateTime;
      notificationEditorData.expireTimeHour = this.getHourFromDate(notificationData.expireDateTime);
      notificationEditorData.expireTimeMinute = this.getMinuteFromDate(notificationData.expireDateTime);
      return notificationEditorData;
    }

    public closeDialog(refresh:boolean) {
      this.dialogRef.close(refresh);
    }

    get title() {
      return this.notificationEditorForm.get('title');
    }

    get detail() {
      return this.notificationEditorForm.get('detail');
    }

    get startDateTime() {
      return this.notificationEditorForm.get('startDateTime');
    }

    get startTimeHour() {
      return this.notificationEditorForm.get('startTimeHour');
    }

    get startTimeMinute() {
      return this.notificationEditorForm.get('startTimeMinute');
    }

    get expireDateTime() {
      return this.notificationEditorForm.get('expireDateTime');
    }

    get expireTimeHour() {
      return this.notificationEditorForm.get('expireTimeHour');
    }

    get expireTimeMinute() {
      return this.notificationEditorForm.get('expireTimeMinute');
    }

    private setPageData(){
      if(this.notificationEditorData != null && this.notificationEditorData.notificationId != null){
        this.notificationEditorForm.controls['title'].setValue(this.notificationEditorData.title);
        this.notificationEditorForm.controls['detail'].setValue(this.notificationEditorData.detail);
        this.notificationEditorForm.controls['startDateTime'].setValue(this.notificationEditorData.startDateTime);
        this.notificationEditorForm.controls['startTimeHour'].setValue(this.notificationEditorData.startTimeHour);
        this.notificationEditorForm.controls['startTimeMinute'].setValue(this.notificationEditorData.startTimeMinute);
        this.notificationEditorForm.controls['expireDateTime'].setValue(this.notificationEditorData.expireDateTime);
        this.notificationEditorForm.controls['expireTimeHour'].setValue(this.notificationEditorData.expireTimeHour);
        this.notificationEditorForm.controls['expireTimeMinute'].setValue(this.notificationEditorData.expireTimeMinute);
      } else {
        this.notificationEditorForm.controls['startTimeHour'].setValue(0);
        this.notificationEditorForm.controls['startTimeMinute'].setValue(0);
        this.notificationEditorForm.controls['expireTimeHour'].setValue(0);
        this.notificationEditorForm.controls['expireTimeMinute'].setValue(0);
      }
    }

    private loadPageConstants(){
      let _hour:KeyValue<number,string>[] = []
          , _min:KeyValue<number,string>[] = [];
      let iHr = 0, iMM = 0, iHrValue="", iMMValue="";
      while(iHr<24){
        if(iHr < 10){
          iHrValue = 0 + iHr.toString();
        } else {
          iHrValue = iHr.toString();
        }
        _hour.push({key:iHr,value:iHrValue});
        iHr++;
      }
      while(iMM<60){
        if(iMM< 10){
          iMMValue = 0 + iMM.toString();
        } else {
          iMMValue = iMM.toString();
        }
        _min.push({key:iMM,value:iMMValue});
        iMM++;
      }
      this.startTimeHourList = _hour;
      this.startTimeMinuteList = _min;
      this.expireTimeHourList = _hour;
      this.expireTimeMinuteList = _min;
    }

    private getPageData():Notification{
      let notification:Notification = new Notification();
      if(this.inputData != null){
        notification.notificationId = this.inputData.notificationId;
      }
      notification.title =  this.title.value;
      notification.detail =  this.detail.value;
      notification.startDateTime =  
        this.concatenateDateTime(new Date(this.startDateTime.value).toLocaleString(), 
                                  this.startTimeHourList.find(x=>x.key == this.startTimeHour.value).value, 
                                  this.startTimeMinuteList.find(x=>x.key == this.startTimeMinute.value).value);

      notification.expireDateTime =  
        this.concatenateDateTime(new Date(this.expireDateTime.value).toLocaleString(), 
                                  this.expireTimeHourList.find(x=>x.key == this.expireTimeHour.value).value, 
                                  this.expireTimeMinuteList.find(x=>x.key == this.expireTimeMinute.value).value);
      return notification;
    }

    public saveNotification(){
      if (this.notificationEditorForm.valid) {
        let notification:Notification = this.getPageData();
        this.loggingService.logVerbose(notification);
        // Add New
        if(notification.notificationId == null){
          this.loggingService.logVerbose("Adding Notification");
          this.notificationService.addNotification(notification)
            .subscribe({
                next: (result: CloudApiResponse) => {  
                      this.notificationBarService.showSuccess("Added Notification");
                      this.loggingService.logInformation("Added Notification " +  notification.detail);
                      this.closeDialog(true);
                    },
                error: () => {
                      this.notificationBarService.showError("Error adding Notification");
                      this.loggingService.logError("Error adding Notification " +  notification.detail);
                    },
                complete: () => { this.loggingService.logVerbose("Completed adding Notification " +  notification.detail); }
            });
        } else {
           // Update 
           this.loggingService.logVerbose("Updating Notification");
           this.notificationService.updateNotification(notification)
            .subscribe({
                next: (result: CloudApiResponse) => {  
                      this.notificationBarService.showSuccess("Updated Notification");
                      this.loggingService.logInformation("Updated Notification " +  notification.detail);
                      this.closeDialog(true);
                    },
                error: () => {
                      this.notificationBarService.showError("Error updating Notification");
                      this.loggingService.logError("Error updating Notification " +  notification.detail);
                    },
                complete: () => { this.loggingService.logVerbose("Completed updating Notification " +  notification.detail); }
            });
        }
      } else {
        this.loggingService.logVerbose("Form has errors.");
      } 
    }

    private concatenateDateTime(date:string, hr:string, minute:string){
      let dateTime = "";
      if(date != null && date != ""){
        date = this.coreHelper.getDateOnly(date);
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
        year = d.getFullYear();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
      }
      date = [year, month, day].join('-');
      dateTime = date + "T" + hr + ":" + minute;
      return dateTime;
    }

    private getHourFromDate(date:string){
      let hr:number = 0, hrStr:string = "";
      hrStr = this.coreHelper.getTimeOnly(date).split(":")[0];
      hr = this.startTimeHourList.find(x=>x.value == hrStr).key;
      return hr;
    }

    private getMinuteFromDate(date:string){
      let min:number = 0, minStr:string = "";
      minStr = this.coreHelper.getTimeOnly(date).split(":")[1];
      min = this.startTimeMinuteList.find(x=>x.value == minStr).key;
      return min;
    }
}