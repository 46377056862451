// Angular
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

// Material
import { MatDialog } from '@angular/material/dialog';

// Shared Lib
import { LoggingService, LoadingBarService, NotificationBarService, CloudApiResponse } from 'kscigcorelib';
import { SMSProvider, EncryptionService } from 'kscigcorelib';

// Component
import { SettingsService } from '../settings.service';
import { NotifySMSService } from './notify-sms.service';
import { NotifySMSProviderEditorComponent } from './notify-smsprovider-editor/notify-smsprovider-editor.component';

@Component({
  selector: 'app-notify-sms',
  templateUrl: './notify-sms.component.html',
  styleUrls: ['./notify-sms.component.css']
})
export class NotifySMSComponent implements OnInit {

  public smsProviderList:SMSProvider[] = [];

  constructor(
    private loggingService: LoggingService,
    private loadingBarService: LoadingBarService,
    private notificationBarService: NotificationBarService,
    private notifySMSService:NotifySMSService,
    private encryptionService:EncryptionService,
    public dialog: MatDialog,
    ) { 
    }

  ngOnInit(): void {
    this.loadingBarService.startBar();
    // Load Page Data
    this.loadPageData();
  }

  private loadPageData(){
    this.loadSMSProviderList(true);    
  }

  private loadSMSProviderList(stopLoadingbar:boolean){
    this.notifySMSService.getAllSMSProviders()
      .subscribe({
          next: (result: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);      
                this.smsProviderList = JSON.parse(decryptedPayload);
                this.loggingService.logVerbose(this.smsProviderList);
                 
                if(stopLoadingbar){
                  this.loadingBarService.stopBar(); 
                }
              },
          error: () => {
                this.notificationBarService.showError("Error calling service");
                this.loadingBarService.stopBar(); 
                this.loggingService.logError("Error fetching SMS Provider list");
              },
          complete: () => { this.loggingService.logVerbose("Completed fetching SMS Provider list"); this.loadingBarService.stopBar();}
      });
  }

  public softDeleteSMSProvider(smsProvider: SMSProvider){
    this.loggingService.logInformation(smsProvider);
    this.loadingBarService.startBar();
    this.notifySMSService.softDeleteSMSProvider(smsProvider)
      .subscribe({
          next: (result: CloudApiResponse) => {
                this.loadSMSProviderList(false);
                this.notificationBarService.showSuccess("Deleted " +  smsProvider.SMSProviderName);
                this.loggingService.logInformation("Provider " +  smsProvider.SMSProviderName + " has been deleted");
              },
          error: () => {
                this.notificationBarService.showError("Error deleting " +  smsProvider.SMSProviderName);
                this.loggingService.logError("Error deleting provider " +  smsProvider.SMSProviderName);
              },
          complete: () => { this.loggingService.logVerbose("Completed deleting provider " +  smsProvider.SMSProviderName); this.loadingBarService.stopBar();}
      });
  }

  public undoSoftDeleteSMSProvider(smsProvider: SMSProvider){
    this.loggingService.logInformation(smsProvider);
    this.loadingBarService.startBar();
    this.notifySMSService.undoSoftDeleteSMSProvider(smsProvider)
      .subscribe({
          next: (result: CloudApiResponse) => {
                this.loadSMSProviderList(false);
                this.notificationBarService.showSuccess("Undone delete " +  smsProvider.SMSProviderName);
                this.loggingService.logInformation("Provider " +  smsProvider.SMSProviderName + " has been undeleted");
              },
          error: () => {
                this.notificationBarService.showError("Error undeleting " +  smsProvider.SMSProviderName);
                this.loggingService.logInformation("Error undeleting provider " +  smsProvider.SMSProviderName);
              },
          complete: () => { this.loggingService.logVerbose("Completed undeleting provider " +  smsProvider.SMSProviderName); this.loadingBarService.stopBar();}
      });
  }

  public openSMSProviderEditor(smsProvider: SMSProvider){
    this.loggingService.logVerbose("Open Editor");
    this.loggingService.logVerbose(smsProvider);

    const dialogRef = this.dialog.open(NotifySMSProviderEditorComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: smsProvider,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The dialog was closed');
      if(result){
        this.loadSMSProviderList(false);
      }
    });
  }
}
