<h2 mat-dialog-title class="DialogCaptionLabel">
    Translator Editor
  </h2>
  <mat-dialog-content>
  
    <div class="alignCenter">
      <mat-form [formGroup]="notifyTranslatorForm">
        <mat-form-field>
          <mat-label>Provider Name</mat-label>
          <input matInput type="text" placeholder="Ex. Microsoft" formControlName="providerName"/>
          <mat-error>
            <div *ngIf="providerName.hasError('required')">
              Required
            </div>
            <div *ngIf="providerName.hasError('maxlength')">
              200 chars only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>End point</mat-label>
          <input matInput type="text" formControlName="endPoint"/>
          <mat-error>
            <div *ngIf="endPoint.hasError('required')">
                Required
            </div>
            <div *ngIf="endPoint.hasError('maxlength')">
              512 chars only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Query String</mat-label>
          <input matInput type="text" formControlName="queryString"/>
          <mat-error>
            <div *ngIf="queryString.hasError('maxlength')">
              512 chars only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Token</mat-label>
          <input matInput type="text" formControlName="token"/>
          <mat-error>
            <div *ngIf="token.hasError('maxlength')">
              512 chars only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Price Per Translation</mat-label>
          <input matInput type="text" formControlName="pricePerTranslation"/>
          <mat-error>
            <div *ngIf="pricePerTranslation.hasError('pattern')">
              Number Only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
      </mat-form>
    </div>
  
  </mat-dialog-content>
  
  <mat-dialog-actions class="alignCenter">
    <button mat-raised-button class="btn-icon-default" style="margin-left:5px;" (click)="closeDialog(false)">Cancel
      <mat-icon>cancel</mat-icon>
    </button>   
    <button mat-raised-button class="btn-icon-default" style="margin-left:10px;margin-right: 5px;" [disabled]="!notifyTranslatorForm.dirty || !notifyTranslatorForm.valid" (click)="saveTranslator()">Save
      <mat-icon>save</mat-icon>
    </button>
  </mat-dialog-actions>