// Angular
import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';

// Shared Lib
import { LoggingService, SessionService, AuthenticatedUserInfo, appRoles  } from 'kscigcorelib';

@Injectable({
  providedIn: 'root'
})

export class SessionHelper {
    constructor(
        private loggingService:LoggingService,
        private sessionService:SessionService,
        private msalService:MsalService,
    ){ }

    public setLoggedInUser(accountInfo:AuthenticatedUserInfo){
        this.sessionService.setLoggedInUser(JSON.stringify(accountInfo));
    }
    
    public getLoggedInUser():AuthenticatedUserInfo{
        var msalActiveUser:AccountInfo = this.getMSALActiveUser();
        var userAccount:AuthenticatedUserInfo = JSON.parse(this.sessionService.getLoggedInUser(true));
        if(msalActiveUser != null && userAccount != null && userAccount.Email != '' && userAccount.Email == msalActiveUser.username) {
            return userAccount;
        }
        return null;
    }

    public isValidUserSession():boolean{
        if(this.isUserLoggedIn() && this.sessionService.getSessionId(false) != null) {
            return true;
        } else {
            return false;
        }
    }

    public isAdminRole():boolean{
        var isAdmin:boolean = false;
        var userInfo:AuthenticatedUserInfo = this.getLoggedInUser();
        if(userInfo && userInfo.Role == appRoles.configAppAdmin){
            isAdmin = true;
        }
        return isAdmin;
    }

    public isUserRole():boolean{
        var isAdmin:boolean = false;
        var userInfo:AuthenticatedUserInfo = this.getLoggedInUser();
        if(userInfo && userInfo.Role == appRoles.configAppUser){
            isAdmin = true;
        }
        return isAdmin;
    }

    public isValidUserRole():boolean{
        var isAdmin:boolean = false;
        var userInfo:AuthenticatedUserInfo = this.getLoggedInUser();
        if(userInfo && (userInfo.Role == appRoles.configAppUser || userInfo.Role == appRoles.configAppAdmin)){
            isAdmin = true;
        }
        return isAdmin;
    }

    private isUserLoggedIn(){
        var userAccount:AuthenticatedUserInfo = this.getLoggedInUser();
        if(userAccount != null){
            return true;
        } else {
            this.loggingService.logError("User not authenticated.");
        }
        return false;
    }

    private getMSALActiveUser():AccountInfo{
        let userAccount:AccountInfo = this.msalService.instance.getActiveAccount();
        if(userAccount != null){
            this.loggingService.logVerbose(userAccount);
            return userAccount;
        } else {
            this.loggingService.logDebug("No User is logged in.");
        }
        return null;
    }
}