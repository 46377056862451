<div>
<div class="backgroundWhite overflowAuto" style="height: calc(100vh - 39px)">

    <div>
        <div class="CaptionLabelSquare media-header-grid">
            <mat-label>Actions</mat-label>
        </div>
        <div>            
            <button mat-raised-button class="btn-icon-default" style="margin: 15px; padding: 5px 10px;" (click)="syncMedia()">Sync Media
                <!-- <mat-icon>Save</mat-icon> -->
            </button>
        </div>
    </div>
        
    <div *ngIf="this.mediaPortalsLoaded" class="borderBottomBlue">
        <div class="CaptionLabelSquare borderBlue">
            <mat-label>Portals</mat-label>
              
            <mat-icon (click)="addNewBlankPortalRow()" title="Add Row" style="cursor: pointer;">add</mat-icon>
        </div>
        
        <table mat-table [dataSource]="mediaPortalListViewModel">
            <ng-container [matColumnDef]="col.key" *ngFor="let col of portalColumnsSchema">
              <th mat-header-cell *matHeaderCellDef>
                {{ col.label }}
              </th>
              <td mat-cell *matCellDef="let element; let i = index" style="width: 40%;">
                <div [ngSwitch]="col.type" *ngIf="!element.IsEdit">
                  <div class="btn-edit" *ngSwitchCase="'IsEdit'">                    
                    <button mat-icon-button *ngIf="element.IsDeleted" (click)="undoDeletePortalRow(element.ExMediaPortalId)"><mat-icon>undo</mat-icon></button>
                    <button mat-icon-button *ngIf="!element.IsDeleted" (click)="deletePortalRow(element.ExMediaPortalId)"><mat-icon>delete</mat-icon></button>
                  </div>
                  
                  <span *ngSwitchDefault>
                    {{ element[col.key] }}
                  </span>
                </div>
                <div [ngSwitch]="col.type" *ngIf="element.IsEdit">
                  <div class="btn-edit" *ngSwitchCase="'IsEdit'">
                    <button mat-icon-button (click)="saveNewPortalRow(i)"><mat-icon>done</mat-icon></button>
                    <button mat-icon-button (click)="removeNewPortalRow(i)"><mat-icon>cancel</mat-icon></button>
                  </div>                  
                  <mat-form-field *ngSwitchDefault>
                    <input [type]="col.type" matInput [(ngModel)]="element[col.key]" />
                  </mat-form-field>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="portalDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: portalDisplayedColumns"></tr>
          </table>
        
    </div>

    <div>
        
        <div class="CaptionLabelSquare ">
            <mat-label>Configurations</mat-label>
        </div>
        <div class="CaptionLabelSquare media-config-grid borderBlue" >
            <div>Name</div>
            <div>Value</div>
            <div>Action</div>
        </div>
        <div *ngFor="let appConfig of mediaAppConfigsViewModel" >
            <div class="media-config-grid borderBottomBlue">
                <div class="Padding10px">
                    <label>{{appConfig.Name}}</label>
                </div>
                <div class="Padding10px">
                    <div *ngIf="!appConfig.IsEditing">{{appConfig.Value}}</div>
                    <input *ngIf="appConfig.IsEditing" type="text" [(ngModel)]="appConfig.Value" style="width: 250px"/>
                </div>
                <div>
                    <div class="edit-mode Caption" *ngIf="!appConfig.IsEditing">
                        <button mat-icon-button (click)="startEdit(appConfig)" title="Edit">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                    <div class="edit-mode Caption" *ngIf="appConfig.IsEditing">
                        <button mat-icon-button (click)="editConfig(appConfig)" title="Update">
                            <mat-icon>update</mat-icon>
                        </button>
                        <button mat-icon-button (click)="cancelEdit(appConfig)" title="Cancel">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="mediaAppConfigs == null || mediaAppConfigs.length == 0" class="Padding10px Centered">
            No Configuration found
        </div>
    </div>

</div>
</div> 