// Angular
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

// Shared Lib
import { Customer, HandleErrorService, ApiService, EnvService } from 'kscigcorelib';

import { DashboardChartData, DashboardGroupChartData } from './dashboard-model';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService: ApiService,
    private envService: EnvService
  ) { }

  public GetCustomerGroupByData(groupBy:string): Observable<any> {
    return this.http.get<DashboardChartData[]>(this.envService.apiUrl + '/Dashboard/GetCustomerGroupByData?groupBy=' + groupBy, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('GetCustomerGroupByData', []))
    );
  }

  public GetCustomerInstallData(installDate:string): Observable<any> {
    return this.http.get<DashboardGroupChartData[]>(this.envService.apiUrl + '/Dashboard/GetCustomerInstallData?installDate=' + installDate, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('GetCustomerInstallData', []))
    );
  }

  public GetSubscriptionEndList(timeRange:string): Observable<any> {
    return this.http.get<Customer[]>(this.envService.apiUrl + '/Dashboard/GetSubscriptionEndList?timeRange=' + timeRange, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('GetSubscriptionEndList', []))
    );
  }

  public GetCustomerActivityData(timeRange:string): Observable<any> {
    return this.http.get<DashboardChartData[]>(this.envService.apiUrl + '/Dashboard/GetCustomerActivityData?timeRange=' + timeRange, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('GetCustomerActivityData', []))
    );
  }
}
