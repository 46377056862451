// Angular
import { Component, OnInit } from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';

// SharedLib
import { LoggingService, CoreHelper } from 'kscigcorelib';
import { UITreeInterface, UITreeInterfaceFlatNode } from 'kscigcorelib';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  public selectedNodeName:string; 

  private TREE_DATA: UITreeInterface[] = [
    {
      name: 'Application',
      children: [
        {name: 'Cache', expandable:false, level:0}        
      ]
    }, {
      name: 'Notify',
      children: [
        {name: 'Email', expandable:false, level:0},
        {name: 'SMS', expandable:false, level:0},
        {name: 'Translator', expandable:false, level:0},
      ]
    }, {
      name: 'Media',
      children: [
        // {name: 'Relaxation', expandable:false, level:0},
        {name: 'Premier', expandable:false, level:0}
      ]
    },  
  ];
  
  private _transformer = (node: UITreeInterface, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<UITreeInterfaceFlatNode>(node => node.level, node => node.expandable);
  treeFlattener = new MatTreeFlattener(this._transformer, node => node.level, node => node.expandable, node => node.children);
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  constructor(
    private loggingService:LoggingService,
    private coreHelper:CoreHelper
  ) {
    this.dataSource.data = this.TREE_DATA;
    this.selectedNodeName = "Settings"
  }

  hasChild = (_: number, node: UITreeInterfaceFlatNode) => node.expandable;

  ngOnInit(): void {
    this.treeControl.expandAll();
  }

  treeNodeSelected(node:UITreeInterfaceFlatNode){
    this.loggingService.logVerbose(node.name);
    this.selectedNodeName = node.name;
  }

  getDisplayTreeNodeName(nodeName:string) :string{
    return this.coreHelper.getDisplayTreeNodeName(nodeName);
  }
}
