// Angular
import { Injectable, Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subject, Subscription, merge, of, fromEvent } from 'rxjs';
import { filter, takeUntil, map } from 'rxjs/operators';
import { Router, NavigationEnd } from "@angular/router";
import { MsalBroadcastService } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';

// Shared Lib
import { coreAppPageMode } from 'kscigcorelib';
import { LoggingService, CoreHelper, IdleTimeoutService, HeartbeatService, AuthenticatedUserInfo } from 'kscigcorelib';

// Application
import { RouteHelper } from './shared/helpers/route.helper';
import { SessionHelper } from './shared/helpers/session.helper';
import { AuthService } from './shared/services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent {
  isIframe = false;
  private readonly _destroying$ = new Subject<void>();
  public isUserLoggedIn = false;
  public bodyClass:string = "bodyBaseColor";
  public coreAppPageModeEnum = coreAppPageMode;
  public coreAppPageMode = coreAppPageMode.none;

  public networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;

  constructor(
    private loggingService: LoggingService,
    private sessionHelper: SessionHelper,
    private routeHelper: RouteHelper,
    private msalBroadcastService:MsalBroadcastService,
    private coreHelper:CoreHelper,
    private authService:AuthService,
    private idleTimeout:IdleTimeoutService,
    private heartbeatService:HeartbeatService,
    private router: Router
    ) { 
      router.events.subscribe((val) => {
        if(val instanceof NavigationEnd){
          this.loggingService.logVerbose("------ AppComponent Route NavigationEnd ---------");
        }
      });
  }

  public ngOnInit(): void {
    this.checkNetworkStatus();
    this.isIframe = window !== window.parent && !window.opener;
    this.subscribeMSALEvents();
  }

  subscribeMSALEvents(){
    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe({
        next: () => {
              this.loggingService.logVerbose("----InteractionStatus None")
              this.coreAppPageMode = this.coreHelper.getApplicationPageMode();
              this.loggingService.logVerbose("App PageMode: " + this.coreAppPageMode);
              if(this.coreAppPageMode == this.coreAppPageModeEnum.login){
                this.bodyClass = "";
              }
              this.validateUserSession();
            }
    });
  }

  validateUserSession() {
    this.loggingService.logVerbose("Validating UserSession via landing page");
    
    if(this.sessionHelper.isValidUserSession()){
      var userAccount:AuthenticatedUserInfo = this.sessionHelper.getLoggedInUser();
      this.loggingService.logVerbose("User found");
      this.loggingService.logVerbose(userAccount);
      this.loggingService.logVerbose(userAccount.Email);
      let domainName:string = this.coreHelper.getDomainfromUsername(userAccount.Email);
      if(this.authService.isValidCustomer(domainName)) {
        if((this.coreAppPageMode == this.coreAppPageModeEnum.login)){
          this.routeHelper.NavigateToApp();
        } else {
            // All good the app page is up
            this.loggingService.logDebug("Valid Session");
        }
        this.idleTimeout.startTimer(this.authService);
        this.heartbeatService.startTick(this.authService);
      } else {
        this.routeHelper.NavigateToLoginPage();
      }
    } else {
      this.loggingService.logInformation("Invalid session.");
      if(this.coreAppPageMode != this.coreAppPageModeEnum.login 
            && this.coreAppPageMode != this.coreAppPageModeEnum.logout){
        this.routeHelper.NavigateToLoginPage();
      }
    }
  }

  private checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.networkStatus = status;
      });
  }
}

