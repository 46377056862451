import { Contact, CustomerLanguagePreference, ApplicationConfig } from 'kscigcorelib';

export class Message {
    MessageId: string;
    MessageCategoryId: string;
    MessageText:string;
    LanguageName:string;
    constructor(){
            this.MessageId = null;
            this.MessageCategoryId = null;
            this.MessageText = '';
            this.LanguageName = '';
        }
}

export class MessageEditorData {
    MessageId: string;
    MessageCategoryId: string;
    MessageText:string;
    LanguageName:string;
    RowEditMode?: boolean;
    IsEditing: boolean;
    constructor(){
            this.MessageId = null;
            this.MessageCategoryId = null;
            this.MessageText = '';
            this.LanguageName = '';
            this.RowEditMode = false;
            this.IsEditing = false;
        }
}

export class ContactEditorData{
    customerId:string;
    contactData:Contact;
    constructor(){
            this.customerId = null;
            this.contactData = new Contact();
        }
}

export class NotifySettingsEditorData{
    customerId:string;
    customerNotifyConfigs:ApplicationConfig[];
    customerLanguagePreference:CustomerLanguagePreference[];
    globalNotifySettings:ApplicationConfig[];
    message:Message
    messageList: Message[];

    constructor(){
        this.customerId = null;
        this.customerNotifyConfigs = [];
        this.customerLanguagePreference = [];
        this.globalNotifySettings = [];
        this.message = null;
        this.messageList = [];
    }
}

export enum OrderByOptionEnum{
    NameAsc = 0,
    NameDesc = 1,
    GoLiveDateAsc = 2,
    GoLiveDateDesc = 3,
    InstallStartAsc = 4,
    InstallStartDesc = 5,
}

export enum CountryRegion{
    East = 0,
    RockyMountain = 1,
    SouthCentral = 2,
    MidAtlantic = 3,
    West = 4,
    Unknown = 5
}