<div>

    <div>
        <div class="CaptionLabelSquare media-header-grid">
            <mat-label>Actions</mat-label>
        </div>
        <div>            
            <button mat-raised-button class="btn-icon-default" style="margin: 15px; padding: 5px 10px;" (click)="syncMedia()">Sync Media
                <!-- <mat-icon>Save</mat-icon> -->
            </button>
        </div>
    </div>

    <div>
        
        <div class="CaptionLabelSquare media-header-grid">
            <mat-label>Configurations</mat-label>
        </div>
        <div class="CaptionLabelSquare media-config-grid" style="border-top: 1px solid #0c81ff;">
            <div>Name</div>
            <div>Value</div>
            <div>Action</div>
        </div>
        <div *ngFor="let appConfig of mediaAppConfigsViewModel">
            <div class="media-config-grid borderBottomBlue">
                <div class="Padding10px">
                    <label>{{appConfig.Name}}</label>
                </div>
                <div class="Padding10px">
                    <div *ngIf="!appConfig.IsEditing">{{appConfig.Value}}</div>
                    <input *ngIf="appConfig.IsEditing" type="text" [(ngModel)]="appConfig.Value" style="width: 250px"/>
                </div>
                <div>
                    <div class="edit-mode Caption" *ngIf="!appConfig.IsEditing">
                        <button mat-icon-button (click)="startEdit(appConfig)">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                    <div class="edit-mode Caption" *ngIf="appConfig.IsEditing">
                        <button mat-icon-button (click)="editConfig(appConfig)">
                            <mat-icon>update</mat-icon>
                        </button>
                        <button mat-icon-button (click)="cancelEdit(appConfig)">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="mediaAppConfigs == null || mediaAppConfigs.length == 0" class="Padding10px Centered">
            No Configuration found
        </div>
    </div>

</div>
