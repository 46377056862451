<h2 mat-dialog-title class="DialogCaptionLabel">
    SMS Provider Editor
  </h2>
  <mat-dialog-content>
  
    <div class="alignCenter">
      <mat-form [formGroup]="smsProviderForm">
        <mat-form-field>
          <mat-label>Provider Name</mat-label>
          <input matInput type="text" placeholder="Ex. Microsoft" formControlName="providerName"/>
          <mat-error>
            <div *ngIf="providerName.hasError('required')">
              Required
            </div>
            <div *ngIf="providerName.hasError('maxlength')">
              200 chars only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>End point</mat-label>
          <input matInput type="text" formControlName="serviceUrl"/>
          <mat-error>
            <div *ngIf="serviceUrl.hasError('required')">
                Required
            </div>
            <div *ngIf="serviceUrl.hasError('maxlength')">
              512 chars only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Account Name</mat-label>
          <input matInput type="text" formControlName="accountName"/>
          <mat-error>
            <div *ngIf="accountName.hasError('required')">
                Required
            </div>
            <div *ngIf="accountName.hasError('maxlength')">
              512 chars only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Account Password</mat-label>
          <input matInput type="text" formControlName="accountPassword"/>
          <mat-error>
            <div *ngIf="accountPassword.hasError('required')">
                Required
            </div>
            <div *ngIf="accountPassword.hasError('maxlength')">
              512 chars only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>SenderNumber</mat-label>
          <input matInput type="text" formControlName="senderNumber"/>
          <mat-error>
            <div *ngIf="senderNumber.hasError('required')">
                Required
            </div>
            <div *ngIf="senderNumber.hasError('maxlength')">
              20 chars only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>OptInKeyword</mat-label>
          <input matInput type="text" formControlName="optInKeyword"/>
          <mat-error>
            <div *ngIf="optInKeyword.hasError('required')">
                Required
            </div>
            <div *ngIf="optInKeyword.hasError('maxlength')">
              25 chars only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>OptOutKeyword</mat-label>
          <input matInput type="text" formControlName="optOutKeyword"/>
          <mat-error>
            <div *ngIf="optOutKeyword.hasError('required')">
                Required
            </div>
            <div *ngIf="optOutKeyword.hasError('maxlength')">
              25 chars only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Stability</mat-label>
          <input matInput type="text" formControlName="stability"/>
          <mat-error>
            <div *ngIf="stability.hasError('pattern')">
              Numbers only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Price/msg</mat-label>
          <input matInput type="text" formControlName="pricePerMsg"/>
          <mat-error>
            <div *ngIf="pricePerMsg.hasError('pattern')">
              Numbers only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Priority</mat-label>
          <input matInput type="text" formControlName="priority"/>
          <mat-error>
            <div *ngIf="priority.hasError('pattern')">
              Numbers only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
      </mat-form>
    </div>
  
  </mat-dialog-content>
  
  <mat-dialog-actions class="alignCenter">
    <button mat-raised-button class="btn-icon-default" style="margin-left:5px;" (click)="closeDialog(false)">Cancel
      <mat-icon>cancel</mat-icon>
    </button>
    <button mat-raised-button class="btn-icon-default" style="margin-left:10px;margin-right: 5px;" [disabled]="!smsProviderForm.dirty || !smsProviderForm.valid" (click)="saveSMSProvider()">Save
      <mat-icon>save</mat-icon>
    </button>
  </mat-dialog-actions>