// Angular
import { Component, OnInit } from '@angular/core';

// Shared Lib
import { coreAppPageMode } from 'kscigcorelib';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public coreAppPageModeEnum = coreAppPageMode;
  public pageMode = coreAppPageMode.appui;
  public companyName:string = "";
  
  constructor() { }

  ngOnInit(): void {
  }

}
