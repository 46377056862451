// Angular 
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';

// Shared Lib
import { LoginAttemptRequest, CloudApiResponse, LoginSuccessRequest, LoginErrorRequest } from 'kscigcorelib';
import { LoggingService, HandleErrorService, SessionService, ApiService, AbstractAuthService, IPService, EnvService } from 'kscigcorelib';


// Application


@Injectable({
  providedIn: 'root'
})
export class AuthService extends AbstractAuthService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private loggingService: LoggingService,
    private msalAuthService: MsalService,
    private apiService: ApiService,
    private sessionService: SessionService,
    private ipService: IPService,
    private envService: EnvService
    ) {
       super(loggingService, msalAuthService, sessionService, ipService);
     }
  

  public registerLoginAttempt(loginAttemptRequest: LoginAttemptRequest) {
    return this.http.post(this.envService.apiUrl + '/Auth/RegisterLoginAttempt', this.apiService.createApiRequestPayload(loginAttemptRequest, true))
    .pipe(
      catchError(this.handleError.handleError<any>('RegisterLoginAttempt', []))
    );
  }

  public registerLoginSuccess(loginSuccessRequest: LoginSuccessRequest) {
    return this.http.post(this.envService.apiUrl + '/Auth/RegisterLoginSuccess', this.apiService.createApiRequestPayload(loginSuccessRequest, true), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('RegisterLoginSuccess', []))
    );
  }

  public registerLoginEnd(loginAttemptRequestId:string){
    return this.http.post(this.envService.apiUrl + '/Auth/RegisterLoginEnd', this.apiService.createApiRequestPayload(loginAttemptRequestId, true), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('registerLoginEnd', []))
    );
  }

  public registerLoginError(loginErrorRequest: LoginErrorRequest){
    return this.http.post(this.envService.apiUrl + '/Auth/RegisterLoginError', this.apiService.createApiRequestPayload(loginErrorRequest, true), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('registerLoginEnd', []))
    );
  }

  public registerHeartbeatTick(loginAttemptRequestId:string){
    return this.http.post(this.envService.apiUrl + '/Auth/RegisterHeartbeatTick', this.apiService.createApiRequestPayload(loginAttemptRequestId, true), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('registerHeartbeatTick'))
    );
  }

  public getProfile() {
    return this.http.get(this.envService.GRAPH_ENDPOINT)
      .pipe(
        catchError(this.handleError.handleError<any>('getProfile', []))
      );
  }

  // Check if valid customer by domain name
  public isValidCustomer(domainName: string): Observable<CloudApiResponse> {
    if(domainName.indexOf('@') > -1){
      domainName = domainName.substring(domainName.indexOf('@') + 1, domainName.length);
    }
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/Customer/isValid?encdn=' + this.apiService.secureApiQueryString(domainName), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('isValidCustomer', []))
      );
  }


}
