<div>

    <div class="backgroundWhite">
        <div class="CaptionLabelSquare media-header-grid">
            <mat-label>Cache</mat-label>
        </div>
        <div>  
            
            <mat-radio-group class="radio-group" [(ngModel)]="selectedClearCacheOption">
                <!-- all -->
                <mat-radio-button class="radio-button" value="all">
                    all
                </mat-radio-button>
                <!-- customer -->
                <mat-radio-button class="radio-button" value="customer">
                    customer  &nbsp;&nbsp;&nbsp;&nbsp;
                    <mat-form-field>
                        <mat-label>Customer</mat-label>
                        <mat-select [(ngModel)]="selectedCustomerId" >
                            <mat-option *ngFor="let cust of customerList" [value]="cust.CustomerId">
                                {{cust.CustomerName}}
                            </mat-option>                                        
                        </mat-select>
                    </mat-form-field>
                </mat-radio-button>
                <!-- api -->
                <mat-radio-button class="radio-button" value="api">
                    Api  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <mat-form-field>
                        <mat-label>Api</mat-label>
                        <mat-select [(ngModel)]="selectedApi" >
                            <mat-option *ngFor="let api of apiList" [value]="api">
                                {{api}}
                            </mat-option>                                        
                        </mat-select>
                    </mat-form-field>
                </mat-radio-button>
                
            </mat-radio-group>
            
            

            <button mat-raised-button class="btn-icon-default" style="margin: 15px; padding: 5px 10px;" (click)="clearCache()">Clear Cache
                <!-- <mat-icon>Save</mat-icon> -->
            </button>
            
        </div>
    </div>

</div>
