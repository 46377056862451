// Angular
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Shared Lib
import { HandleErrorService, SMSProvider, ApiService, EnvService } from 'kscigcorelib';

@Injectable({
  providedIn: 'root'
})
export class NotifySMSService {

  constructor( 
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService: ApiService,
    private envService: EnvService) { }

    public getAllSMSProviders(): Observable<any> {
      return this.http.get<SMSProvider[]>(this.envService.apiUrl + '/NotifySMSProvider/Get', this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('Get all SMSProviders', []))
      );
    }

    public addSMSProvider(smsProvider: SMSProvider) {
      return this.http.post(this.envService.apiUrl + '/NotifySMSProvider/add', this.apiService.createApiRequestPayload(smsProvider), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('Add SMS Provider data', []))
      );
    }

    public updateSMSProvider(smsProvider: SMSProvider) {
      return this.http.put(this.envService.apiUrl + '/NotifySMSProvider/update', this.apiService.createApiRequestPayload(smsProvider), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('Update SMS Provider data', []))
      );
    }
    
    public softDeleteSMSProvider(smsProvider: SMSProvider) {
      return this.http.put(this.envService.apiUrl + '/NotifySMSProvider/delete', this.apiService.createApiRequestPayload(smsProvider), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('Delete SMS Provider data', []))
      );
    }
    
    public undoSoftDeleteSMSProvider(smsProvider: SMSProvider) {
      return this.http.put(this.envService.apiUrl + '/NotifySMSProvider/undo', this.apiService.createApiRequestPayload(smsProvider), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('Undo Delete SMS Provider data', []))
      );
    }
}
