// Angular
import { Component, OnInit } from '@angular/core';

// Shared Lib
import { LoggingService, LoadingBarService, SessionService } from 'kscigcorelib';

// Application
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  public logOutmessage:string = "";

  constructor( 
    private loggingService: LoggingService,
    private authService: AuthService,
    private loadingBarService: LoadingBarService,
    private sessionService: SessionService,
    ) {
      this.logOutmessage = "";
   }

  ngOnInit() {
    this.loadingBarService.startBar();
    this.logOutmessage = "Please wait, Logout is in progress...";
    this.logoutUser();
  }

  logoutUser(){
    var sessionId = this.sessionService.getSessionId(true);  
    if(sessionId != null){
      this.authService.registerLoginEnd(sessionId)
        .subscribe({
            next: () => {
                  this.loggingService.logVerbose("Session End registered");
                  this.authService.logoutUser();
                  this.logOutmessage = "You have been logged out of the application.";
                  this.loadingBarService.stopBar();
                },
            error: () => {
                  this.loggingService.logError("Session End registeration failed");
                  this.authService.logoutUser();
                  this.logOutmessage = "You have been logged out of the application.";
                  this.loadingBarService.stopBar();
                },
        });
    } else {
      this.loggingService.logError("Session End registeration failed");
      this.authService.logoutUser();
      this.logOutmessage = "You have been logged out of the application.";
      this.loadingBarService.stopBar();
    }
  }
}
