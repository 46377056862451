import { KeyValue } from '@angular/common';

export enum orderByOptionEnum{
    StartDateAsc = 0,
    StartDateDesc = 1,
    ExpireDateAsc = 2,
    ExpireDateDesc = 3,
}

export class NotificationEditorData {
    notificationId: string;
    title: string;
    detail: string;
    startDateTime: string;
    expireDateTime: string;
    startTimeHour: number;
    startTimeMinute: number;
    expireTimeHour: number;
    expireTimeMinute: number;
}