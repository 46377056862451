<div class="backgroundWhite">
    <div class="CaptionLabelSquare notify-providers-header-grid">
        <div>
            <mat-label>Providers</mat-label>
        </div>
        <div style="text-align:right;">
            <mat-icon (click)="openSMSProviderEditor(null)" class="CursorPointer" style="margin-right: 5px;" title="Add SMS Provider">add</mat-icon>
        </div>
    </div>
    <div class="CaptionLabelSquare notify-providers-content-grid borderBlue" >
        <div class="padding5px">Name</div>
        <div class="padding5px">Priority</div>
        <div class="padding5px" style="text-align: right;margin-right: 20px;">Action</div>
    </div>
    <div *ngFor="let smsProvider of smsProviderList">
        <div class="notify-providers-content-grid borderBottomBlue">
            <div class="Padding10px" style="margin-top: 10px;">
                {{smsProvider.SMSProviderName}}
            </div>
            <div class="Padding10px" style="margin-top: 10px;">
                {{smsProvider.Priority}}
            </div>
            <div class="Padding10px Caption" style="text-align: right;margin-right: 5px;">
                <div *ngIf="!smsProvider.IsDeleted">
                    <button mat-icon-button (click)="openSMSProviderEditor(smsProvider)" title="Edit">
                        <mat-icon style="margin-right: 5px;">edit</mat-icon>
                    </button>
                    <button mat-icon-button (click)="softDeleteSMSProvider(smsProvider)" title="Delete">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <button mat-icon-button *ngIf="smsProvider.IsDeleted" (click)="undoSoftDeleteSMSProvider(smsProvider)" title="Undo">
                    <mat-icon>undo</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>