<div class="backgroundWhite">
    <div class="CaptionLabelSquare notify-providers-header-grid borderBlue">
        <div>
            <mat-label>Providers</mat-label>
        </div>
        <div style="text-align:right;">
            <mat-icon (click)="openEmailProviderEditor(null)" class="CursorPointer" style="margin-right: 5px;" title="Add Email Provider">add</mat-icon>
        </div>
    </div>
    <div class="CaptionLabelSquare notify-providers-content-grid" >
        <div class="padding5px">Name</div>
        <div class="padding5px">Priority</div>
        <div class="padding5px" style="text-align: right;margin-right: 20px;">Action</div>
    </div>
    <div *ngFor="let emailProvider of emailProviderList">
        <div class="notify-providers-content-grid borderBlue">
            <div class="Padding10px" style="margin-top: 10px;">
                {{emailProvider.EmailProviderName}}
            </div>
            <div class="Padding10px" style="margin-top: 10px;">
                {{emailProvider.Priority}}
            </div>
            <div class="Padding10px Caption" style="text-align: right;margin-right: 5px;">
                <div *ngIf="!emailProvider.IsDeleted">
                    <button mat-icon-button (click)="openEmailProviderEditor(emailProvider)" title="Edit">
                        <mat-icon style="margin-right: 5px;">edit</mat-icon>
                    </button>
                    <button mat-icon-button (click)="softDeleteEmailProvider(emailProvider)" title="Delete">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <button mat-icon-button *ngIf="emailProvider.IsDeleted" (click)="undoSoftDeleteEmailProvider(emailProvider)" title="Undo">
                    <mat-icon>undo</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <!-- Notify Email Configs -->
    <div>
        <div class="CaptionLabelSquare notify-providers-header-grid">
            <mat-label>Configurations</mat-label>
        </div>
        <div class="CaptionLabelSquare notify-config-grid borderBlue" >
            <div>Name</div>
            <div>Value</div>
            <div>Action</div>
        </div>
        <div *ngFor="let appConfig of notifyAppConfigsViewModel">
            <div class="notify-config-grid borderBottomBlue">
                <div class="Padding10px">
                    <label>{{appConfig.Name}}</label>
                </div>
                <div class="Padding10px">
                    <div *ngIf="!appConfig.IsEditing">{{appConfig.Value}}</div>
                    <input *ngIf="appConfig.IsEditing" type="text" [(ngModel)]="appConfig.Value" style="width: 250px"/>
                </div>
                <div>
                    <div class="edit-mode Caption" *ngIf="!appConfig.IsEditing">
                        <button mat-icon-button (click)="startEdit(appConfig)" title="Edit">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                    <div class="edit-mode Caption" *ngIf="appConfig.IsEditing">
                        <button mat-icon-button (click)="editConfig(appConfig)" title="Update">
                            <mat-icon>update</mat-icon>
                        </button>
                        <button mat-icon-button (click)="cancelEdit(appConfig)" title="Cancel">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="notifyAppConfigs == null || notifyAppConfigs.length == 0" class="Padding10px Centered">
            No Configuration found
        </div>
    </div>
</div>