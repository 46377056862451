<h2 mat-dialog-title class="DialogCaptionLabel">
    Notify Settings Editor
</h2>

<mat-dialog-content>
        <div class="CaptionLabel">Settings:</div>
        <div class="Padding10px backgroundWhite">
            <div *ngFor="let config of customerNotifyConfigsViewModel">
                <div class="borderBottomBlue Padding5px" *ngIf="config.Name == 'IsSMSOptInEnabled'">
                    <mat-icon>textsms</mat-icon>
                    <label style="margin-left: 20px;">SMS Opt-In</label>
                    <mat-slide-toggle style="margin-left: 5px" 
                        [checked]="isSMSOptInEnabled" 
                        [(ngModel)]="isSMSOptInEnabled"
                        (change)="onChangeCustomerOptInPreference($event)"></mat-slide-toggle>
                </div>
            </div>
            <div *ngFor="let config of customerNotifyConfigsViewModel">
                <div class="borderBottomBlue Padding5px" *ngIf="config.Name != 'IsSMSOptInEnabled'">
                    <div class="config-content-grid">
                        <div>
                            <label>{{config.DisplayName}}:</label>
                            <div>
                                <div *ngIf="!config.IsEditing" >{{config.Value}}</div>
                                <input *ngIf="config.IsEditing" type="text" [(ngModel)]="config.Value" style="width: 250px;"/>
                            </div>
                        </div>
                        
                        <div>
                            <div class="edit-mode Caption" *ngIf="!config.IsEditing">
                                <button mat-icon-button (click)="startEdit(config)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </div>
                            <div class="edit-mode Caption" *ngIf="config.IsEditing">
                                <button mat-icon-button (click)="editConfig(config)">
                                    <mat-icon>update</mat-icon>
                                </button>
                                <button mat-icon-button (click)="cancelEdit(config)">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="borderBottomBlue Padding5px">
                <div class="config-content-grid">
                    <div>
                        <label>Welcome Message:</label>
                        <div>
                            <div *ngIf="!this.welcomeMessage.IsEditing">{{this.welcomeMessage.MessageText}}</div>
                            <input *ngIf="this.welcomeMessage.IsEditing" type="text" [(ngModel)]="this.welcomeMessage.MessageText" style="width: 100%;"/>
                        </div>
                    </div>
                    <div>
                        <div class="edit-mode Caption" *ngIf="!this.welcomeMessage.IsEditing">
                            <button mat-icon-button (click)="startEditMessage(this.welcomeMessage)">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>
                        <div class="edit-mode Caption" *ngIf="this.welcomeMessage.IsEditing">
                            <button mat-icon-button (click)="editConfigMessage(this.welcomeMessage)">
                                <mat-icon>update</mat-icon>
                            </button>
                            <button mat-icon-button (click)="cancelEditMessage(this.welcomeMessage)">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        <div class="backgroundWhite">
            <div class="CaptionLabel">Automatic Message Settings:</div>
            <div>
                <div class="borderBottomBlue Padding10px " >                
                    <span>
                    <mat-checkbox style="width: 340px; margin-left: 22px" 
                        [checked]="isWelcomeTextEnabledConfig.Value == 'true'"                     
                        (change)="onWelcomeTextEnabled($event.checked)">Send Welcome Text to patient contacts</mat-checkbox>                    
                    </span>                    
                    <span>
                        <mat-form-field style="width: 180px;">
                            <mat-label>Hours prior to surgery</mat-label>
                            <mat-select (selectionChange)="hrsPriorToSurgeryChanged()" [(value)]="hrsPriorToSurgeryConfig.Value" [(ngModel)]="hrsPriorToSurgeryConfig.Value">
                                <mat-option value="24">24</mat-option>
                                <mat-option value="48">48</mat-option>
                                <mat-option value="72">72</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </span>                
                </div>
                <div class="borderBottomBlue Padding10px " >                
                    <span>
                    <mat-checkbox style="width: 340px; margin-left: 22px" 
                        [checked]="isPreOpMessageEnabledConfig.Value == 'true'"                     
                        (change)="onPreOpMessageEnabled($event.checked)">Send Pre-Op Message to patient self</mat-checkbox>                    
                    </span>
                    <span>
                        <mat-form-field style="width: 180px;">
                            <mat-label>Days prior to surgery</mat-label>
                            <mat-select (selectionChange)="daysPriorToSurgeryChanged()" [(value)]="daysPriorToSurgeryConfig.Value" [(ngModel)]="daysPriorToSurgeryConfig.Value">
                                <mat-option value="3">3</mat-option>
                                <mat-option value="5">5</mat-option>                            
                            </mat-select>
                        </mat-form-field>
                    </span>     
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>           
                    <span>
                        <mat-form-field style="width: 500px;">
                            <mat-label>Select the pre-op message to be sent</mat-label>
                            <mat-select (selectionChange)="preOpMessageSelectionChanged()" [(value)]="preOpMsgIdConfig.Value" [(ngModel)]="preOpMsgIdConfig.Value">
                                <mat-option *ngFor="let msg of notifySettingsEditorData.messageList" [value]="msg.MessageId">
                                    {{msg.MessageText}}
                                </mat-option>                        
                            </mat-select>
                        </mat-form-field>
                    </span>
                    <div>
                        <label>Please note, if opt-in policy is enabled then the application will send a Welcome text via SMS, if patient opt-ins only then the pre-op message will be sent.<br />The application will send pre-op message via email if email is populated.</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="backgroundWhite">
            <div class="CaptionLabel">Languages:</div>
            <div *ngFor="let language of notifySettingsEditorData.customerLanguagePreference">
                <div class="Padding10px borderBottomBlue">
                    <mat-icon class="Caption">translate</mat-icon><label style="margin-left:10px;">{{language.languageName}}</label>
                    <mat-slide-toggle [disabled]="language.isDefault" style="margin-left: 55px;" [checked]="language.isEnabledForNotify" (change)="onChangeLanguagePreference($event, language.languageId)"></mat-slide-toggle>
                </div>
            </div>
        </div>
</mat-dialog-content>

<mat-dialog-actions class="alignCenter">
    <button mat-raised-button class="btn-icon-default" style="margin-left:5px;" (click)="closeDialog(true)">Done
      <mat-icon>cancel</mat-icon>
    </button>   
</mat-dialog-actions>