// Angular
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,FormControl,Validators, EmailValidator } from '@angular/forms';

// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange } from '@angular/material/select';
import { MatCheckboxChange } from '@angular/material/checkbox';

// Shared Lib
import { EmailProvider } from 'kscigcorelib';
import { LoggingService, LoadingBarService, NotificationBarService, EncryptionService } from 'kscigcorelib';

import { NotifyEmailService } from '../notify-email.service';

@Component({
  selector: 'app-notify-email-provider-editor',
  templateUrl: './notify-email-provider-editor.component.html',
  styleUrls: ['./notify-email-provider-editor.component.css']
})
export class NotifyEmailProviderEditorComponent implements OnInit {

  public notifyEmailProviderForm: UntypedFormGroup;
  public emailProviderData:EmailProvider;
  
  constructor(
    private loadingBarService: LoadingBarService,
    private loggingService:LoggingService,
    private notificationBarService: NotificationBarService,
    private notifyEmailService:NotifyEmailService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<NotifyEmailProviderEditorComponent>, 
    private encryptionService: EncryptionService,
    @Inject(MAT_DIALOG_DATA) public inputData: EmailProvider
    ) {  
      this.notifyEmailProviderForm = this.fb.group({
        providerName: [null, [Validators.required,Validators.maxLength(50)]],
        token: [null, [Validators.maxLength(250)]],
        smtpServer: [null, [Validators.maxLength(100)]],
        username: [null, [Validators.maxLength(100)]],
        password: [null, [Validators.maxLength(100)]],
        fromAddress: [null, [Validators.maxLength(100)]],
        port: [null, [Validators.pattern(/^[0-9]+(\.[0-9]{1,5})?$/)]],
        pricePerEmail: [null, [Validators.pattern(/^[0-9]+(\.[0-9]{1,5})?$/)]],
        priority: [null, [Validators.pattern(/^[0-9]+(\.[0-9]{1,5})?$/)]],
        isEncrypted: [null, null],
      });
    }

  ngOnInit(): void {
    this.loadPageData();
  }

  private loadPageData(){
    if(this.inputData != null){
      this.loggingService.logVerbose("Edit Email Provider");
      this.loggingService.logVerbose(this.inputData);
      this.emailProviderData = this.inputData;
      this.setPageData();
    } else {
      this.loggingService.logVerbose("New Email Provider");
    }
  }

  private setPageData(){
    if(this.emailProviderData != null){
      this.notifyEmailProviderForm.controls['providerName'].setValue(this.emailProviderData.EmailProviderName);
      this.notifyEmailProviderForm.controls['token'].setValue(this.emailProviderData.Token);
      this.notifyEmailProviderForm.controls['smtpServer'].setValue(this.emailProviderData.SMTPServer);
      this.notifyEmailProviderForm.controls['username'].setValue(this.emailProviderData.Username);
      this.notifyEmailProviderForm.controls['password'].setValue(this.emailProviderData.Password);
      this.notifyEmailProviderForm.controls['port'].setValue(this.emailProviderData.Port);
      this.notifyEmailProviderForm.controls['fromAddress'].setValue(this.emailProviderData.FromAddress);
      this.notifyEmailProviderForm.controls['username'].setValue(this.emailProviderData.Username);
      this.notifyEmailProviderForm.controls['pricePerEmail'].setValue(this.emailProviderData.PricePerEmail);
      this.notifyEmailProviderForm.controls['priority'].setValue(this.emailProviderData.Priority);
      this.notifyEmailProviderForm.controls['isEncrypted'].setValue(this.emailProviderData.IsEncrypted);
    }
  }

  public closeDialog(refresh:boolean) {
    this.dialogRef.close(refresh);
  }

  get providerName() {
    return this.notifyEmailProviderForm.get('providerName');
  }

  get token() {
    return this.notifyEmailProviderForm.get('token');
  }

  get pricePerEmail() {
    return this.notifyEmailProviderForm.get('pricePerEmail');
  }

  get priority() {
    return this.notifyEmailProviderForm.get('priority');
  }

  get smtpServer() {
    return this.notifyEmailProviderForm.get('smtpServer');
  }

  get username() {
    return this.notifyEmailProviderForm.get('username');
  }

  get password() {
    return this.notifyEmailProviderForm.get('password');
  }

  get port() {
    return this.notifyEmailProviderForm.get('port');
  }

  get isEncrypted() {
    return this.notifyEmailProviderForm.get('isEncrypted');
  }

  get fromAddress() {
    return this.notifyEmailProviderForm.get('fromAddress');
  }

  public saveEmailProvider(){
    this.loadingBarService.startBar();
    let emailProvider:EmailProvider = this.getEmailProviderFormData();

    if(this.inputData == null){
      // Add EmailProvider
      this.notifyEmailService.addEmailProvider(emailProvider)
        .subscribe({
          next: () => {  
                this.notificationBarService.showSuccess("Added " +  emailProvider.EmailProviderName);
                this.loggingService.logInformation("Email provider " +  emailProvider.EmailProviderName + " has been updated");
                this.closeDialog(true);
              },
          error: () => {
                this.notificationBarService.showError("Error Adding " +  emailProvider.EmailProviderName);
                this.loggingService.logError("Error Adding email provider " +  emailProvider.EmailProviderName);
              },
          complete: () => { 
            this.loggingService.logVerbose("Completed Adding email provider " +  emailProvider.EmailProviderName); 
            this.loadingBarService.stopBar();
          }
      });
    } else {
      // Update EmailProvider
      this.notifyEmailService.updateEmailProvider(emailProvider)
        .subscribe({
          next: () => {  
                this.notificationBarService.showSuccess("Updated " +  emailProvider.EmailProviderName);
                this.loggingService.logInformation("Email provider " +  emailProvider.EmailProviderName + " has been updated");
                this.closeDialog(true);
              },
          error: () => {
                this.notificationBarService.showError("Error updating " +  emailProvider.EmailProviderName);
                this.loggingService.logError("Error updating email provider " +  emailProvider.EmailProviderName);
              },
          complete: () => { 
            this.loggingService.logVerbose("Completed updating email provider " +  emailProvider.EmailProviderName);
            this.loadingBarService.stopBar();
           }
      });
    }
  }

  private getEmailProviderFormData():EmailProvider{
    let emailProvider:EmailProvider = new EmailProvider();
    if(this.inputData != null){
      emailProvider.EmailProviderId = this.inputData.EmailProviderId;
    }
    emailProvider.EmailProviderName = this.providerName.value;
    emailProvider.Token = this.token.value;
    emailProvider.Priority = Number(this.priority.value);
    emailProvider.PricePerEmail = Number(this.pricePerEmail.value);
    emailProvider.SMTPServer = this.smtpServer.value;
    emailProvider.Username = this.username.value;
    emailProvider.Password = this.password.value;
    emailProvider.Port = Number(this.port.value);
    emailProvider.IsEncrypted = Boolean(this.isEncrypted.value);
    emailProvider.FromAddress = this.fromAddress.value;
    return emailProvider;
  }

}
