
import { ValidatorFn, AbstractControl } from '@angular/forms';

export class CustomDateValidator {
    public static startToEndDate(fromDateField: string, toDateField: string, errorName: string = 'fromToDate'): ValidatorFn {
        return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
            const fromDate = formGroup.get(fromDateField).value
                  , toDate = formGroup.get(toDateField).value;
            if ((fromDate != null && toDate != null)) {
                if(new Date(fromDate) <= new Date(toDate)) {
                    return null;
                } else {
                    return {[errorName]: true};
                  }
            } else {
                return null;
            }
        }
    }
  }