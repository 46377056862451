// Angular
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Shared Lib
import { CloudApiResponse, SMSProvider } from 'kscigcorelib';
import { LoggingService, LoadingBarService, NotificationBarService, EncryptionService } from 'kscigcorelib';

import { NotifySMSService } from '../notify-sms.service';

@Component({
  selector: 'app-notify-smsprovider-editor',
  templateUrl: './notify-smsprovider-editor.component.html',
  styleUrls: ['./notify-smsprovider-editor.component.css']
})
export class NotifySMSProviderEditorComponent implements OnInit {

  public smsProviderForm: UntypedFormGroup;
  public smsProviderData:SMSProvider;

  constructor(
    private loadingBarService: LoadingBarService,
    private loggingService:LoggingService,
    private notificationBarService: NotificationBarService,
    private notifySMSService:NotifySMSService,
    private encryptionService: EncryptionService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<NotifySMSProviderEditorComponent>, 
    @Inject(MAT_DIALOG_DATA) public inputData: SMSProvider) { 
      this.smsProviderForm = this.fb.group({
        providerName: [null, [Validators.required,Validators.maxLength(200)]],
        serviceUrl: [null, [Validators.maxLength(512)]],
        accountName: [null, [Validators.required,Validators.maxLength(512)]],
        accountPassword: [null, [Validators.required,Validators.maxLength(512)]],
        senderNumber: [null, [Validators.required,Validators.maxLength(20)]],
        optInKeyword: [null, [Validators.required,Validators.maxLength(25)]],
        optOutKeyword: [null, [Validators.required,Validators.maxLength(25)]],
        stability: [null, [Validators.pattern(/^[0-9]+(\.[0-9]{1,5})?$/)]],
        pricePerMsg: [null, [Validators.pattern(/^[0-9]+(\.[0-9]{1,5})?$/)]],
        priority: [null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      });
    }

    ngOnInit() {
      this.loadPageData();
     }
   
     private loadPageData(){
       if(this.inputData != null){
         this.loggingService.logVerbose("Edit SMS Provider");
         this.loggingService.logVerbose(this.inputData);
         this.smsProviderData = this.inputData;
         this.setPageData();
       } else {
         this.loggingService.logVerbose("New SMS Provider");
       }
     }

    private setPageData(){
      if(this.smsProviderData != null){
        this.smsProviderForm.controls['providerName'].setValue(this.smsProviderData.SMSProviderId);
        this.smsProviderForm.controls['providerName'].setValue(this.smsProviderData.SMSProviderName);
        this.smsProviderForm.controls['serviceUrl'].setValue(this.smsProviderData.ServiceUrl);
        this.smsProviderForm.controls['accountName'].setValue(this.smsProviderData.AccountName);
        this.smsProviderForm.controls['accountPassword'].setValue(this.smsProviderData.AccountPassword != undefined ? this.encryptionService.decryptUsingAES256(this.smsProviderData.AccountPassword) : '');
        this.smsProviderForm.controls['senderNumber'].setValue(this.smsProviderData.SenderNumber);
        this.smsProviderForm.controls['optInKeyword'].setValue(this.smsProviderData.OptInKeyword);
        this.smsProviderForm.controls['optOutKeyword'].setValue(this.smsProviderData.OptOutKeyword);
        this.smsProviderForm.controls['stability'].setValue(this.smsProviderData.Stability);
        this.smsProviderForm.controls['pricePerMsg'].setValue(this.smsProviderData.PricePerMsg);
        this.smsProviderForm.controls['priority'].setValue(this.smsProviderData.Priority);
      }
     }

    public closeDialog(refresh:boolean) {
      this.dialogRef.close(refresh);
    }

    get providerName() {
      return this.smsProviderForm.get('providerName');
    }

    get serviceUrl() {
      return this.smsProviderForm.get('serviceUrl');
    }

    get accountName() {
      return this.smsProviderForm.get('accountName');
    }

    get accountPassword() {
      return this.smsProviderForm.get('accountPassword');
    }

    get senderNumber() {
      return this.smsProviderForm.get('senderNumber');
    }

    get optInKeyword() {
      return this.smsProviderForm.get('optInKeyword');
    }

    get optOutKeyword() {
      return this.smsProviderForm.get('optOutKeyword');
    }

    get stability() {
      return this.smsProviderForm.get('stability');
    }

    get pricePerMsg() {
      return this.smsProviderForm.get('pricePerMsg');
    }

    get priority() {
      return this.smsProviderForm.get('priority');
    }

    public saveSMSProvider(){
      let smsProvider:SMSProvider = this.getSMSProviderFormData();
      if(this.inputData == null){
        // Add SMS Provider
        this.notifySMSService.addSMSProvider(smsProvider)
          .subscribe({
              next: (result: CloudApiResponse) => {  
                    this.notificationBarService.showSuccess("Added " +  smsProvider.SMSProviderName);
                    this.loggingService.logInformation("SMS Provider " +  smsProvider.SMSProviderName + " has been added");
                    this.closeDialog(true);
                  },
              error: () => {
                    this.notificationBarService.showError("Error adding " +  smsProvider.SMSProviderName);
                    this.loggingService.logError("Error adding SMS Provider " +  smsProvider.SMSProviderName);
                  },
              complete: () => { this.loggingService.logVerbose("Completed adding SMS Provider " +  smsProvider.SMSProviderName); }
          });
      } else {
        // Update SMS Provider
        this.notifySMSService.updateSMSProvider(smsProvider)
          .subscribe({
              next: (result: CloudApiResponse) => {  
                    this.notificationBarService.showSuccess("Updated " +  smsProvider.SMSProviderName);
                    this.loggingService.logInformation("SMS Provider " +  smsProvider.SMSProviderName + " has been updated");
                    this.closeDialog(true);
                  },
              error: () => {
                    this.notificationBarService.showError("Error updating " +  smsProvider.SMSProviderName);
                    this.loggingService.logError("Error updating SMS Provider " +  smsProvider.SMSProviderName);
                  },
              complete: () => { this.loggingService.logVerbose("Completed updating SMS Provider " +  smsProvider.SMSProviderName); }
          });
      }
    }

    private getSMSProviderFormData():SMSProvider{
      let smsProvider:SMSProvider = new SMSProvider();
      if(this.inputData != null){
        smsProvider.SMSProviderId = this.inputData.SMSProviderId;
      }
      smsProvider.SMSProviderName = this.providerName.value;
      smsProvider.ServiceUrl = this.serviceUrl.value;
      smsProvider.AccountName = this.accountName.value;
      smsProvider.AccountPassword = this.encryptionService.encryptUsingAES256(this.accountPassword.value);
      smsProvider.SenderNumber = this.senderNumber.value;
      smsProvider.OptInKeyword = this.optInKeyword.value;
      smsProvider.OptOutKeyword = this.optOutKeyword.value;
      smsProvider.Stability = Number(this.stability.value);
      smsProvider.PricePerMsg = Number(this.pricePerMsg.value);
      smsProvider.Priority = Number(this.priority.value);
      return smsProvider;
    }
}
