// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Shared Lib
import { HandleErrorService, Language, TranslationProvider, ApiService, EnvService } from 'kscigcorelib';

@Injectable({
  providedIn: 'root'
})
export class NotifyTranslatorService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService: ApiService,
    private envService: EnvService) { }

    public getAllTranslationProviders(): Observable<any> {
      return this.http.get<TranslationProvider[]>(this.envService.apiUrl + '/NotifyTranslator/Get', this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('Get all TranslationProvider data', []))
      );
    }

    public updateTranslationProvider(translationProvider: TranslationProvider) {
      return this.http.put(this.envService.apiUrl + '/NotifyTranslator/update', this.apiService.createApiRequestPayload(translationProvider), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('Update Translator data', []))
      );
    }

    public getAllLanguages(): Observable<any> {
      return this.http.get<Language[]>(this.envService.apiUrl + '/NotifyLanguage/Get', this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('Get all Languages', []))
      );
    }

    public addLanguage(language: Language) {
      return this.http.post(this.envService.apiUrl + '/NotifyLanguage/add', this.apiService.createApiRequestPayload(language), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('Add Language data', []))
      );
    }

    public updateLanguage(language: Language) {
      return this.http.put(this.envService.apiUrl + '/NotifyLanguage/update', this.apiService.createApiRequestPayload(language), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('Update Language data', []))
      );
    }
    
    public softDeleteLanguage(language: Language) {
      return this.http.put(this.envService.apiUrl + '/NotifyLanguage/delete', this.apiService.createApiRequestPayload(language), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('Delete Language data', []))
      );
    }
    
    public undoSoftDeleteLanguage(language: Language) {
      return this.http.put(this.envService.apiUrl + '/NotifyLanguage/undo', this.apiService.createApiRequestPayload(language), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('Undo Delete Language data', []))
      );
    }
}
