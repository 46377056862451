import { Component, OnInit, Inject } from '@angular/core';

// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';

// Shared Lib
import { LoggingService, LoadingBarService, NotificationBarService, CloudApiResponse, SessionInfo, MessageBoxModel, MessageBoxType, MessageBoxComponent } from 'kscigcorelib';

import { UserService } from '../../shared/services/user.service';
import { Time } from '@angular/common';

@Component({
  selector: 'app-user-session-editor',
  templateUrl: './user-session-editor.component.html',
  styleUrls: ['./user-session-editor.component.css']
})
export class UserSessionEditorComponent implements OnInit {

  private userId:string;
  public sessions:SessionInfo[] = [];

  constructor(
    private loadingBarService: LoadingBarService,
    private loggingService:LoggingService,
    private notificationBarService: NotificationBarService,   
    private domainUserService: UserService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<UserSessionEditorComponent>, 
    @Inject(MAT_DIALOG_DATA) public inputData: any    
  ) 
  { 
    this.userId = inputData.userId;
    this.sessions = inputData.userSessions;
  }

  ngOnInit(): void {
  }

  public closeDialog(close:boolean) {
    this.dialogRef.close(close);
  }

  public terminateUserSession(sessionId:string) {
    
    let messageBoxData:MessageBoxModel = new MessageBoxModel();
    messageBoxData.message = "Are you sure you want to terminate session '" + sessionId + "'?";
    messageBoxData.messageBoxType = MessageBoxType.yesno;
    const warnDialogRef = this.dialog.open(MessageBoxComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: messageBoxData,
      minWidth: '50vw',
      disableClose: true,
    });

    warnDialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The dialog was closed');
      if(result){
          this.loggingService.logVerbose("Terminate session initiated");
          this.domainUserService.terminateUserSession(sessionId)
              .subscribe({
                  next: (result: CloudApiResponse) => {
                        var terminated = result.payload; 
                        this.loggingService.logVerbose(result.payload);        
                        if (terminated) {
                          this.sessions = this.sessions.filter(m => m.LoginAttemptId != sessionId);
                          this.notificationBarService.showSuccess("Terminated session " + sessionId);
                        } 
                        else 
                          this.notificationBarService.showError("Unable to terminate session " + sessionId);
                      },
                  error: () => { this.loggingService.logError("Error terminating session " + sessionId); },
                  complete: () => { this.loggingService.logVerbose("Completed terminating session " + sessionId); }
              });
      } else {
        this.loggingService.logVerbose("Terminate session was cancelled");
      }
    });
    
  }

  public terminateAllSessions() {

    let messageBoxData:MessageBoxModel = new MessageBoxModel();
    messageBoxData.message = "Are you sure you want to terminate all sessions ?";
    messageBoxData.messageBoxType = MessageBoxType.yesno;
    const warnDialogRef = this.dialog.open(MessageBoxComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: messageBoxData,
      minWidth: '50vw',
      disableClose: true,
    });

    warnDialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The dialog was closed');
      if(result){
          this.loggingService.logVerbose("Terminate all sessions initiated");
          this.domainUserService.terminateAllUserSessions(this.userId)
              .subscribe({
                  next: (result: CloudApiResponse) => {
                        var terminated = result.payload; 
                        this.loggingService.logVerbose(result.payload);        
                        if (terminated) {
                          this.sessions = [];
                          this.notificationBarService.showSuccess("Terminated all sessions ");
                        } 
                        else 
                          this.notificationBarService.showError("Unable to terminate all sessions");
                      },
                  error: () => { this.loggingService.logError("Error Terminating all sessions"); },
                  Component: () => { this.loggingService.logVerbose("Completed Terminating all sessions"); }
                      });
      } else {
        this.loggingService.logVerbose("Terminate all sessions was cancelled");
      }
    });
    
  }
}


