// Angular
import { validateHorizontalPosition } from '@angular/cdk/overlay';
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,FormControl,Validators } from '@angular/forms';

// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';

// Shared Lib
import { LoggingService, LoadingBarService, NotificationBarService } from 'kscigcorelib';
import { CustomerSubscription } from 'kscigcorelib';

// Component
import { CustomerService } from '../customer.service';

@Component({
  selector: 'app-customer-subscription-editor',
  templateUrl: './customer-subscription-editor.component.html',
  styleUrls: ['./customer-subscription-editor.component.css']
})
export class CustomerSubscriptionEditorComponent implements OnInit {

  public customerSubscriptionEditorForm: UntypedFormGroup;
  public customerSubscriptionEditorData:CustomerSubscription;
  
  public isWorklistEnabled:boolean;
  public isNotifyEnabled:boolean;  
  public isMediaPremierEnabled:boolean;

  constructor(private loadingBarService: LoadingBarService,
    private loggingService:LoggingService,
    private notificationBarService: NotificationBarService,
    private customerService:CustomerService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CustomerSubscriptionEditorComponent>, 
    @Inject(MAT_DIALOG_DATA) public inputData: CustomerSubscription
    ) {       
      this.isWorklistEnabled = false;
      this.isNotifyEnabled = false;      
      this.isMediaPremierEnabled = false;

      this.customerSubscriptionEditorForm = this.fb.group({
        subscriptionStartDate: [inputData.SubscriptionStartDate, [Validators.required]],
        subscriptionEndDate: [inputData.SubscriptionEndDate, [Validators.required]],
        seats: [inputData.Seats, [Validators.required]],
        usersPerSeat: [inputData.UsersPerSeat, [Validators.required]],
      });
    }
  
  ngOnInit(): void {
    this.loadPageData();
  }
  
  private loadPageData(){
    this.loggingService.logVerbose(this.inputData);
    if(this.inputData != null){
      if(this.inputData.CustomerSubscriptionId != null){
        this.loggingService.logVerbose("Edit Subscription");
        this.customerSubscriptionEditorData = this.inputData;
        this.setPageData();
      } else {
        this.loggingService.logVerbose("New Subscription");
      }
    } else {
      this.loggingService.logVerbose("Error in passed form data");
    }
  }

  public closeDialog(refresh:boolean) {
    this.dialogRef.close(refresh);
  }

  get subscriptionStartDate() {
    return this.customerSubscriptionEditorForm.get('subscriptionStartDate');
  }

  get subscriptionEndDate() {
    return this.customerSubscriptionEditorForm.get('subscriptionEndDate');
  }

  private setPageData(){
    if(this.customerSubscriptionEditorForm != null){
      this.isWorklistEnabled = this.customerSubscriptionEditorData.IsWorklistEnabled;
      this.isNotifyEnabled = this.customerSubscriptionEditorData.IsNotifyEnabled;      
      this.isMediaPremierEnabled = this.customerSubscriptionEditorData.IsMediaPremierEnabled;
    }
  }
  
  private getPageData():CustomerSubscription{
    let customerSubscription:CustomerSubscription = new CustomerSubscription();
    if(this.inputData.CustomerSubscriptionId != null){
      customerSubscription.CustomerSubscriptionId = this.inputData.CustomerSubscriptionId;
    }
    customerSubscription.CustomerId = this.inputData.CustomerId;
    customerSubscription.SubscriptionStartDate =  this.subscriptionStartDate.value;
    customerSubscription.SubscriptionEndDate =  this.subscriptionEndDate.value;
    customerSubscription.Seats = this.customerSubscriptionEditorForm.controls["seats"].value;
    customerSubscription.UsersPerSeat = this.customerSubscriptionEditorForm.controls["usersPerSeat"].value;
    customerSubscription.IsWorklistEnabled =  this.isWorklistEnabled;
    customerSubscription.IsNotifyEnabled =  this.isNotifyEnabled;    
    customerSubscription.IsMediaPremierEnabled =  this.isMediaPremierEnabled;
    return customerSubscription;
  }

  public saveCustomerSubscription(){
    if (this.customerSubscriptionEditorForm.valid) {
      let customerSubscription:CustomerSubscription  = this.getPageData();
      this.loggingService.logVerbose(customerSubscription);
      let subscriptionStartDateOnly:string = customerSubscription.SubscriptionStartDate;
      subscriptionStartDateOnly = new Date(subscriptionStartDateOnly).toLocaleDateString();
      // Add New
      if(this.inputData.CustomerSubscriptionId == null){
        this.loggingService.logVerbose("Adding Subscription");
        this.customerService.addCustomerSubscription(customerSubscription)
          .subscribe({
              next: () => {  
                    this.notificationBarService.showSuccess("Added Subscription Starting on " +  subscriptionStartDateOnly);
                    this.loggingService.logInformation("Added Subscription Starting on " +  subscriptionStartDateOnly);
                    this.closeDialog(true);
                  }, 
              error: () => {
                    this.notificationBarService.showError("Error Adding Subscription Starting on " +  subscriptionStartDateOnly);
                    this.loggingService.logError("Error Adding Subscription Starting on " +  subscriptionStartDateOnly);
                  },
              complete: () => { this.loggingService.logVerbose("Completed Adding Subscription Starting on " +  subscriptionStartDateOnly); }
          });
      } else {
         // Update 
         this.loggingService.logVerbose("Updating Subscription");
         this.customerService.updateCustomerSubscription(customerSubscription)
          .subscribe({
              next: () => {  
                    this.notificationBarService.showSuccess("Updated Subscription Starting on " +  subscriptionStartDateOnly);
                    this.loggingService.logInformation("Updated Subscription Starting on " +  subscriptionStartDateOnly);
                    this.closeDialog(true);
                  },
              error: () => {
                    this.notificationBarService.showError("Error Updating Subscription Starting on " +  subscriptionStartDateOnly);
                    this.loggingService.logError("Error Updating Subscription Starting on " +  subscriptionStartDateOnly);
                  },
              complete: () => { this.loggingService.logVerbose("Completed Updating Subscription Starting on " +  subscriptionStartDateOnly); }
          });
      }
    } else {
      this.loggingService.logVerbose("Form has errors.");
    } 
  }
}

