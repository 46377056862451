<h2 mat-dialog-title class="DialogCaptionLabel">Connections Editor</h2>
<mat-dialog-content>
  <div class="alignCenter">
    <div class="CaptionLabel">Interfaces</div>
    <br>
    
    <mat-form [formGroup]="customerConnectionInfoEditorForm">
      <div>
        <mat-checkbox formControlName="isADT" >ADT</mat-checkbox>&nbsp;
        <mat-checkbox formControlName="isSIU" >SIU</mat-checkbox>&nbsp;
      </div>
      <br>
      <div class="CaptionLabel">Cloud</div>
      <br>
      <mat-form-field>
          <mat-label>Cloud DB Name</mat-label>
          <input matInput type="text" formControlName="cloudDatabaseName"/>
          <mat-error>
              <div *ngIf="cloudDatabaseName.hasError('required')">
                Required
              </div>
              <div *ngIf="cloudDatabaseName.hasError('maxlength')">
                50 chars only
              </div>
          </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Domain Name</mat-label>
        <input matInput type="text" formControlName="cloudDomainName"/>
        <mat-error>
          <div *ngIf="cloudDomainName.hasError('required')">
            Required
          </div>
          <div *ngIf="cloudDomainName.hasError('maxlength')">
            255 chars only
          </div>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Tenant Id</mat-label>
        <input matInput type="text" formControlName="cloudTenantId"/>
        <mat-error>
          <div *ngIf="cloudTenantId.hasError('required')">
            Required
          </div>
          <div *ngIf="cloudTenantId.hasError('maxlength')">
            255 chars only
          </div>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field>
          <mat-label>Idle Timeout (minutes)</mat-label>
          <input matInput type="text" formControlName="idleTimeoutMinutes"/>          
      </mat-form-field>
      <br>
      <mat-form-field>
          <mat-label>API URL</mat-label>
          <input matInput type="text" formControlName="onPremAPIServerURL"/>
          <mat-error>
            <div *ngIf="onPremAPIServerURL.hasError('required')">
              Required
            </div>
            <div *ngIf="onPremAPIServerURL.hasError('maxlength')">
              255 chars only
            </div>
          </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field>
          <mat-label>Server IP</mat-label>
          <input matInput type="text" formControlName="onPremAPIServerIPAddress"/>
          <mat-error>
              <div *ngIf="onPremAPIServerIPAddress.hasError('maxlength')">
                100 chars only
              </div>
          </mat-error>
      </mat-form-field>      
    </mat-form>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="alignCenter">
  <button mat-raised-button class="btn-icon-default" style="margin-left:5px;" (click)="closeDialog(false)">Cancel
    <mat-icon>cancel</mat-icon>
  </button>   
  <button mat-raised-button class="btn-icon-default" style="margin-left:10px;margin-right: 5px;" (click)="saveCustomerConnectionInfo()">Save
    <mat-icon>save</mat-icon>
  </button>
</mat-dialog-actions>