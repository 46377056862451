<mat-toolbar class="secondary">
    <span style="flex: 1 1 auto;"></span>
    <div>
        Activity Log
    </div>
    <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<div class="contentBox">
<div id="activityContainer">

    <div class="activityPanel">

        <div class="filterPanel">
            
            <mat-checkbox [checked]="activeCustomersOnly" [(ngModel)]="activeCustomersOnly" id="activeOnly" (change)="activeCustomersOnlyChanged()" >
                Active Customers Only
            </mat-checkbox>
   
            <mat-form-field *ngIf="customerList" id="custFilter">
                <mat-label>Customer</mat-label>
                <mat-select (selectionChange)="customerFilterChanged($event)" [(value)]="selectedCustomerId" >
                    <mat-option *ngFor="let cust of customerList" [value]="cust.CustomerId">
                        {{cust.CustomerName}}
                    </mat-option>                                        
                </mat-select>
            </mat-form-field>
            &nbsp;&nbsp;
            <mat-form-field *ngIf="moduleTypes" id="moduleType">
                <mat-label>Module Type</mat-label>
                <mat-select (selectionChange)="this.hideActivityDetails();refreshActivityLogs()" [(value)]="selectedModType" >
                    <mat-option *ngFor="let modType of moduleTypes" [value]="modType">
                        {{modType}}
                    </mat-option>
                </mat-select>
            </mat-form-field>                
            &nbsp;&nbsp;
            <mat-form-field *ngIf="timeFilters" id="timeFilter">
                <mat-label>Time</mat-label>
                <mat-select (selectionChange)="timeFilterChanged($event)" [(value)]="selectedTimeFilterId" >
                    <mat-option *ngFor="let timeFilter of timeFilters" [value]="timeFilter.filterId">
                        {{timeFilter.filterName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            &nbsp;&nbsp;
            <mat-form-field class="search" *ngIf="customerList && selectedTabIndex == 1" id="searchFilter">
                <mat-label>Search Keyword</mat-label>
                <input class="search" matInput placeholder="Keyword" id="searchKeyword" [(ngModel)]="searchKeyword" >
                <mat-icon  class="search" title="clear" (click)="clearSearch()" mat-raised-button color="primary">clear</mat-icon>
                <mat-icon  class="search" title="search" (click)="search()" mat-raised-button color="primary" >search</mat-icon>
                <!-- <button mat-raised-button color="primary" class="btn-default search" (click)="search()" [disabled]="isKeywordSearchDisabled">
                    Search
                </button>  -->
            </mat-form-field>            
        </div>


        <mat-tab-group mat-stretch-tabs="false" animationDuration="0ms" (selectedTabChange)="updateSelectedTabValue($event); hideActivityDetails()" >
            <mat-tab label="Admin Portal"> 
                
                <div class="overflowAuto" style="height: calc(100vh - 178px);">
                    <mat-list>
                        <ng-container *ngFor="let aLog of adminLogs">
                            <mat-list-item>
                                {{aLog.ActivityDate}}                                
                                -&nbsp;{{aLog.Description}}
                                &nbsp;<a class="detailsLink" *ngIf="aLog.DetailDescription" (click)="showActivityDetails(aLog.DetailDescription, $event.target)"><u>Details</u></a>
                            </mat-list-item>
                        </ng-container>
                    </mat-list>    
                </div>

            </mat-tab>
            <mat-tab label="ComfortOR1 Portal"> 
                <div class="overflowAuto" style="height: calc(100vh - 178px);">
                    <mat-list>
                        <ng-container *ngIf="!selectedCustomerId || selectedCustomerId == '1'">
                            <mat-list-item>Please select a customer</mat-list-item>
                        </ng-container>
                        <ng-container *ngFor="let customerLog of customerLogs">
                            <mat-list-item>
                                {{customerLog.ActivityDate}}                                
                                -&nbsp;{{customerLog.Description}}
                                &nbsp;<a class="detailsLink" *ngIf="customerLog.DetailDescription" (click)="showActivityDetails(customerLog.DetailDescription, $event.target)"><u>Details</u></a>
                            </mat-list-item>
                        </ng-container>
                    </mat-list>    
                </div>
            </mat-tab>    
        </mat-tab-group>

    </div>

    <!-- Activity Detail -->
    <div id="detailsPanel" class="displayHidden" >
        <div class="contentBox">
            <div class="CaptionLabel">Activity Details <mat-icon title="close" (click)="hideActivityDetails()">close</mat-icon></div>                
        </div>
        <div id="detailContent" [innerHTML]="detailHtml" class="Padding5px overflowAuto contentBoxSquare backgroundWhite borderBlue" style="height: calc(100vh - 255px);">            
        </div>
    </div>

</div>
</div>