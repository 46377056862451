// Angular
import { Component, OnInit, Inject } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { MsalService, MsalBroadcastService, MsalGuardConfiguration, MSAL_GUARD_CONFIG, MsalGuardAuthRequest } from '@azure/msal-angular';
import { AuthenticationResult, InteractionType, PopupRequest, RedirectRequest, EventMessage, EventType, AccountInfo } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Shared Lib
import { CloudApiResponse, LoginAttemptRequest, AuthenticatedUserInfo, TokenInfo, clientType, MSALEventTypes, CredentialTypes, localStorageItem, LoginSuccessRequest, appRoles, LoginErrorRequest } from 'kscigcorelib';
import { NotificationBarService, LoadingBarService, LoggingService, CoreHelper, SessionService, EncryptionService  } from 'kscigcorelib';

// Application Core 
import { RouteHelper } from '../shared/helpers/route.helper';
import { SessionHelper } from '../shared/helpers/session.helper';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public loginForm: UntypedFormGroup;
  public isProperDomain: boolean;
  public logInMessage:string;
  public logInMessageClass:string;
  public enteredUsername:string;
  public authenticatedUsername:string;
  public authenticatedResult:EventMessage;
  public authenticatedPayloadResult:AuthenticationResult;
  public msalEventId:number;
  private readonly _destroying$ = new Subject<void>();
  private publicClientIP:string = null;

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  private fb: UntypedFormBuilder,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private routeHelper: RouteHelper,
    private loggingService: LoggingService,
    private authService: AuthService,
    private loadingBarService: LoadingBarService,
    private notificationBarService:NotificationBarService,
    private sessionHelper:SessionHelper,
    private coreHelper:CoreHelper,
    private sessionService:SessionService,
    private encryptionService:EncryptionService,
  ) { 
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
    });
    this.enteredUsername = null;
    this.authenticatedUsername = null;
    this.authenticatedResult = null;
    this.authenticatedPayloadResult = null;
    this.logInMessage = "";
    this.logInMessageClass = "";
    this.msalEventId = 0;
  }

  ngOnInit() {
    this.handleMsalLoginEvents();
    this.loadPageData();
  }

  loadPageData(){
    this.publicClientIP = this.sessionService.getClientIP(true);
    this.loggingService.logDebug('Public IP from session: ' + this.publicClientIP);
    if(this.publicClientIP == null){
      // Get public IP and store in session
      this.authService.getIPAddress()
      .subscribe( { 
        next: (val) => { 
          if(val == null){
            this.notificationBarService.showError("Error getting public client IP. Some functionalities might not work.")
          } else {
            this.publicClientIP = val;
            this.loggingService.logDebug('Public IP: ' + this.publicClientIP);
          }
        }, 
        error: (e)=> {
          this.loggingService.logError(e);
          this.notificationBarService.showError("Error getting public client IP. Some functionalities might not work.")
        }
      });
    }
  }

  get email() {
    return this.loginForm.get('email');
  }

  getErrorMessage() {
    if (this.loginForm.get('email').hasError('required')) {
      return 'Please enter an email';
    }
    return this.loginForm.get('email').hasError('email') ? 'Not a valid email' : '';
  }

  private handleMsalLoginEvents(){
    // Subscribe to HANDLE_REDIRECT_END
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.HANDLE_REDIRECT_END),
      )
      .subscribe({
          next: (result: EventMessage) => {
                this.loggingService.logVerbose("----HANDLE_REDIRECT_End");
                this.loggingService.logVerbose(result);
                this.onRedirectionEnd();
              },
          error: () => { this.loggingService.logError("Error HANDLE_REDIRECT_End"); },
          complete: () => { this.loggingService.logVerbose("Completed HANDLE_REDIRECT_End"); }
      });

    // Subscribe to LOGIN_FAILURE
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
      )
      .subscribe({
          next: (result: EventMessage) => {
                this.loggingService.logVerbose("----LOGIN_FAILURE");
                this.loggingService.logVerbose(result);
              },
          error: () => { this.loggingService.logError("Error HANDLE_REDIRECT_START"); }
      });

    // Subscribe to LOGIN_SUCCESS
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe({
          next: (result: EventMessage) => {
                this.loggingService.logVerbose("----LOGIN_SUCCESS");
                this.onLoginSuccess(result);
              }, 
          error: () => { this.loggingService.logError("Error HANDLE_REDIRECT_START"); },
          complete: () => { this.loggingService.logVerbose("Completed LOGIN_SUCCESS"); }
      });
    
    // Subscribe to SSO_SILENT_FAILURE
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.SSO_SILENT_FAILURE),
      )
      .subscribe({
          next: (result: EventMessage) => {
                this.loggingService.logVerbose("----SSO_SILENT_FAILURE");
              },
          error: () => { this.loggingService.logError("Error HANDLE_REDIRECT_START"); }
      });

    // Subscribe to SSO_SILENT_SUCCESS
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.SSO_SILENT_SUCCESS),
      )
      .subscribe({
          next: (result: EventMessage) => { this.loggingService.logVerbose("----SSO_SILENT_SUCCESS"); },
          error: () => { this.loggingService.logError("Error HANDLE_REDIRECT_START"); }
      });
    
    // Subscribe to ACQUIRE_TOKEN_SUCCESS
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      )
      .subscribe({
          next: (result: EventMessage) => {
                this.loggingService.logVerbose("----ACQUIRE_TOKEN_SUCCESS Event");
                this.onAcquireTokenSuccess(result);
              },
          error: () => {
                this.notificationBarService.showError("Error acquiring token");
                this.loggingService.logError("Error acquiring token");
              },
          complete: () => { this.loggingService.logVerbose("Completed acquiring token"); }
      });
  }

  // UI button function to initiate login
  public onLoginButtonClick(){
    this.loadingBarService.startBar();
    var userAccount:AccountInfo;
    this.enteredUsername = this.email.value;
    this.loggingService.logVerbose(this.enteredUsername);
    if(this.email.valid){
      // Start Login process
      this.logInMessageClass = "messageBlock";
      this.logInMessage = "Processing...";
      this.loggingService.logVerbose("Valid Email: " + this.enteredUsername);
      localStorage.setItem(localStorageItem.enteredUsername, this.enteredUsername);
      this.msalService.instance.getAllAccounts().forEach(x=>{
        if(x.username.toString().toLowerCase() == this.email.value.toLowerCase()){
          userAccount = x;
        }
      });
      this.performLoginLogic(userAccount);
    } else {
      this.loadingBarService.stopBar();
    }
  }

  private performLoginLogic(userAccount:AccountInfo){
    if(this.msalGuardConfig != null){
      this.loggingService.logVerbose("msalGuardConfig");
      this.loggingService.logVerbose(this.msalGuardConfig);
      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        // The app will not support popup interaction Type 
        /*
        if (this.msalGuardConfig.authRequest){
          this.msalService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
            .subscribe((response: AuthenticationResult) => {
              this.msalService.instance.setActiveAccount(response.account);
            });
          } else {
            this.msalService.loginPopup()
              .subscribe((response: AuthenticationResult) => {
                this.msalService.instance.setActiveAccount(response.account);
              });
        }
        */
      } else {
        if (this.msalGuardConfig.authRequest){
          this.loggingService.logVerbose("Redirecting to login authority");
          this.loggingService.logVerbose({...this.msalGuardConfig.authRequest});
          
          var request:MsalGuardAuthRequest = {...this.msalGuardConfig.authRequest};
          if(userAccount == null){
            userAccount = {
                            homeAccountId: "",
                            environment: "",
                            tenantId: "",
                            username: this.enteredUsername,
                            localAccountId: "",
                            name: null,
                            idTokenClaims: null
                          };
          }
          // Updated angular msal (with angular v17) does not like this - the below like gives error and auth process fails
          //request.account = userAccount;
          this.loggingService.logVerbose({request});
          // Redirect to MS Graph API for authentication
          this.msalService.loginRedirect({...request} as RedirectRequest);
      } else {
          this.loggingService.logError("MSAL Config Auth request not found");
          this.msalService.loginRedirect();
        }
      }
    } else {
      this.loggingService.logError("MSAL Config not found");
    }
  }

  private getTokens():TokenInfo[]{
    var tokens:TokenInfo[] = [];
    var token:TokenInfo = new TokenInfo();
    token.token = this.authenticatedPayloadResult.idToken;
    token.credentialTypeId = CredentialTypes.IdToken;
    tokens.push(token);
    token = new TokenInfo();
    token.token = this.sessionService.getAccessToken();
    token.credentialTypeId = CredentialTypes.AccessToken;
    tokens.push(token);
    token = new TokenInfo();
    token.token = this.sessionService.getRefreshToken();
    token.credentialTypeId = CredentialTypes.RefreshToken;
    if(token.token != null){
      tokens.push(token);
    }
    return tokens;
  }

  private getAuthenticatedUserInfo():AuthenticatedUserInfo{
    var authenticatedUserInfo:AuthenticatedUserInfo = new AuthenticatedUserInfo();
    authenticatedUserInfo.Email = this.authenticatedPayloadResult.account.username;
    authenticatedUserInfo.HomeAccountId = this.authenticatedPayloadResult.account.homeAccountId;
    authenticatedUserInfo.LocalAccountId = this.authenticatedPayloadResult.account.localAccountId;
    authenticatedUserInfo.Name = this.authenticatedPayloadResult.account.name;
    authenticatedUserInfo.TenantId = this.authenticatedPayloadResult.account.tenantId;
    authenticatedUserInfo.Role = this.coreHelper.getRoleFromToken(this.authenticatedPayloadResult.idToken);
    authenticatedUserInfo.tokens = this.getTokens();
    return authenticatedUserInfo;
  }

  // Return LoginAttemptRequestObject
  private getLoginAttemptRequestObject():LoginAttemptRequest{
    var loginAttemptRequest:LoginAttemptRequest = new LoginAttemptRequest();
    loginAttemptRequest.authenticatedUserInfo = this.getAuthenticatedUserInfo();
    loginAttemptRequest.clientName = clientType.AdminUI;
    loginAttemptRequest.clientIP = this.publicClientIP;
    loginAttemptRequest.timeZoneOffset = new Date().getTimezoneOffset();
    // Get enteredUsername from local storage as its not retained in the page after redirect
    loginAttemptRequest.enteredUserEmail = localStorage.getItem(localStorageItem.enteredUsername);
    loginAttemptRequest.msalEventId = this.msalEventId;
    return loginAttemptRequest; 
  }

  private onLoginSuccess(result:EventMessage){
    this.msalEventId = MSALEventTypes.LOGIN_SUCCESS;
    this.setAuthenticatedUser(result);
  }

  private onAcquireTokenSuccess(result:EventMessage){
    this.msalEventId = MSALEventTypes.ACQUIRE_TOKEN_SUCCESS;
    this.setAuthenticatedUser(result);
  }
  
  private setAuthenticatedUser(result:EventMessage){
    this.loadingBarService.startBar();
    // Start Login process
    this.logInMessageClass = "messageBlock";
    this.logInMessage = "Processing...";
    if (result?.payload) {
      this.authenticatedResult = result;
      this.authenticatedPayloadResult = result.payload as AuthenticationResult;
      if(this.authenticatedPayloadResult.account){
        this.authenticatedUsername = this.authenticatedPayloadResult.account.username;
        this.loggingService.logVerbose("Authenticated username: " + this.authenticatedUsername);
      } else {
        // Error in Login process
        this.logInMessageClass = "colorError";
        this.logInMessage = "Error occured during login";
      }
    } else {
      // Error in Login process
      this.logInMessageClass = "colorError";
      this.logInMessage = "Error occured during login";
    }
  }

  // Perform Application Logic on login redirection end
  private onRedirectionEnd(){
    if(this.authenticatedUsername != null){
      this.loadingBarService.startBar();
      var loginAttemptRequest:LoginAttemptRequest = this.getLoginAttemptRequestObject();
      this.loggingService.logDebug(loginAttemptRequest);
      this.registerLoginAttempt(loginAttemptRequest);
    } else {
      this.loggingService.logError("No authenticated username found.");
      this.loadingBarService.stopBar();
    }
  }

  private registerLoginAttempt(loginAttemptRequest:LoginAttemptRequest){
    // Register Login attempt
    this.authService.registerLoginAttempt(loginAttemptRequest)
      .subscribe({
          next: (cloudApiResponse:CloudApiResponse)=>{
                this.loggingService.logVerbose(cloudApiResponse);
                if(cloudApiResponse.statusCode == 200 && cloudApiResponse.payload != null){
                  // Remove temp local storage item
                  localStorage.removeItem(localStorageItem.enteredUsername);
                  //Set Session id
                  var sessionId = this.encryptionService.decryptUsingAES256(cloudApiResponse.payload);
                  this.sessionService.setSessionId(sessionId);
                  // Check if  user has a valid role only then Login success
                  var userRole = this.coreHelper.getRoleFromToken(this.authenticatedPayloadResult.idToken);
                  if(userRole != null && (userRole == appRoles.configAppAdmin || userRole == appRoles.configAppUser)){
                    // Validate Customer
                    let domainName:string = this.coreHelper.getDomainfromUsername(this.authenticatedUsername);
                    this.authService.isValidCustomer(domainName)
                    .subscribe({
                      next: (apiResponseIsValid:CloudApiResponse) => { 
                          this.loggingService.logVerbose(apiResponseIsValid);
                          if(apiResponseIsValid != null && apiResponseIsValid.statusCode == 200 && apiResponseIsValid.payload != null) {
                            var customerId = this.encryptionService.decryptUsingAES256(apiResponseIsValid.payload);                
                            // Start Login process
                            this.logInMessageClass = "colorSuccess";
                            this.logInMessage = "Loading settings...";
                            this.loggingService.logVerbose("Valid customer");

                            var loginSuccessRequest:LoginSuccessRequest = new LoginSuccessRequest();
                            loginSuccessRequest.customerId = customerId;
                            loginSuccessRequest.loginAttemptRequestId = sessionId;

                            // Register Login Success
                            this.authService.registerLoginSuccess(loginSuccessRequest)
                            .subscribe({
                                next: () => {
                                      this.msalService.instance.setActiveAccount(this.authenticatedPayloadResult.account);
                                      // Add User info to session
                                      this.loggingService.logVerbose("Set in session active User: " + this.authenticatedUsername);
                                      this.sessionHelper.setLoggedInUser(this.getAuthenticatedUserInfo());
                                      this.loadingBarService.stopBar();
                                      this.routeHelper.NavigateToApp();
                                    },
                                error: () => {
                                      this.registerLoginError(sessionId, "Oops! Error in registering login.");
                                    },
                                complete: () => { this.loggingService.logVerbose("Completed register login success"); }
                            });
                          } else {
                            this.registerLoginError(sessionId, "Oops! Your organization is not subscribed.");
                          }
                        },
                      error: () => { 
                        this.registerLoginError(sessionId, "Error in checking isValidCustomer");
                      },
                      complete: () => { 
                        this.loggingService.logVerbose("Completed checking 'Is Organization Authorized'"); 
                      }
                    });
                  } else {
                    this.registerLoginError(sessionId, "Error! Unauthorized user.");
                  }
                } else {
                  // Error Regstering login attempt
                  this.logInMessageClass = "colorError";
                  this.logInMessage = "Oops! Error occurred in login process.";
                  this.loggingService.logError("Error registering login attempt");
                  this.loadingBarService.stopBar();
                }
            },
        error: () => {
                // Error Regstering login attempt
                this.logInMessageClass = "colorError";
                this.logInMessage = "Oops! Error occurred in login process.";
                this.loggingService.logError("Error registering login attempt");
                this.loadingBarService.stopBar();
              },
        complete: () => { this.loggingService.logVerbose("Completed register login attempt"); }
    });
  }

  private registerLoginError(loginAttemptRequestId:string, errorMessage:string) {
    if(loginAttemptRequestId != null && loginAttemptRequestId != ''){
      var loginErrorRequest:LoginErrorRequest = new LoginErrorRequest();
      loginErrorRequest.loginAttemptRequestId = loginAttemptRequestId;
      loginErrorRequest.errorMessage = errorMessage;
      this.authService.registerLoginError(loginErrorRequest)
        .subscribe({
          next: (loginErrorResult:CloudApiResponse) => {  
                    this.sessionService.clearSession();                                                                              
                    if(loginErrorResult != null && loginErrorResult.payload != null && loginErrorResult.payload){
                      this.loggingService.logError(errorMessage);
                      this.loggingService.logVerbose("Registered Login Error");
                      this.logInMessageClass = "colorError";
                      this.logInMessage = errorMessage;
                      this.loadingBarService.stopBar();
                    } else {
                      this.loggingService.logError(errorMessage);
                      this.loggingService.logError("Error Registering Login error.");
                      this.logInMessageClass = "colorError";
                      this.logInMessage = errorMessage;
                      this.loadingBarService.stopBar();
                    }
                  }, 
          error: () => {
                    this.loggingService.logError(errorMessage);
                    this.loggingService.logError("Error Registering Login error.");
                    this.logInMessageClass = "colorError";
                    this.logInMessage = errorMessage;
                    this.sessionService.clearSession();
                    this.loadingBarService.stopBar();
                  },
          complete: () => { this.loggingService.logVerbose("Completed Registering Login error."); }
      });
      
    } else {
      this.loggingService.logError(errorMessage);
      this.loggingService.logError("Error in registerLoginError. loginAttemptRequestId is empty");
      this.logInMessageClass = "colorError";
      this.logInMessage = errorMessage;
      this.sessionService.clearSession();
      this.loadingBarService.stopBar();
    }
  }

}
