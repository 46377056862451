// Angular
import { NgModule, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Routes, RouterModule, RouterStateSnapshot, TitleStrategy } from '@angular/router';

// Application
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomerComponent } from './customer/customer.component';
import { ReportsComponent } from './reports/reports.component';
import { ActivityComponent } from './activity/activity.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SettingsComponent } from './settings/settings.component';
import { NotFoundComponent } from './error/not-found/not-found.component';
import { UnauthorizedComponent } from './error/unauthorized/unauthorized.component'; 
import { environment } from 'src/environments/environment';
import { AuthGuard } from './shared/helpers/authGuard';

@Injectable()
export class PageTitleStrategy extends TitleStrategy {
  constructor(
    private readonly title: Title    
    ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(environment.productName + `${title}`);
    }
  }
}

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    title: ' | Login',
  },{
    path: 'logout',
    component: LogoutComponent,
    title: ' | Logout',
  },{
    path: 'dashboard',
    component: DashboardComponent,
    title: ' | Dashboard',
    canActivate: [
      AuthGuard
    ]
  },{
    path: 'customer',
    component: CustomerComponent,
    title: ' | Customer',
    canActivate: [
      AuthGuard
    ]
  },{
    path: 'reports',
    component: ReportsComponent,
    title: ' | Reports',
    canActivate: [
      AuthGuard
    ]
  },{
    path: 'activity',
    component: ActivityComponent,
    title: ' | Activity',
    canActivate: [
      AuthGuard
    ]
  },{
    path: 'notifications',
    component: NotificationsComponent,
    title: ' | Notifications',
    canActivate: [
      AuthGuard
    ]
  },{
    path: 'settings',
    component: SettingsComponent,
    title: ' | Settings',
    canActivate: [
      AuthGuard
    ]
  },{
    path: 'error', 
    component: NotFoundComponent,
    title: ' | Error 404'
  },{
    path: 'unauthorized', 
    component: UnauthorizedComponent,
    title: ' | Unauthorized'
  },{
    path: '',
    redirectTo: '/login', 
    pathMatch: 'full'
  },{ // Wildcard route should always be the last
    path: '**', 
    redirectTo: 'error'
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy
    }
  ]
})
export class AppRoutingModule { }