// Angular
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

// Shared Lib
import { Contact, Customer, CustomerConnectionInfo, CustomerSubscription, HandleErrorService, ApiService, CloudApiResponse, EnvService } from 'kscigcorelib';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService: ApiService,
    private envService: EnvService

  ) { }

  public getCustomersCount(isActiveOnly: boolean): Observable<any> {
    return this.http.get<number>(this.envService.apiUrl + '/Customer/GetCount?isActiveOnly=' + isActiveOnly, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('getCustomersCount', []))
    );
  }

  public getCustomers(isActiveOnly: boolean): Observable<CloudApiResponse> {
    return this.http.get<Customer[]>(this.envService.apiUrl + '/Customer/GetAll?isActiveOnly=' + isActiveOnly, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('getCustomers', []))
    );
  }

  public getCustomersForGroupByData(groupBy: string, groupByItem:string): Observable<CloudApiResponse> {
    return this.http.get<Customer[]>(this.envService.apiUrl + '/Customer/GetGroupBy?groupBy=' + groupBy + '&groupByItem=' + groupByItem, this.apiService.getCustomHeader())
    .pipe( 
      catchError(this.handleError.handleError<any>('getCustomersForGroupByData', []))
    );
  }

  public getCustomersForInstallData(installDate: string, time:string, state:string): Observable<CloudApiResponse> {
    return this.http.get<Customer[]>(this.envService.apiUrl + '/Customer/GetByInstallDate?installDate=' + installDate + '&time=' + time + '&state=' + state, this.apiService.getCustomHeader())
    .pipe( 
      catchError(this.handleError.handleError<any>('getCustomersForInstallData', []))
    );
  }

  public addCustomer(customer: Customer): Observable<any> {
    return this.http.post(this.envService.apiUrl + '/Customer/Add', this.apiService.createApiRequestPayload(customer), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('addCustomer', []))
    );
  }

  public updateCustomer(customer: Customer): Observable<any> {
    return this.http.put(this.envService.apiUrl + '/Customer/Update', this.apiService.createApiRequestPayload(customer), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('updateCustomer', []))
    );
  }

  public deleteCustomer(customerId: string): Observable<any> {
    return this.http.delete<boolean>(this.envService.apiUrl + '/Customer/Delete?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('deleteCustomer', []))
    );
  }

  public setCustomerStatus(customerId: string, isActivate:boolean): Observable<any> {
    let url = this.envService.apiUrl + '/Customer/deactivate';
    if(isActivate){
      url = this.envService.apiUrl + '/Customer/activate';
    }
    return this.http.put(url + "?ecid=" + this.apiService.secureApiQueryString(customerId), null, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('setCustomerStatus', []))
    );
  }

  public getCustomerContacts(customerId: string): Observable<CloudApiResponse> {
    return this.http.get<Contact[]>(this.envService.apiUrl + '/Customer/Contact?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('getCustomerContacts', []))
    );
  }

  public addCustomerContact(customerId: string, contact: Contact): Observable<any> {
    return this.http.post(this.envService.apiUrl + '/Customer/Contact/add?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(contact, true), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('addCustomerContact', []))
    );
  }

  public updateCustomerContact(contact: Contact): Observable<any> {
    return this.http.put(this.envService.apiUrl + '/Customer/Contact/update', this.apiService.createApiRequestPayload(contact, true), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('updateCustomerContact', []))
    );
  }

  public deleteCustomerContact(contactId: string): Observable<any> {
    return this.http.delete<boolean>(this.envService.apiUrl + '/Customer/Contact/delete?eccid=' + this.apiService.secureApiQueryString(contactId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('deleteCustomerContact', []))
    );
  }

  public getCustomerConnectionInfo(customerId: string): Observable<any> {
    return this.http.get<CustomerConnectionInfo>(this.envService.apiUrl + '/Customer/GetConnInfo?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('getCustomerDBConnection', []))
    );
  }

  public setCustomerConnectionInfo(customerConnectionInfo: CustomerConnectionInfo): Observable<any> {
    return this.http.put(this.envService.apiUrl + '/Customer/SetConnInfo', this.apiService.createApiRequestPayload(customerConnectionInfo), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('setCustomerConnectionInfo', []))
    );
  }

  public getCustomerSubscriptions(customerId: string): Observable<any> {
    return this.http.get<CustomerSubscription[]>(this.envService.apiUrl + '/Customer/Subscription/Get?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('getCustomerSubscriptions', []))
    );
  }

  public addCustomerSubscription(customerSubscription: CustomerSubscription): Observable<any> {
    return this.http.post(this.envService.apiUrl + '/Customer/Subscription/Add', this.apiService.createApiRequestPayload(customerSubscription), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('addCustomerSubscription', []))
    );
  }

  public updateCustomerSubscription(customerSubscription: CustomerSubscription): Observable<any> {
    return this.http.put(this.envService.apiUrl + '/Customer/Subscription/Update', this.apiService.createApiRequestPayload(customerSubscription), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('updateCustomerSubscription', []))
    );
  }

  public deleteCustomerSubscription(customerSubscriptionId: string): Observable<any> {
    return this.http.delete<boolean>(this.envService.apiUrl + '/Customer/Subscription/Delete?ecsid=' + this.apiService.secureApiQueryString(customerSubscriptionId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('deleteCustomerSubscription', []))
    );
  }
}