// Angular
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

// Material
import { MatDialog } from '@angular/material/dialog';

// Shared Lib
import { LoggingService, LoadingBarService, NotificationBarService, CloudApiResponse } from 'kscigcorelib';
import { EmailProvider, ApplicationConfig, ApplicationConfigViewModel, EncryptionService } from 'kscigcorelib';

// Component
import { SettingsService } from '../settings.service';
import { NotifyEmailProviderEditorComponent } from './notify-email-provider-editor/notify-email-provider-editor.component';
import { NotifyEmailService } from './notify-email.service';

@Component({
  selector: 'app-notify-email',
  templateUrl: './notify-email.component.html',
  styleUrls: ['./notify-email.component.css']
})
export class NotifyEmailComponent implements OnInit {

  public emailProviderList:EmailProvider[] = [];
  public notifyAppConfigs:ApplicationConfig[] = [];
  public notifyAppConfigsViewModel:ApplicationConfigViewModel[] = [];

  constructor(
    private loggingService: LoggingService,
    private loadingBarService: LoadingBarService,
    private notificationBarService: NotificationBarService,
    private notifyEmailService: NotifyEmailService,
    private encryptionService: EncryptionService,
    public dialog: MatDialog,
    private settingsService: SettingsService) { 
    }

  ngOnInit(): void {
    this.loadingBarService.startBar();
    // Load Page Data
    this.loadPageData();
  }

  private loadPageData(){
    this.loadEmailConfigs();
    this.loadEmailProviderList(true);
  }

  private loadEmailConfigs(){
    let _notifyAppConfigsViewModel:ApplicationConfigViewModel[] = [];
    let _notifyAppConfig:ApplicationConfigViewModel = new ApplicationConfigViewModel();
    this.settingsService.getAllNotifyApplicationConfigs()
      .subscribe({
          next: (result:CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);      
                let applicationConfigs: ApplicationConfig[] = JSON.parse(decryptedPayload);
                this.loggingService.logVerbose(applicationConfigs);
                this.notifyAppConfigs = applicationConfigs;
                if(applicationConfigs != null){
                  applicationConfigs.forEach(applicationConfig => {
                    // Create the application Config Vie Model
                    _notifyAppConfig = new ApplicationConfigViewModel();
                    _notifyAppConfig.Name = applicationConfig.Name;
                    _notifyAppConfig.Value = applicationConfig.Value;
                    _notifyAppConfig.IsEncrypted = applicationConfig.IsEncrypted;
                    _notifyAppConfigsViewModel.push(_notifyAppConfig);
                  });
                  this.notifyAppConfigsViewModel = _notifyAppConfigsViewModel;
                }
                this.loadingBarService.stopBar(); 
              },
          error: () => {
                this.notificationBarService.showError("Error calling service");
                this.loadingBarService.stopBar();
                this.loggingService.logError("Error loading email configs"); 
              },
          complete: () => { this.loggingService.logVerbose("Completed loading email configs");}
      });
  }

  private loadEmailProviderList(stopLoadingbar:boolean){
    this.notifyEmailService.getAllEmailProviders()
      .subscribe({
          next: (result: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);      
                let emailProviders: EmailProvider[] = JSON.parse(decryptedPayload);
                this.loggingService.logVerbose(emailProviders);
                this.emailProviderList = emailProviders;
              },
          error: () => {
                this.notificationBarService.showError("Error calling service");
                this.loadingBarService.stopBar(); 
                this.loggingService.logError("Error fetching email providers");
              },
          complete: () => { this.loggingService.logVerbose("Completed fetching email providers"); }
      });
  }

  private updateAppConfigSetting(applicationConfig:ApplicationConfig){
    if(applicationConfig != null){
      this.loadingBarService.startBar();
      this.settingsService.setNotifyApplicationConfig(applicationConfig)
        .subscribe({
            next: (updated: boolean) => {
                  this.loggingService.logVerbose(updated);
                  if(updated) {
                    this.notifyAppConfigs.find(m => m.Name == applicationConfig.Name).Value = applicationConfig.Value;
                    this.notificationBarService.showSuccess("Updated " + applicationConfig.Name);
                    this.loggingService.logInformation("Updated " + applicationConfig.Name);
                  } else {
                    this.notificationBarService.showError("Error Updating " + applicationConfig.Name);
                    this.loggingService.logError("Error Updating " + applicationConfig.Name);
                  }
                },
            error: () => {
                    this.notificationBarService.showError("Error calling service");
                    this.loggingService.logError("Error updating application config " + applicationConfig.Name);
                },
            complete: () => { this.loggingService.logVerbose("Completed updating application config " + applicationConfig.Name); this.loadingBarService.stopBar();}
        });
    } else {
      this.loggingService.logError("Error applicationConfig is null");
    }
  }

  public openEmailProviderEditor(emailProvider:EmailProvider){
    this.loggingService.logVerbose("Open Editor");
    this.loggingService.logVerbose(emailProvider);

    const dialogRef = this.dialog.open(NotifyEmailProviderEditorComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: emailProvider,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The dialog was closed');
      if(result){
        this.loadEmailProviderList(false);
      }
    });
  }

  public softDeleteEmailProvider(emailProvider: EmailProvider){
    this.loggingService.logInformation(emailProvider);
    this.loadingBarService.startBar();
    this.notifyEmailService.softDeleteEmailProvider(emailProvider)
      .subscribe({
          next: (result: CloudApiResponse) => {
                this.loadEmailProviderList(false);
                this.notificationBarService.showSuccess("Deleted " +  emailProvider.EmailProviderName);
                this.loggingService.logInformation("Provider " +  emailProvider.EmailProviderName + " has been deleted");
              },
          error: () => {
                this.notificationBarService.showError("Error deleting " +  emailProvider.EmailProviderName);
                this.loggingService.logError("Error deleting provider " +  emailProvider.EmailProviderName);
              },
          complete: () => { this.loggingService.logVerbose("Completed deleting provider " +  emailProvider.EmailProviderName); this.loadingBarService.stopBar();}
      });
  }

  public undoSoftDeleteEmailProvider(emailProvider: EmailProvider){
    this.loggingService.logInformation(emailProvider);
    this.loadingBarService.startBar();
    this.notifyEmailService.undoSoftDeleteEmailProvider(emailProvider)
      .subscribe({
          next: (result: CloudApiResponse) => {
                this.loadEmailProviderList(false);
                this.notificationBarService.showSuccess("Undone delete " +  emailProvider.EmailProviderName);
                this.loggingService.logInformation("Provider " +  emailProvider.EmailProviderName + " has been undeleted");
              },
          error: () => {
                this.notificationBarService.showError("Error undeleting " +  emailProvider.EmailProviderName);
                this.loggingService.logInformation("Error undeleting provider " +  emailProvider.EmailProviderName);
              },
          complete: () => { this.loggingService.logVerbose("Completed undeleting provider " +  emailProvider.EmailProviderName); this.loadingBarService.stopBar();}
      });
  }


  public startEdit(appConfig: ApplicationConfigViewModel): void {
    appConfig.IsEditing = true;
  }

  public cancelEdit(appConfig: ApplicationConfigViewModel): void {
    appConfig.Value = this.notifyAppConfigs.find(m => m.Name == appConfig.Name).Value;
    appConfig.IsEditing = false;
  }

  public editConfig(appConfig: ApplicationConfigViewModel){
    this.loggingService.logVerbose(appConfig);
    this.updateAppConfigSetting(appConfig);
    appConfig.IsEditing = false;
  }

}
