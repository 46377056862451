export class LanguageViewModel {
    rowEditMode?: boolean;
    isEditing: boolean;
    languageId: string;
    languageCode: string;
    languageName: string;
    isDeleted: boolean;
    isDefault: boolean;

    constructor(){
        this.isDeleted = true;
        this.isDefault = false;
        this.isEditing = false;
        this.languageCode = '';
        this.languageId = null;
        this.languageName = '';
        this.rowEditMode = false;
    }
}