// Angular
import { Component, OnInit, Inject } from '@angular/core';
import { KeyValue } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup,FormControl,Validators } from '@angular/forms';

// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Shared Lib
import { LoggingService, LoadingBarService, NotificationBarService } from 'kscigcorelib';
import { Customer } from 'kscigcorelib';

// Shared
import { CustomDateValidator } from '../../shared/helpers/CustomDateValidator';

// component
import { CustomerService } from '../customer.service';
import { CountryRegion } from '../customer-model';

@Component({
  selector: 'app-customer-editor',
  templateUrl: './customer-editor.component.html',
  styleUrls: ['./customer-editor.component.css']
})
export class CustomerEditorComponent implements OnInit {

  public countryRegions:KeyValue<CountryRegion,string>[] = [];
  public countryRegion:CountryRegion;
  public customerEditorForm: UntypedFormGroup;
  public customerEditorData:Customer;

  constructor(private loadingBarService: LoadingBarService,
    private loggingService:LoggingService,
    private notificationBarService: NotificationBarService,
    private customerService:CustomerService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CustomerEditorComponent>, 
    @Inject(MAT_DIALOG_DATA) public inputData: Customer
    ) { 
      this.customerEditorForm = this.fb.group({
        customerName: [null, [Validators.required,Validators.maxLength(255)]],
        customerShortName: [null, [Validators.required,Validators.maxLength(25)]],
        streetAddress: [null, [Validators.maxLength(255)]],
        city: [null, [Validators.maxLength(50)]],
        state: [null, [Validators.maxLength(50)]],
        zipcode: [null, [Validators.maxLength(10)]],
        country: [null, [Validators.maxLength(50)]],
        phone: [null, [Validators.maxLength(12), Validators.pattern("^([0-9]{3})[-]?([0-9]{3})[-]?([0-9]{4})?")]],
        region: [null, [Validators.required]],
        installStartDate: [null, []],
        goLiveDate: [null, []],
      }, {
        validator : [
          CustomDateValidator.startToEndDate('installStartDate', 'goLiveDate')
        ]
      });
    }

    ngOnInit(): void {
      let _countryRegion:KeyValue<CountryRegion,string>[] = [];
      _countryRegion.push({key:CountryRegion.East,value:"East"});
      _countryRegion.push({key:CountryRegion.MidAtlantic,value:"Mid-Atlantic"});
      _countryRegion.push({key:CountryRegion.RockyMountain,value:"Rocky Mountain"});
      _countryRegion.push({key:CountryRegion.SouthCentral,value:"South Central"});
      _countryRegion.push({key:CountryRegion.West,value:"West"});
      _countryRegion.push({key:CountryRegion.Unknown,value:"Unknown"});
      this.countryRegions = _countryRegion;
      this.countryRegion = CountryRegion.Unknown;
      this.loadPageData();
    }
  
    private loadPageData(){
      this.loggingService.logVerbose(this.inputData);
      if(this.inputData != null){
        this.loggingService.logVerbose("Edit Contact");
        this.customerEditorData = this.inputData;
        this.setPageData();
      }
      else {
        this.loggingService.logVerbose("New Contact");
      }
    }
  
    public closeDialog(refresh:boolean) {
      this.dialogRef.close(refresh);
    }

    get customerName() {
      return this.customerEditorForm.get('customerName');
    }

    get customerShortName() {
      return this.customerEditorForm.get('customerShortName');
    }

    get streetAddress() {
      return this.customerEditorForm.get('streetAddress');
    }

    get city() {
      return this.customerEditorForm.get('city');
    }

    get state() {
      return this.customerEditorForm.get('state');
    }

    get zipcode() {
      return this.customerEditorForm.get('zipcode');
    }

    get country() {
      return this.customerEditorForm.get('country');
    }

    get phone() {
      return this.customerEditorForm.get('phone');
    }

    get region() {
      return this.customerEditorForm.get('region');
    }

    get installStartDate() {
      return this.customerEditorForm.get('installStartDate');
    }

    get goLiveDate() {
      return this.customerEditorForm.get('goLiveDate');
    }

    private setPageData(){
      if(this.customerEditorData.CustomerId != null){
        this.customerEditorForm.controls['customerName'].setValue(this.customerEditorData.CustomerName);
        this.customerEditorForm.controls['customerShortName'].setValue(this.customerEditorData.CustomerShortName);
        this.customerEditorForm.controls['streetAddress'].setValue(this.customerEditorData.StreetAddress);
        this.customerEditorForm.controls['state'].setValue(this.customerEditorData.State);
        this.customerEditorForm.controls['zipcode'].setValue(this.customerEditorData.Zipcode);
        this.customerEditorForm.controls['city'].setValue(this.customerEditorData.City);
        this.customerEditorForm.controls['country'].setValue(this.customerEditorData.Country);
        this.customerEditorForm.controls['phone'].setValue(this.customerEditorData.Phone);
        this.customerEditorForm.controls['region'].setValue(this.countryRegions.find(x=>x.value == this.customerEditorData.Region).key);
        this.customerEditorForm.controls['installStartDate'].setValue(this.customerEditorData.InstallStartDate);
        this.customerEditorForm.controls['goLiveDate'].setValue(this.customerEditorData.GoLiveDate);
      }
    }

    private getPageData():Customer{
      let customer:Customer = new Customer();
      if(this.inputData != null){
        customer.CustomerId = this.inputData.CustomerId;
      }
      customer.CustomerName =  this.customerName.value;
      customer.CustomerShortName =  this.customerShortName.value;
      customer.StreetAddress =  this.streetAddress.value;
      customer.State =  this.state.value;
      customer.Zipcode =  this.zipcode.value;
      customer.City =  this.city.value;
      customer.Country =  this.country.value;
      customer.Phone =  this.phone.value;
      customer.Region =  this.countryRegions.find(x=>x.key == this.region.value).value;
      customer.InstallStartDate =  this.installStartDate.value;
      customer.GoLiveDate =  this.goLiveDate.value;
      return customer;
    }
  
    public saveCustomer(){
      if (this.customerEditorForm.valid) {
        let customer:Customer = this.getPageData();
        this.loggingService.logVerbose(customer);
        // Add New
        if(customer.CustomerId == null){
          this.loggingService.logVerbose("Adding Contact");
          this.customerService.addCustomer(customer)
            .subscribe({
                next: () => {  
                      this.notificationBarService.showSuccess("Added " +  customer.CustomerName);
                      this.loggingService.logInformation("Added " +  customer.CustomerName);
                      this.closeDialog(true);
                    },
                error: () => {
                      this.notificationBarService.showError("Error adding " + customer.CustomerName);
                      this.loggingService.logError("Error adding " +  customer.CustomerName);
                    },
                complete: () => { this.loggingService.logVerbose("Completed adding customer " +  customer.CustomerName); }
            });
        } else {
           // Update 
           this.loggingService.logVerbose("Updating Contact");
           this.customerService.updateCustomer(customer)
            .subscribe({
                next: () => {  
                      this.notificationBarService.showSuccess("Updated " +  customer.CustomerName);
                      this.loggingService.logInformation("Updated " +  customer.CustomerName);
                      this.closeDialog(true);
                    },
                error: () => {
                      this.notificationBarService.showError("Error updating " +  customer.CustomerName);
                      this.loggingService.logError("Error updating " +  customer.CustomerName);
                    },
                complete: () => { this.loggingService.logVerbose("Completed updating customer " +  customer.CustomerName); }
            });
        }
      } else {
        this.loggingService.logVerbose("Form has errors.");
      } 
    }
}
