// Angular
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

// Shared Lib
import { HandleErrorService, ApplicationConfig, ApiService, CloudApiResponse, EnvService, MediaPortal } from 'kscigcorelib';
import { MediaModel, CustomerDefaultMediaPreference } from 'kscigcorelib';




@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService: ApiService,
    private envService: EnvService
  ) { }

  // public getActiveMedia(customerId:string): Observable<CloudApiResponse> {
  //   return this.http.get<MediaModel[]>(this.envService.apiUrl + '/Media/get?isActiveOnly=true' + "&ecid=" + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
  //   .pipe(
  //     catchError(this.handleError.handleError<any>('getActiveMedia', []))
  //   );
  // }

  // public getActiveMediaForModuleType(mediaModuleTypeId:string, customerId:string): Observable<CloudApiResponse> {
  //   return this.http.get<MediaModel[]>(this.envService.apiUrl + '/Media/getMediaByModuleType?mediaModuleTypeId=' + mediaModuleTypeId + '&isActiveOnly=true' + "&ecid=" + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
  //   .pipe(
  //     catchError(this.handleError.handleError<any>('getActiveMediaForModuleType', []))
  //   );
  // }

  // public getCustomerDefaultMediaPreference(customerId: string): Observable<CloudApiResponse> {
  //   return this.http.get<MediaModel[]>(this.envService.apiUrl + '/Customer/getDefaultMediaPref?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
  //   .pipe(
  //     catchError(this.handleError.handleError<any>('getCustomerDefaultMediaPreference'))
  //   );
  // }

  // public setCustomerDefaultMediaPreference(defaultMediaPref: CustomerDefaultMediaPreference){
  //   return this.http.put(this.envService.apiUrl + '/Customer/setDefaultMediaPref', this.apiService.createApiRequestPayload(defaultMediaPref), this.apiService.getCustomHeader())
  //   .pipe(
  //     catchError(this.handleError.handleError<any>('setCustomerMediaPreference'))
  //   );
  // }

// public getCustomerMediaPortalId(customerId: string): Observable<CloudApiResponse> {
  //   return this.http.get<string>(this.envService.apiUrl + '/Customer/getMediaPortalId?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
  //   .pipe(
  //     catchError(this.handleError.handleError<any>('getCustomerMediaPortalId'))
  //   );
  // }

  public getMediaPortals(): Observable<CloudApiResponse> {
    return this.http.get<MediaPortal[]>(this.envService.apiUrl + '/MediaPortal/all', this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('getMediaPortals', []))
    );
  }

  public addMediaPortal(mediaPortal: MediaPortal){
      return this.http.post(this.envService.apiUrl + '/MediaPortal/add', this.apiService.createApiRequestPayload(mediaPortal), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('addMediaPortal'))
      );
  }

  public deleteMediaPortal(mediaPortalId: string){
    return this.http.put(this.envService.apiUrl + '/MediaPortal/delete?empid=' + this.apiService.secureApiQueryString(mediaPortalId), null, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('deleteMediaPortal'))
    );
  }

  public undoDeleteMediaPortal(mediaPortalId: string){
    return this.http.put(this.envService.apiUrl + '/MediaPortal/undo?empid=' + this.apiService.secureApiQueryString(mediaPortalId), null, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('undodeleteMediaPortal'))
    );
  }
}
