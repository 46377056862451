// Angular
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

// Shared Lib
import { HandleErrorService, ApiService, WLSMSConsentFilter, WLTimeFilter, CustomerWLSMSConsentFilter, CustomerWLTimeFilter, EnvService } from 'kscigcorelib';


@Injectable({
  providedIn: 'root'
})
export class WorklistService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService: ApiService,
    private envService: EnvService
  ) { }

  public getWLSMSConsentFilter(customerId: string): Observable<any> {
    return this.http.get<WLSMSConsentFilter>(this.envService.apiUrl + '/WLCriteria/GetWLConsentFilter?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get WLSMSConsentFilter data'))
    );
  }

  public getWLTimeFilter(customerId: string): Observable<any> {
    return this.http.get<WLTimeFilter>(this.envService.apiUrl + '/WLCriteria/GetWLTimeFilter?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get WLTimeFilter data'))
    );
  }

  public UpdateWLSMSConsentFilter(customerWLSMSConsentFilter:CustomerWLSMSConsentFilter):Observable<any>{
    return this.http.post(this.envService.apiUrl + '/WLCriteria/UpdateConsentFilter', this.apiService.createApiRequestPayload(customerWLSMSConsentFilter, true), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get WLSMSConsentFilter data'))
    );
  }

  public UpdateWLTimeFilter(customerWLTimeFilter:CustomerWLTimeFilter):Observable<any>{
    return this.http.post(this.envService.apiUrl + '/WLCriteria/UpdateTimeFilter', this.apiService.createApiRequestPayload(customerWLTimeFilter, true), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get WLTimeFilter data'))
    );
  }

}