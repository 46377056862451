// Angular
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Shared Lib
import { HandleErrorService, EmailProvider, ApiService, EnvService } from 'kscigcorelib';


@Injectable({
  providedIn: 'root'
})
export class NotifyEmailService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService: ApiService,
    private envService: EnvService) { }

    public getAllEmailProviders(): Observable<any> {
      return this.http.get<EmailProvider[]>(this.envService.apiUrl + '/NotifyEmailProvider/Get', this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('Get all Email Provider data', []))
      );
    }

    public addEmailProvider(emailProvider: EmailProvider) {
      return this.http.post(this.envService.apiUrl + '/NotifyEmailProvider/add', this.apiService.createApiRequestPayload(emailProvider), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('Add Email Provider data', []))
      );
    }

    public updateEmailProvider(emailProvider: EmailProvider) {
      return this.http.put(this.envService.apiUrl + '/NotifyEmailProvider/update', this.apiService.createApiRequestPayload(emailProvider), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('Update Email Provider data', []))
      );
    }

    public softDeleteEmailProvider(emailProvider: EmailProvider) {
      return this.http.put(this.envService.apiUrl + '/NotifyEmailProvider/delete', this.apiService.createApiRequestPayload(emailProvider), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('Delete Email Provider data', []))
      );
    }
    
    public undoSoftDeleteEmailProvider(emailProvider: EmailProvider) {
      return this.http.put(this.envService.apiUrl + '/NotifyEmailProvider/undo', this.apiService.createApiRequestPayload(emailProvider), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('Undo Delete Email Provider data', []))
      );
    }
}
