import { Component, OnInit } from '@angular/core';

import { ActivityLogViewModel, ActivityTimeFilter, CloudApiResponse, LoggingService, LoadingBarService, EncryptionService, Customer } from 'kscigcorelib';
import { ActivityService } from './activity.service'
import { CustomerService } from '../customer/customer.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {

  public adminLogs:ActivityLogViewModel[];
  public customerLogs:ActivityLogViewModel[];
  public timeFilters:ActivityTimeFilter[];
  public selectedTimeFilterId:number = 0;
  public moduleTypes: string[] = Object.values(EnumActivityModuleType);
  public selectedModType: string = "All";
  public customerList: Customer[] = [];
  public selectedCustomerId:string = "1";
  public searchKeyword:string = "";
  public selectedTabIndex: number = 0;

  public detailHtml:string;

  constructor(
    private loggingService:LoggingService,
    private activityService:ActivityService,
    private loadingBarService: LoadingBarService,
    private customerService: CustomerService,
    private encryptionService: EncryptionService
  ) { 
  }

  ngOnInit(): void {
    this.getPageData();
  }

  getPageData(){
    this.loadingBarService.startBar();
    this.getTimeFilters();
    this.getCustomers();
  }

  private getTimeFilters() {
    this.activityService.getTimeFilters()
            .subscribe({
              next: (result: CloudApiResponse) => { 
                    this.loggingService.logVerbose(result.payload);
                    this.timeFilters = result.payload;                                                            
                    this.selectedTimeFilterId = 1;
                    
                    this.refreshActivityLogs();
                    },
              error: () => { this.loggingService.logError("Failed to get Activity log time filters."); },
              complete: () => { this.loggingService.logVerbose("Completed getting Activity log time filters."); }
            });
  }

  private getCustomers() {
    this.customerService.getCustomers(false)
      .subscribe({
          next: (result: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
                this.loggingService.logVerbose(decryptedPayload);
                this.customerList = JSON.parse(decryptedPayload);   
                var noneCustomer:Customer = new Customer();
                noneCustomer.CustomerId = "1";
                noneCustomer.CustomerName = "None";
                this.customerList.push(noneCustomer);                                       
                this.loadingBarService.stopBar();                
              },
          error: () => { this.loggingService.logError("Error loading Customers"); },
          complete: () => { this.loggingService.logVerbose("Completed loading Customers"); }
      });
  }

  timeFilterChanged(event) {  
    this.refreshActivityLogs();
  }

  clearSearch() {
    this.searchKeyword = '';
  }

  search() {
    if (this.searchKeyword) {
      this.refreshActivityLogs();
    }
  }

  refreshActivityLogs() {
    let timeFilterName:string = this.timeFilters.find(m => m.filterId == this.selectedTimeFilterId).filterName;
    // Get admin portal Logs
    this.activityService.getAdminPortalActivityLog(this.selectedTimeFilterId, this.selectedModType)
          .subscribe({
            next: (logResult: CloudApiResponse) => {           
                  this.loggingService.logVerbose(logResult.payload);
                  this.adminLogs = JSON.parse(logResult.payload);                    
            },
            error: () => { 
                  this.loggingService.logError("Failed to get Admin Portal Activity logs - TimeFilter: " + timeFilterName); 
                  this.loadingBarService.stopBar();
            },
            complete: () => {                  
                  this.loadingBarService.stopBar();
            }
          });
          
    // Get customer portal Logs
    if (this.selectedCustomerId == "1") { 
      if (this.customerLogs) {
        this.customerLogs.length = 0;
      }
    } else if (this.selectedCustomerId) {
      this.activityService.getCustomerPortalActivityLog(this.selectedTimeFilterId, this.selectedCustomerId, this.searchKeyword, this.selectedModType)
          .subscribe({
            next: (logResult: CloudApiResponse) => {  
                  var decryptedLogs = this.encryptionService.decryptUsingAES256(logResult.payload);         
                  this.loggingService.logVerbose(decryptedLogs);
                  this.customerLogs = JSON.parse(decryptedLogs);                    
            },
            error: () => { 
                  this.loggingService.logError("Failed to get Customer Portal Activity logs - TimeFilter: " + timeFilterName); 
                  this.loadingBarService.stopBar();
            },
            complete: () => {                                  
                  this.loadingBarService.stopBar();
            }
          });
    }
  }

  customerFilterChanged(event) {  
    this.refreshActivityLogs();
  }

  updateSelectedTabValue(event) {
    this.selectedTabIndex = event.index;
    /*
    if (this.timeFilters != null && this.timeFilters.length > 0) {
      this.selectedTimeFilterId = 1;
    }
    */
  }

  public showActivityDetails(htmlStr:string, target: HTMLElement) {
    this.clearSelection();

    var parentElem: HTMLElement = target.parentElement;
    parentElem.parentElement.parentElement.classList.add("isActive");
    this.detailHtml = htmlStr;
    this.displayActivityDetailsPanel(true);
  }

  private clearSelection() {
    var listItemElems = document.querySelectorAll<HTMLElement>(".mat-mdc-list-item")
    listItemElems.forEach((elem) => {
      elem.classList.remove("isActive");
    });
  }
  
  public hideActivityDetails() {
    this.clearSelection();
    this.displayActivityDetailsPanel(false);
  }

  displayActivityDetailsPanel(doShow:boolean) {
    if (doShow) {
      document.getElementById("detailsPanel").classList.remove("displayHidden");
      document.getElementById("detailsPanel").classList.add("activityDetailPanel");
      document.getElementById("detailsPanel").setAttribute("display", "inline-block");
    }
    else {
      document.getElementById("detailsPanel").classList.remove("activityDetailPanel");
      document.getElementById("detailsPanel").classList.add("displayHidden");
    }
  }

}

export enum EnumActivityModuleType
{
    All = "All",
    Auth = "Auth",
    Media = "Media",
    Notify = "Notify",
    Worklist = "Worklist"
}
