<h2 mat-dialog-title class="DialogCaptionLabel">
    Contact Editor
  </h2>
  <mat-dialog-content>
  
    <div class="alignCenter">
      <mat-form [formGroup]="contactEditorForm">
        <mat-form-field>
          <mat-label>First Name</mat-label>
          <input matInput type="text" placeholder="Ex. John" formControlName="FirstName"/>
          <mat-error>
            <div *ngIf="FirstName.hasError('required')">
              Required
            </div>
            <div *ngIf="FirstName.hasError('maxlength')">
              50 chars only
            </div>
            <div *ngIf="FirstName.hasError('pattern')">
              Only allowed chars (A-Z , . - ')
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Middle Name</mat-label>
          <input matInput type="text" placeholder="Ex. Smith" formControlName="MiddleName"/>
          <mat-error>
            <div *ngIf="MiddleName.hasError('maxlength')">
              50 chars only
            </div>
            <div *ngIf="MiddleName.hasError('pattern')">
              Only allowed chars (A-Z , . - ')
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Last Name</mat-label>
          <input matInput type="text" placeholder="Ex. Doe" formControlName="LastName"/>
          <mat-error>
            <div *ngIf="LastName.hasError('required')">
              Required
            </div>
            <div *ngIf="LastName.hasError('maxlength')">
              50 chars only
            </div>
            <div *ngIf="LastName.hasError('pattern')">
              Only allowed chars (A-Z , . - ')
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Alias</mat-label>
          <input matInput type="text" placeholder="Ex. Surgeon" formControlName="Alias"/>
          <mat-error>
            <div *ngIf="Alias.hasError('maxlength')">
              50 chars only
            </div>
            <div *ngIf="Alias.hasError('pattern')">
              Only allowed chars (A-Z , . - ')
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input type="Email" matInput placeholder="Ex. mail@gmail.com" formControlName="Email"/>
          <mat-icon matSuffix>mail_outline</mat-icon>
          <mat-error>
            <div *ngIf="Email.hasError('email')">
              Invalid email
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Mobile</mat-label>
          <input matInput type="text" placeholder="Ex. 310-227-7302" formControlName="Mobile" />
          <mat-icon matSuffix>phone</mat-icon>
          <mat-error>
            <div *ngIf="Mobile.hasError('maxlength')">
              12 chars only
            </div>
            <div *ngIf="Mobile.hasError('pattern')">
              Required format 310-227-7302
            </div>
            <div *ngIf="contactEditorForm.hasError('inValidContactPoint')">
              One contact point is required
            </div>
          </mat-error>
        </mat-form-field>
      </mat-form>
    </div>
  
  </mat-dialog-content>
  
  <mat-dialog-actions class="alignCenter">
    <button mat-raised-button class="btn-icon-default" style="margin-left:5px;" (click)="closeDialog(false)">Cancel
      <mat-icon>cancel</mat-icon>
    </button>   
    <button mat-raised-button class="btn-icon-default" style="margin-left:10px;margin-right: 5px;" [disabled]="!contactEditorForm.dirty" (click)="saveContact()">Save
      <mat-icon>save</mat-icon>
    </button>
  </mat-dialog-actions>