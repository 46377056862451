// Angular
import { Component, OnInit } from '@angular/core';

// Material
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDialog } from '@angular/material/dialog';

// Shared Lib
import { LoggingService, LoadingBarService, NotificationBarService, EncryptionService, CloudApiResponse, MediaPortal } from 'kscigcorelib';
import { ApplicationConfig, ApplicationConfigViewModel } from 'kscigcorelib';

// Component
import { MediaSettingsService } from '../media-settings.service';
import { MediaService } from 'src/app/shared/services/media.service';


@Component({
  selector: 'app-media-premier',
  templateUrl: './media-premier.component.html',
  styleUrls: ['./media-premier.component.css']
})
export class MediaPremierComponent implements OnInit {
  public mediaAppConfigs:ApplicationConfig[] = [];
  public mediaAppConfigsViewModel:ApplicationConfigViewModel[] = [];
  public mediaPortalList: MediaPortal[] = [];
  public mediaPortalListViewModel: MediaPortalViewModel[] = [];  
  public portalColumnsSchema: any = [
    {
        key: "ExMediaPortalId",
        type: "text",
        label: "Portal Id"
    },
    {
        key: "Description",
        type: "text",
        label: "Description"
    },
    {
      key: "IsEdit",
      type: "IsEdit",
      label: ""
    }  
  ];

  public portalDisplayedColumns: string[] = this.portalColumnsSchema.map(col => col.key);
  
  private mediaConfigsLoaded = false;
  public mediaPortalsLoaded = false;


  constructor(
    private loggingService: LoggingService,
    private loadingBarService: LoadingBarService,
    private notificationBarService: NotificationBarService,
    public dialog: MatDialog,
    private settingsService: MediaSettingsService,
    private mediaService: MediaService,
    private encryptionService: EncryptionService
  ) { }

  ngOnInit(): void {
    this.loadingBarService.startBar();
    this.loadMediaPortals();
    this.loadMediaConfigs();
  }

  private loadMediaPortals() {
    this.mediaService.getMediaPortals()
        .subscribe({
            next: (result: CloudApiResponse) => {
                  var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
                  let portals: MediaPortal[] = JSON.parse(decryptedPayload);
                  this.loggingService.logVerbose(portals);
                  //this.mediaPortalList = portals;
                  portals.forEach(element => {
                    var portal = new MediaPortalViewModel();
                    portal.ExMediaPortalId = element.ExMediaPortalId;
                    portal.Description = element.Description;
                    portal.IsDeleted = element.IsDeleted;
                    this.mediaPortalListViewModel.push(portal);                    
                  });
                  this.mediaPortalsLoaded = true;
                  if (this.mediaConfigsLoaded) {
                    this.loadingBarService.stopBar(); 
                  }                  
            },
            error: () => {
              this.loggingService.logError("Error loading media portals");
              this.notificationBarService.showError("Error calling service");
              this.loadingBarService.stopBar(); 
            },
            complete: () => { this.loggingService.logVerbose("Completed loading Premier media portals"); }
    });
  }

  private loadMediaConfigs() {    
    this.settingsService.getMediaApplicationConfigsByCategory("Premier")
      .subscribe({
          next: (result: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);      
                let applicationConfigs: ApplicationConfig[] = JSON.parse(decryptedPayload);
                this.loggingService.logVerbose(applicationConfigs);
                this.mediaAppConfigs = applicationConfigs;
                if(applicationConfigs != null){
                  applicationConfigs.forEach(applicationConfig => {
                    // Create the application Config View Model
                    let _mediaAppConfig = new ApplicationConfigViewModel();
                    _mediaAppConfig.Name = applicationConfig.Name;
                    _mediaAppConfig.Value = applicationConfig.IsEncrypted && applicationConfig.Value ? this.encryptionService.decryptUsingAES256(applicationConfig.Value) : applicationConfig.Value;
                    _mediaAppConfig.IsEncrypted = applicationConfig.IsEncrypted;
                    
                    this.mediaAppConfigsViewModel.push(_mediaAppConfig);
                    
                  });          
                  this.mediaConfigsLoaded = true;
                }
                if (this.mediaPortalsLoaded) {
                  this.loadingBarService.stopBar(); 
                }
              },
          error: () => {
                this.loggingService.logError("Error loading Premier media configs");
                this.notificationBarService.showError("Error calling service");
                this.loadingBarService.stopBar(); 
              },
          complete: () => { this.loggingService.logVerbose("Completed loading Premier media configs"); }
      });
  }

  private updateMediaConfig(appConfig: ApplicationConfig) {
    if(appConfig != null){      
      this.settingsService.setMediaApplicationConfig(appConfig)
        .subscribe({
            next: (result: CloudApiResponse) => {
                  let updated: boolean = result.payload;
                  this.loggingService.logVerbose(updated);
                  if(updated) {
                    this.mediaAppConfigs.find(m => m.Name == appConfig.Name).Value = appConfig.Value;
                    this.notificationBarService.showSuccess("Updated " + appConfig.Name);
                    this.loggingService.logInformation("Updated " + appConfig.Name);
                  } else {
                    this.notificationBarService.showError("Error Updating " + appConfig.Name);
                    this.loggingService.logError("Error Updating " + appConfig.Name);
                  }
                },
            error: () => {
                    this.loggingService.logError("Error updating media config " + appConfig.Name);
                    this.notificationBarService.showError("Error calling service");
                },
            complete: () => { this.loggingService.logVerbose("Completed updating media config " + appConfig.Name); }
        });
    } else {
      this.loggingService.logError("Error applicationConfig is null");
    }
  }

  public startEdit(appConfig: ApplicationConfigViewModel): void {
    appConfig.IsEditing = true;
  }

  public cancelEdit(appConfig: ApplicationConfigViewModel): void {
    let config = this.mediaAppConfigs.find(m => m.Name == appConfig.Name);
    if (config != undefined) {
      appConfig.Value = config.IsEncrypted && config.Value ? this.encryptionService.decryptUsingAES256(config.Value) : config.Value;
    }    
    appConfig.IsEditing = false;
  }

  public editConfig(appConfigVm: ApplicationConfigViewModel){
    this.loggingService.logVerbose(appConfigVm);

    let appConfig = new ApplicationConfig();
    appConfig.Name = appConfigVm.Name;
    appConfig.Value = appConfigVm.IsEncrypted && appConfigVm.Value != undefined ? this.encryptionService.encryptUsingAES256(appConfigVm.Value) : appConfigVm.Value;
    appConfig.IsEncrypted = appConfigVm.IsEncrypted;

    this.updateMediaConfig(appConfig);
    appConfigVm.IsEditing = false;
  }

  addNewBlankPortalRow() {
    if (this.mediaPortalListViewModel.filter(n => n.ExMediaPortalId == "").length == 0) {
      const newRow = {"ExMediaPortalId": "", "Description": "", IsDeleted: false, IsEdit: true}
      this.mediaPortalListViewModel = [...this.mediaPortalListViewModel, newRow];
    }
  }

  removeNewPortalRow(rowIndex: number) {
    this.mediaPortalListViewModel.pop();
    this.mediaPortalListViewModel = [...this.mediaPortalListViewModel];    
  }

  saveNewPortalRow(rowIndex: number) {
    let portalVM = this.mediaPortalListViewModel[rowIndex];
    if (portalVM.ExMediaPortalId != "" && this.mediaPortalListViewModel.filter(n => n.ExMediaPortalId == portalVM.ExMediaPortalId).length == 1) {
      let portal = new MediaPortal(); 
      portal.ExMediaPortalId = portalVM.ExMediaPortalId;
      portal.Description = portalVM.Description;

      this.mediaService.addMediaPortal(portal)
          .subscribe({
              next: (result: CloudApiResponse) => {
                    let added: boolean = result.payload;
                    this.loggingService.logVerbose(added);
                    if(added) {
                      this.mediaPortalListViewModel[rowIndex].IsEdit = false;
                      this.notificationBarService.showSuccess("Media Portal Added");
                      this.loggingService.logInformation("Premier Media Portal Added " + portal.ExMediaPortalId);
                    } else {
                      this.notificationBarService.showError("Media Portal Add Failed");
                      this.loggingService.logError("Premier Media Portal Add Failed");
                    }
                  },
              error: () => {
                      this.loggingService.logError("Premier Media Portal Add Failed");
                      this.notificationBarService.showError("Error calling service");
                  },
              complete: () => { this.loggingService.logVerbose("Completed Adding Premier Media Portal " + portal.ExMediaPortalId); }
          });  
      }
  }
  
  deletePortalRow(exMediaPortalId: string) {
    
    this.mediaService.deleteMediaPortal(exMediaPortalId)
        .subscribe({
            next: (result: CloudApiResponse) => { 
                  let deleted: boolean = result.payload;
                  this.loggingService.logVerbose(deleted);
                  if(deleted) {
                    this.mediaPortalListViewModel.find(n => n.ExMediaPortalId == exMediaPortalId).IsDeleted = true;
                    this.notificationBarService.showSuccess("Media Portal deleted");
                    this.loggingService.logInformation("Premier Media Portal Deleted " + exMediaPortalId);
                  } else {
                    this.notificationBarService.showError("Media Portal Delete Failed");
                    this.loggingService.logError("Premier Media Portal Delete Failed");
                  }
                },
            error: () => {
                    this.loggingService.logError("Premier Media Portal Delete Failed");
                    this.notificationBarService.showError("Error calling service");
                },
            complete: () => { this.loggingService.logVerbose("Completed Deleting Premier Media Portal " + exMediaPortalId); }
        });
  }

  undoDeletePortalRow(exMediaPortalId: string) {
    this.mediaService.undoDeleteMediaPortal(exMediaPortalId)
        .subscribe({
            next: (result: CloudApiResponse) => {
                  let added: boolean = result.payload;
                  this.loggingService.logVerbose(added);
                  if(added) {
                    this.mediaPortalListViewModel.find(n => n.ExMediaPortalId == exMediaPortalId).IsDeleted = false;
                    this.notificationBarService.showSuccess("Media Portal Delete Undone");
                    this.loggingService.logInformation("Premier Media Portal Delete Undone " + exMediaPortalId);
                  } else {
                    this.notificationBarService.showError("Media Portal Undo Delete Failed");
                    this.loggingService.logError("Premier Media Undo Delete Failed");
                  }
                },
            error: () => {
                    this.loggingService.logError("Premier Media Portal Undo Delete Failed");
                    this.notificationBarService.showError("Error calling service");
                },
            complete: () => { this.loggingService.logVerbose("Completed Undo Delete Premier Media Portal " + exMediaPortalId); }
        });
  }

  public syncMedia() {
    this.settingsService.syncMedia("Premier")
        .subscribe({
            next: (result: CloudApiResponse) => {
                  let updated: boolean = result.payload;
                  this.loggingService.logVerbose(updated);
                  if(updated) {
                    this.notificationBarService.showSuccess("Premier Media Sync completed successfully");
                    this.loggingService.logInformation("Premier Media Sync completed successfully");
                  } else {
                    this.notificationBarService.showError("Premier Media Sync Failed");
                    this.loggingService.logError("Premier Media Sync Failed");
                  }
                },
            error: () => {
                    this.loggingService.logError("Premier Media Sync Failed");
                    this.notificationBarService.showError("Error calling service");
                },
            complete: () => { this.loggingService.logVerbose("Completed Premier Media Sync"); }
        });
  }
}

export class MediaPortalViewModel {  
  ExMediaPortalId: string;
  Description: string;
  IsDeleted: boolean;
  IsEdit: boolean;

  constructor(){            
    this.ExMediaPortalId = null;
    this.Description = '';
    this.IsDeleted = false;  
    this.IsEdit = false;
  }
}
