// Angular Modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';

// Application Modules
import { AppRoutingModule } from './app-routing.module';

// Shared Lib
import { EnvService, KscigcorelibModule } from 'kscigcorelib';
import { MaterialModule } from 'kscigcorelib';
import { EnvServiceProvider } from 'kscigcorelib';

// Application Components
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CustomerComponent } from './customer/customer.component';
import { SideNavListComponent } from './side-nav-list/side-nav-list.component';
import { ReportsComponent } from './reports/reports.component';
import { CustomerEditorComponent } from './customer/customer-editor/customer-editor.component';
import { CustomerContactEditorComponent } from './customer/customer-contact-editor/customer-contact-editor.component';
import { CustomerSubscriptionEditorComponent } from './customer/customer-subscription-editor/customer-subscription-editor.component';
import { UserSessionEditorComponent } from './customer/user-session-editor/user-session-editor.component';
import { ActivityComponent } from './activity/activity.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CustomerConnectionInfoEditorComponent } from './customer/customer-connection-info-editor/customer-connection-info-editor.component';
import { NotificationEditorComponent } from './notifications/notification-editor/notification-editor.component';
import { SettingsComponent } from './settings/settings.component';
import { NotifySMSComponent } from './settings/notify-sms/notify-sms.component';
import { NotifyTranslatorComponent } from './settings/notify-translator/notify-translator.component';
import { NotifyEmailComponent } from './settings/notify-email/notify-email.component';
import { NotifyTranslatorEditorComponent } from './settings/notify-translator/notify-translator-editor/notify-translator-editor.component';
import { NotifySMSProviderEditorComponent } from './settings/notify-sms/notify-smsprovider-editor/notify-smsprovider-editor.component';
import { NotifyEmailProviderEditorComponent } from './settings/notify-email/notify-email-provider-editor/notify-email-provider-editor.component';
import { NotifySettingsEditorComponent } from './customer/notify-settings-editor/notify-settings-editor.component';
import { MediaRelaxationComponent } from './settings/media/media-relaxation/media-relaxation.component';
import { MediaPremierComponent } from './settings/media/media-premier/media-premier.component';

import { environment } from 'src/environments/environment';
import { LogoutComponent } from './logout/logout.component';
import { NotFoundComponent } from './error/not-found/not-found.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WorklistSettingsEditorComponent } from './customer/worklist-settings-editor/worklist-settings-editor.component';
import { ApplicationCacheComponent } from './settings/application-cache/application-cache.component';
import { GraphDrilldownComponent } from './reports/graph-drilldown/graph-drilldown.component';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication { 
  const eService = inject(EnvService);
  return new PublicClientApplication({ 
    auth: {
      authority: eService.authority,
      clientId: eService.uiClienId,
      redirectUri: eService.redirectUrl,
      postLogoutRedirectUri: eService.postLogoutRedirectUrl,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: environment.production? LogLevel.Error: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const eService = inject(EnvService);
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(eService.GRAPH_ENDPOINT, ['user.read']);
  protectedResourceMap.set(eService.apiUrl, [eService.APIScopeUri]);
  
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [
          'user.read',
        ]
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    CustomerComponent,
    SideNavListComponent,
    ReportsComponent,
    CustomerEditorComponent,
    CustomerContactEditorComponent,
    CustomerSubscriptionEditorComponent,
    UserSessionEditorComponent,
    ActivityComponent,
    NotificationsComponent,
    CustomerConnectionInfoEditorComponent,
    NotificationEditorComponent,
    SettingsComponent,
    NotifySMSComponent,
    NotifyTranslatorComponent,
    NotifyEmailComponent,
    NotifyTranslatorEditorComponent,
    NotifySMSProviderEditorComponent,
    NotifyEmailProviderEditorComponent,
    NotifySettingsEditorComponent,    
    MediaRelaxationComponent,
    MediaPremierComponent,
    LogoutComponent,
    NotFoundComponent,
    DashboardComponent,
    WorklistSettingsEditorComponent,
    ApplicationCacheComponent,
    GraphDrilldownComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    KscigcorelibModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    MsalModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
    },
    {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    EnvServiceProvider,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
