import { Component, OnInit, Inject } from '@angular/core';

// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Shared Lib
import { LoggingService } from 'kscigcorelib';
import { UtilizationDrillDownModalData } from 'kscigcorelib';

@Component({
  selector: 'app-graph-drilldown',
  templateUrl: './graph-drilldown.component.html',
  styleUrls: ['./graph-drilldown.component.css']
})
export class GraphDrilldownComponent {

  public utilizationDrillDownData:UtilizationDrillDownModalData;

  constructor(
    private loggingService:LoggingService,
    public dialogRef: MatDialogRef<GraphDrilldownComponent>, 
    @Inject(MAT_DIALOG_DATA) public inputData: UtilizationDrillDownModalData
    ) {       
      
    }

  ngOnInit(): void {
    this.loadPageData();
  }

  private loadPageData(){
    this.loggingService.logVerbose(this.inputData);
    this.utilizationDrillDownData = this.inputData;
  }

  public closeDialog(refresh:boolean) {
    this.dialogRef.close(refresh);
  }

}
