<header>
  <!-- Home page Tool bar -->
  <mat-toolbar *ngIf="coreAppPageMode === coreAppPageModeEnum.logout">
    {{productName}}
    <span style="flex: 1 1 auto;"></span>
    <button mat-raised-button *ngIf="!isValidUserSession" (click)="login()">
      Login
    </button>
  </mat-toolbar>
  
  <!-- Login Tool bar -->
  <mat-toolbar *ngIf="coreAppPageMode === coreAppPageModeEnum.login || coreAppPageMode === coreAppPageModeEnum.error">
    {{productName}} 
  </mat-toolbar>

  <!-- In App Tool bar --> 
  <mat-toolbar *ngIf="coreAppPageMode === coreAppPageModeEnum.appui">
    <mat-toolbar-row>
      <div>
        <button mat-icon-button (click)="onToggleSidenav()">
          <mat-icon>menu</mat-icon>
        </button>
        {{productName}}
      </div>
      <span style="flex: 1 1 auto;"></span>
      
      <button mat-icon-button [matMenuTriggerFor]="usermenu">
        <mat-icon>account_circle</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-menu #usermenu="matMenu">
    <div class="CaptionLabelSquare" style="max-width: 200px;">
      <span *ngIf="userRole">({{userRole}})</span>&nbsp;{{username}} 
    </div>
    
    <mat-divider></mat-divider>
    <mat-divider></mat-divider>
    <a mat-menu-item href="/logout"> 
      <mat-icon>power_settings_new</mat-icon>Logout
    </a>
  </mat-menu>
</header>