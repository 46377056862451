// Angular
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

// Shared Lib
import { HandleErrorService, ApplicationConfig, ApiService, EnvService } from 'kscigcorelib';



@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService: ApiService,
    private envService: EnvService    
  ) { }


    
  public getTimeFilters(): Observable<any> {
    return this.http.get<ApplicationConfig[]>(this.envService.apiUrl + '/ActivityLog/timeFilters', this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('getTimeFilters', []))
    );
  }

  public getAdminPortalActivityLog(timeFilterId:number, moduleType: string): Observable<any> {
    return this.http.get<ApplicationConfig>(this.envService.apiUrl + '/ActivityLog/adminportal?timeFilterId=' + timeFilterId + "&mType=" + moduleType, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('getAdminPortalActivityLog', []))
    );
  }

  public getCustomerPortalActivityLog(timeFilterId:number, customerId:string, keyword:string, moduleType: string): Observable<any> {
    return this.http.get<ApplicationConfig>(this.envService.apiUrl + '/ActivityLog/customerportal?timeFilterId=' + timeFilterId + "&ecid=" + this.apiService.secureApiQueryString(customerId) + "&kw=" + keyword + "&mType=" + moduleType, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('getCustomerPortalActivityLog', []))
    );
  }

}
