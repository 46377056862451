// Angular
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

// Material
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDialog } from '@angular/material/dialog';

// Shared Lib
import { LoggingService, LoadingBarService, NotificationBarService, ApplicationConfig, TranslationProvider, CloudApiResponse } from 'kscigcorelib';
import { Language, EncryptionService } from 'kscigcorelib';

// Component
import { LanguageViewModel } from './notify-translator-view-model';
import { NotifyTranslatorService } from './notify-translator.service';
import { SettingsService } from '../settings.service';
import { NotifyTranslatorEditorComponent } from './notify-translator-editor/notify-translator-editor.component';

@Component({
  selector: 'app-notify-translator',
  templateUrl: './notify-translator.component.html',
  styleUrls: ['./notify-translator.component.css']
})
export class NotifyTranslatorComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  ColumnsToDisplay = [
    'languageName',
    'languageCode',
    'editDelete'
  ];

  public translationProviderList: TranslationProvider[] = [];
  public languageList: LanguageViewModel[] = [];
  public newLanguage: Language;
  public languageViewModel = new MatTableDataSource<LanguageViewModel>();
  public displayedColumns: string[] = this.ColumnsToDisplay;

  constructor(
    private loggingService: LoggingService,
    private loadingBarService: LoadingBarService,
    private notificationBarService: NotificationBarService,
    private settingsService: SettingsService,
    private notifyTranslatorService: NotifyTranslatorService,
    private encryptionService: EncryptionService,
    public dialog: MatDialog,
    ) { 
    }

  ngOnInit(): void {
    this.loadingBarService.startBar();
    this.initNewLanguage();
    // Load Page Data
    this.loadPageData();
  }

  private loadPageData(){
    this.loadTranslationProviders();
    this.loadLanguageList(true);    
  }

  private loadTranslationProviders(){
    this.notifyTranslatorService.getAllTranslationProviders()
      .subscribe({
          next: (result: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);      
                this.translationProviderList = JSON.parse(decryptedPayload);
                this.loggingService.logVerbose(this.translationProviderList);               
              },
          error: () => {
                this.notificationBarService.showError("Error calling service");
                this.loadingBarService.stopBar(); 
              },
          complete: () => { this.loggingService.logVerbose("Completed getting translation providers"); }
      });
  }

  private loadLanguageList(stopLoadingbar:boolean){
    this.notifyTranslatorService.getAllLanguages()
      .subscribe({
          next: (result: CloudApiResponse) => {
            this.languageList = result.payload;
                this.loggingService.logVerbose(this.languageList); 
                this.languageViewModel.data.splice(0);              
                this.languageList.forEach(element => {
                  let vm = new LanguageViewModel();
                  vm.languageId = element.languageId;
                  vm.languageName = element.languageName;                  
                  vm.languageCode = element.languageCode;
                  vm.isDeleted = element.isDeleted;
                  vm.isDefault = element.isDefault;
                  vm.isEditing = element.isEditing;
                  vm.rowEditMode = element.rowEditMode;
                  this.languageViewModel.data.push(vm);
                });
                
                this.languageViewModel.sort = this.sort;
                if(stopLoadingbar){
                  this.loadingBarService.stopBar(); 
                }
              },
          error: () => {
                this.notificationBarService.showError("Error calling service");
                this.loadingBarService.stopBar(); 
                this.loggingService.logError("Error loading language list");
              },
          complete: () => { this.loggingService.logVerbose("Completed loading language list"); }
      });
  }

  public hasScroll(element: ElementRef): boolean {
    return element.nativeElement.scrollHeight > element.nativeElement.clientHeight;
  }

  public isOnEditMode(): boolean {
    return this.languageViewModel.data.findIndex((language) => language.isEditing) >= 0;
  }

  public startEdit(language: LanguageViewModel): void {
    language.isEditing = true;
  }

  public cancelEdit(language: LanguageViewModel): void {
    let origLanguage = this.languageList.find(m => m.languageId == language.languageId);
    language.languageName = origLanguage.languageName;
    language.languageCode = origLanguage.languageCode;
    language.isEditing = false;
  }

  public softDeleteLanguage(language: LanguageViewModel){
    this.loggingService.logInformation(language);
    this.notifyTranslatorService.softDeleteLanguage(language)
      .subscribe({
          next: (result: CloudApiResponse) => {
                  this.loadPageData();
                  this.notificationBarService.showSuccess("Deleted " +  language.languageName);
                  this.loggingService.logInformation("Language " +  language.languageName + " has been deleted");
              },
          error: () => {
                  this.notificationBarService.showError("Error deleting " +  language.languageName);
                  this.loggingService.logError("Error deleting language " +  language.languageName);
              },
          complete: () => { this.loggingService.logVerbose("Completed deleting language " +  language.languageName); }
      });
  }

  public undoSoftDeleteLanguage(language: LanguageViewModel){
    this.loggingService.logInformation(language);
    this.notifyTranslatorService.undoSoftDeleteLanguage(language)
      .subscribe({
          next: (result: CloudApiResponse) => {
                this.loadPageData();
                this.notificationBarService.showSuccess("Undone delete " +  language.languageName);
                this.loggingService.logInformation("Language " +  language.languageName + " has been undeleted");
              },
          error: () => {
                this.notificationBarService.showError("Error undeleting " +  language.languageName);
                this.loggingService.logInformation("Error undeleting language " +  language.languageName);
              },
          complete: () => { this.loggingService.logVerbose("Completed undeleting language " +  language.languageName); }
      });
  }

  private editLanguage(language: LanguageViewModel){
    if(language.languageId != ''){
      this.notifyTranslatorService.updateLanguage(language)
        .subscribe({
            next: (result: CloudApiResponse) => {
                    language.isEditing = false;
                    this.loadLanguageList(false);
                    this.notificationBarService.showSuccess("Updated " +  language.languageName);
                    this.loggingService.logInformation("Language " +  language.languageName + " has been updated");
                },
            error: () => {
                    this.notificationBarService.showError("Error upating " +  language.languageName);
                    this.loggingService.logError("Error upating language " +  language.languageName);
                },
            complete: () => { this.loggingService.logVerbose("Complete upating language " +  language.languageName); }
        });
    }
  }

  public cancelAdd(){
    this.initNewLanguage();
  }

  private initNewLanguage(){
    this.newLanguage = new Language();
  }

  public addLanguage(language: Language){
    this.loggingService.logInformation(language);
    this.notifyTranslatorService.addLanguage(language)
      .subscribe({
          next: (result: CloudApiResponse) => {
                this.loadLanguageList(false);
                this.initNewLanguage();
                this.notificationBarService.showSuccess("Added " +  language.languageName);
                this.loggingService.logInformation("Language " +  language.languageName + " has been added");
              },
          error: () => {
                this.notificationBarService.showError("Error adding " +  language.languageName);
                this.loggingService.logError("Error adding language " +  language.languageName);
              },
          complete: () => { this.loggingService.logVerbose("Completed adding language " +  language.languageName); }
      });
  }

  public openTranslatorEditor(translationProvider:TranslationProvider){
    this.loggingService.logVerbose("Open Editor");
    this.loggingService.logVerbose(translationProvider);

    const dialogRef = this.dialog.open(NotifyTranslatorEditorComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: translationProvider,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The dialog was closed');
      if(result){
        this.loadTranslationProviders();
      }
    });

  }
}