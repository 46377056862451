<div class="settings-grid">
    <div>
        <div class="CaptionLabelSquare">Settings</div>
        <mat-tree class="settings-grid-tree" [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding class="CursorPointer" (click)="treeNodeSelected(node)" [ngClass]="{'selected-node':selectedNodeName==node.name}">
                {{getDisplayTreeNodeName(node.name)}}
            </mat-tree-node>
            <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
                </button>
                {{getDisplayTreeNodeName(node.name)}}
            </mat-tree-node>
        </mat-tree>
    </div>

    <div class="contentBox settings-grid-item-treeContent">
        <div class="CaptionLabelSquare alignCenter borderBlue" >
            {{getDisplayTreeNodeName(selectedNodeName)}} 
            <span *ngIf="selectedNodeName != 'Settings'">Settings</span>
        </div>

      <div *ngIf="selectedNodeName == 'Settings'" class="alignCenter" style="margin-top: 40px;">Select a setting from left navigation pane</div>
      <!-- Notify Section Start -->
      <app-notify-email *ngIf="selectedNodeName == 'Email'"></app-notify-email>
      <app-notify-sms *ngIf="selectedNodeName == 'SMS'"></app-notify-sms>
      <app-notify-translator *ngIf="selectedNodeName == 'Translator'"></app-notify-translator>
      <!-- Notify Section End -->
      
      <!-- Media Section Start -->
      <!-- <app-media-relaxation *ngIf="selectedNodeName == 'Relaxation'"></app-media-relaxation>     -->
      <app-media-premier *ngIf="selectedNodeName == 'Premier'"></app-media-premier>      
      <!-- Media Section End -->

      <!-- Cache Start -->
      <app-application-cache *ngIf="selectedNodeName == 'Cache'"></app-application-cache>
      <!-- Cache End -->

    </div>
</div>
