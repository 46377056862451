// Angular 
import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';

// Shared Lib
import { LoginAttemptRequest, CloudApiResponse } from 'kscigcorelib';
import { NotificationBarService, LoggingService, HandleErrorService, SessionService, ApiService, EnvService } from 'kscigcorelib';

// Application


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private loggingService: LoggingService,    
    private apiService: ApiService,
    private sessionService: SessionService,
    private envService: EnvService
    ) { }

    public getUsers(customerId: string): Observable<CloudApiResponse> {
        return this.http.get(this.envService.apiUrl + '/User?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
        .pipe(
          catchError(this.handleError.handleError<any>('GetUsers', []))
        );
    }

    public deleteUser(userId: string): any {
        return this.http.delete(this.envService.apiUrl + '/user/delete?euid=' + this.apiService.secureApiQueryString(userId), this.apiService.getCustomHeader())
        .pipe(
          catchError(this.handleError.handleError<any>('Delete User', []))
        );
    }

    public undoDeleteUser(userId: string): any {
        return this.http.put(this.envService.apiUrl + '/user/undodelete?euid=' + this.apiService.secureApiQueryString(userId), null, this.apiService.getCustomHeader())
        .pipe(
          catchError(this.handleError.handleError<any>('Undo Delete User', []))
        );
    }

    public getUserSessions(userId: string): Observable<CloudApiResponse> {
      return this.http.get(this.envService.apiUrl + '/user/sessions?euid=' + this.apiService.secureApiQueryString(userId), this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('GetUserSessions', []))
      );
    }

    public terminateUserSession(sessionId: string): any {
      return this.http.put(this.envService.apiUrl + '/user/terminateSession?esid=' + this.apiService.secureApiQueryString(sessionId), null, this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('Terminate User Session'))
      );
    }

    public terminateAllUserSessions(userId: string): any {
      return this.http.put(this.envService.apiUrl + '/user/terminateAllSessions?euid=' + this.apiService.secureApiQueryString(userId), null, this.apiService.getCustomHeader())
      .pipe(
        catchError(this.handleError.handleError<any>('Terminate all User sessions'))
      );
    }

}
