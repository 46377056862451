<h2 mat-dialog-title class="DialogCaptionLabel">Manage Active User Sessions</h2>
<mat-dialog-content>
  
    <div *ngIf="sessions.length > 0">
      <label>Terminate all Sessions</label>
      <button mat-icon-button (click)="terminateAllSessions()">
          <mat-icon>delete</mat-icon>
      </button>                                      
    </div>
    <div class="Padding5px"></div>


    <div class="mat-elevation-z8">
    <table mat-table [dataSource]="sessions" class="mat-elevation-z8">
      
        <!-- Session Column -->
        <ng-container matColumnDef="Session">
          <th mat-header-cell *matHeaderCellDef>Session</th>
          <td mat-cell *matCellDef="let element">{{element.LoginAttemptId}}</td>
        </ng-container>
      
        <ng-container matColumnDef="ClientIP">
          <th mat-header-cell *matHeaderCellDef>Client IP</th>
          <td mat-cell *matCellDef="let element">{{element.ClientIP}}</td>
        </ng-container>

        <ng-container matColumnDef="DeviceId">
          <th mat-header-cell *matHeaderCellDef>Device ID</th>
          <td mat-cell *matCellDef="let element">{{element.DeviceId}}</td>
        </ng-container>

        <ng-container matColumnDef="SessionStartDt">
          <th mat-header-cell *matHeaderCellDef>Start Date</th>
          <td mat-cell *matCellDef="let element">{{element.DtSessionStart | date :'short'}}</td>
        </ng-container>

        <ng-container matColumnDef="LastHeartBeat">
          <th mat-header-cell *matHeaderCellDef>Last Heart Beat</th>
          <td mat-cell *matCellDef="let element">{{element.LastHeartBeat | date :'short'}}</td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="Terminate">
          <th mat-header-cell *matHeaderCellDef>Terminate</th>
          <td mat-cell *matCellDef="let element"> 
            <button mat-icon-button (click)="terminateUserSession(element.LoginAttemptId)">
                <mat-icon>delete</mat-icon>
            </button>      
          </td>
        </ng-container>
                    
        <tr mat-header-row *matHeaderRowDef="[ 'Session', 'ClientIP', 'DeviceId', 'SessionStartDt', 'LastHeartBeat', 'Terminate' ]"></tr>
        <tr mat-row *matRowDef="let row; columns: [ 'Session', 'ClientIP', 'DeviceId', 'SessionStartDt', 'LastHeartBeat', 'Terminate' ]"></tr>
      </table>
    </div>
  </mat-dialog-content>
<mat-dialog-actions class="alignCenter">
    <button mat-raised-button class="btn-icon-default" style="margin-left:5px;" (click)="closeDialog(true)" >Close      
    </button>       
</mat-dialog-actions>
