// Angular
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,FormControl,Validators } from '@angular/forms';

// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

// Shared Lib
import { LoggingService, LoadingBarService, NotificationBarService, CloudApiResponse, ApplicationConfig } from 'kscigcorelib';
import { CustomerLanguagePreference, ApplicationConfigViewModel } from 'kscigcorelib';

// component
import { NotifyService } from '../notify.service';
import { NotifySettingsEditorData, Message, MessageEditorData } from '../customer-model';

@Component({
  selector: 'app-notify-settings-editor',
  templateUrl: './notify-settings-editor.component.html',
  styleUrls: ['./notify-settings-editor.component.css']
})
export class NotifySettingsEditorComponent implements OnInit {

  public customerNotifyConfigsViewModel:ApplicationConfigViewModel[] = [];
  public notifySettingsEditorForm: UntypedFormGroup;
  public notifySettingsEditorData:NotifySettingsEditorData;
  public isSMSOptInEnabled:boolean = false;
  public welcomeMessage:MessageEditorData = null;

  public isWelcomeTextEnabledConfig: ApplicationConfig = new ApplicationConfig();
  public isPreOpMessageEnabledConfig: ApplicationConfig = new ApplicationConfig();
  public hrsPriorToSurgeryConfig: ApplicationConfig = new ApplicationConfig();
  public daysPriorToSurgeryConfig: ApplicationConfig = new ApplicationConfig();
  public preOpMsgIdConfig: ApplicationConfig = new ApplicationConfig();
  
  constructor(
    private loadingBarService: LoadingBarService,
    private loggingService:LoggingService,
    private notificationBarService: NotificationBarService,
    private notifyService:NotifyService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<NotifySettingsEditorComponent>, 
    @Inject(MAT_DIALOG_DATA) public inputData: NotifySettingsEditorData
    ) {
    }

    ngOnInit(): void {
      this.loadPageData();
    }
  
    private loadPageData(){
      this.loggingService.logVerbose(this.inputData);
      let _customerNotifyConfigsViewModel:ApplicationConfigViewModel[] = [];
      let _welcomeMessage:MessageEditorData = new MessageEditorData();
      let _customerNotifyConfig:ApplicationConfigViewModel = new ApplicationConfigViewModel();
      if(this.inputData != null && this.inputData.customerNotifyConfigs != null && this.inputData.customerLanguagePreference != null){
        this.loggingService.logVerbose("Edit Config");
        this.notifySettingsEditorData = this.inputData;
        this.inputData.customerNotifyConfigs.forEach(applicationConfig => {
          // Create the application Config View Model
          if (!applicationConfig.Name.includes('Auto')) {
            _customerNotifyConfig = new ApplicationConfigViewModel();
            _customerNotifyConfig.Name = applicationConfig.Name;
            _customerNotifyConfig.DisplayName = applicationConfig.DisplayName;
            _customerNotifyConfig.Value = applicationConfig.Value;
            _customerNotifyConfig.IsEncrypted = applicationConfig.IsEncrypted;
            _customerNotifyConfigsViewModel.push(_customerNotifyConfig);
            if(applicationConfig.Name == "IsSMSOptInEnabled"){
              this.isSMSOptInEnabled = (applicationConfig.Value == "1") ? true: false;
            }
          } else { 
              if (applicationConfig.Name == 'SendAutoWelcomeTextEnabled')
                this.isWelcomeTextEnabledConfig = applicationConfig; 
              else if (applicationConfig.Name == 'SendAutoPreOpMessageEnabled')
                this.isPreOpMessageEnabledConfig = applicationConfig; 
              else if (applicationConfig.Name == 'SendAutoWelcomeText_HrsPriorToSurgery')
                this.hrsPriorToSurgeryConfig = applicationConfig;
              else if (applicationConfig.Name == 'SendAutoPreOpMessage_DaysPriorToSurgery')
                this.daysPriorToSurgeryConfig = applicationConfig;
              else if (applicationConfig.Name == 'SendAutoPreOpMessage_MessageId')
                this.preOpMsgIdConfig = applicationConfig;
          }          
        });
        this.customerNotifyConfigsViewModel = _customerNotifyConfigsViewModel;
        _welcomeMessage.MessageId = this.notifySettingsEditorData.message.MessageId;
        _welcomeMessage.MessageText = this.notifySettingsEditorData.message.MessageText;
        _welcomeMessage.MessageCategoryId = this.notifySettingsEditorData.message.MessageCategoryId;
        this.welcomeMessage = _welcomeMessage;
      } else {
        this.loggingService.logVerbose("New");
      }
    }

    public closeDialog(refresh:boolean) {
      this.dialogRef.close(refresh);
    }

    public onChangeLanguagePreference(event:MatSlideToggleChange, languageId:string){
      this.loadingBarService.startBar(); 
      this.loggingService.logVerbose(languageId);
      this.loggingService.logVerbose(event.checked);
      this.setLanguagePreference(languageId, event.checked);
    }

    private setLanguagePreference(languageId:string, enabledForNotify:boolean){
      let langPref:CustomerLanguagePreference = new CustomerLanguagePreference();
      langPref.languageId = languageId;
      langPref.isEnabledForNotify = enabledForNotify;
      this.loggingService.logVerbose(langPref);
      this.notifyService.setCustomerLanguagePreference(this.inputData.customerId, langPref)
        .subscribe({
            next: (result: CloudApiResponse) => {
                  let isSet:boolean = result.payload;
                  if(isSet){
                    this.notificationBarService.showSuccess("Preference updated");
                  } else {
                    this.notificationBarService.showSuccess("Preference update failed");
                  }
                  this.loadingBarService.stopBar(); 
                },
            error: () => {
                  this.loggingService.logError("Error updating Language Preference");
                  this.notificationBarService.showError("Error calling service");
                  this.loadingBarService.stopBar(); 
                },
            complete: () => { this.loggingService.logVerbose("Completed updating Language Preference"); }
        });
    }

    public onChangeCustomerOptInPreference(event:MatSlideToggleChange){
      this.loadingBarService.startBar(); 
      this.setCustomerNotifyConfig(this.getCustomerNotifyOptInConfig());
    }

    private setCustomerNotifyConfig(customerNotifyConfig:ApplicationConfig){
      // associate proper customer Id
      this.notifyService.setCustomerNotifyConfig(this.inputData.customerId, customerNotifyConfig)
        .subscribe({
            next: (result: CloudApiResponse) => {
                  let isSet:boolean = result.payload;
                  if (isSet) {
                    this.notificationBarService.showSuccess("Preference updated");
                  } else {
                    this.notificationBarService.showSuccess("Preference update failed");
                  }
                  this.loadingBarService.stopBar(); 
                },
            error: () => {
                  this.loggingService.logError("Error updating notify preference");
                  this.notificationBarService.showError("Error calling service");
                  this.loadingBarService.stopBar(); 
                },
            complete: () => { this.loggingService.logVerbose("Completed updating notify preference"); }
        });
    }

    private getCustomerNotifyOptInConfig():ApplicationConfig{
      let applicationConfig = new ApplicationConfig();
      applicationConfig = this.customerNotifyConfigsViewModel.find(m => m.Name == "IsSMSOptInEnabled")
      applicationConfig.Value = this.isSMSOptInEnabled ? "1" : "0";
      return applicationConfig;
    }

    public startEdit(appConfig: ApplicationConfigViewModel): void {
      appConfig.IsEditing = true;
    }
  
    public cancelEdit(appConfig: ApplicationConfigViewModel): void {
      appConfig.Value = this.customerNotifyConfigsViewModel.find(m => m.Name == appConfig.Name).Value;
      appConfig.IsEditing = false;
    }
  
    public editConfig(appConfig: ApplicationConfigViewModel){
      this.loggingService.logVerbose(appConfig);
      this.setCustomerNotifyConfig(appConfig);
      appConfig.IsEditing = false;
    }


    public startEditMessage(message: MessageEditorData): void {
      message.IsEditing = true;
    }
  
    public cancelEditMessage(message: MessageEditorData): void {
      message.MessageText = this.notifySettingsEditorData.message.MessageText;
      message.IsEditing = false;
    }
  
    public editConfigMessage(message: MessageEditorData){
      this.loggingService.logVerbose(message);
      this.setWelcomeMessage(message);
      message.IsEditing = false;
    }

    public setWelcomeMessage(message:Message): void{
      // associate proper customer Id
      this.notifyService.setCustomerWelcomeMessage(this.inputData.customerId, message)
        .subscribe({
            next: (result: CloudApiResponse) => {
                  let isSet:boolean = result.payload;
                  if (isSet) {
                    this.notificationBarService.showSuccess("Welcome message updated");
                  } else {
                    this.notificationBarService.showSuccess("Welcome message failed");
                  }
                  this.loadingBarService.stopBar(); 
                },
            error: () => {
                  this.loggingService.logError("Error updating Welcome message");
                  this.notificationBarService.showError("Error calling service");
                  this.loadingBarService.stopBar(); 
                },
            complete: () => { this.loggingService.logVerbose("Completed updating Welcome message"); }
        });
    }

    public onWelcomeTextEnabled(enabled: boolean) {
      this.isWelcomeTextEnabledConfig.Value = enabled ? "true" : "false";
      this.setCustomerNotifyConfig(this.isWelcomeTextEnabledConfig);
    }
  
    public onPreOpMessageEnabled(enabled: boolean) {
      this.isPreOpMessageEnabledConfig.Value = enabled ? "true" : "false";
      this.setCustomerNotifyConfig(this.isPreOpMessageEnabledConfig);
    }
  
    public hrsPriorToSurgeryChanged() {
      this.setCustomerNotifyConfig(this.hrsPriorToSurgeryConfig);
    }
  
    public daysPriorToSurgeryChanged() {
      this.setCustomerNotifyConfig(this.daysPriorToSurgeryConfig);
    }
  
    public preOpMessageSelectionChanged() {
      this.setCustomerNotifyConfig(this.preOpMsgIdConfig);
    }

}
