// Angular
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

// Application Shared library
import { LoggingService, EnvService } from 'kscigcorelib';

// Application
import { appPageName } from '../constants/app.enum';
import { query } from '@angular/animations';

@Injectable({
    providedIn: 'root'
  })
export class RouteHelper {
    
    public baseHref:string = '';

    constructor( private router:Router,
        private loggingService:LoggingService,
        private envService: EnvService
        ) {
          if(envService.baseHref == '/'){
            this.baseHref = '';        
          } else {
            this.baseHref = envService.baseHref;
    
          }
     }
    
    public RoutePage(pagePath:string){
        this.router.navigateByUrl(pagePath);
    }

    public NavigatePage(pagePath:appPageName, queryString:string) {
      this.loggingService.logVerbose("Navigate request:" + pagePath + "queryString:" + queryString);
      this.loggingService.logVerbose("Basehref:" + this.envService.baseHref);
      if(queryString != null && queryString.length > 0){
        queryString = "?" + queryString;
      } else {
        queryString = "";
      }
      location.href = location.protocol + "//" + location.host + this.baseHref + pagePath.toString() + queryString;
    }

    public NavigateToLogoutPage(){
      this.NavigatePage(appPageName.logout, null);
    }
  
    public NavigateToLoginPage(){
      this.NavigatePage(appPageName.login, null);
    }

    public NavigateToApp(){
      this.NavigatePage(appPageName.dashboard, null);
    }
}
