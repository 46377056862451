import { WLSMSConsentFilter, WLTimeFilter } from 'kscigcorelib';

export class WorklistEditorData{
    customerId:string;
    wlTimeFilters:WLTimeFilter[];
    wlSMSConsentFilters:WLSMSConsentFilter[];
    constructor(){
            this.customerId = '';
            this.wlTimeFilters = [];
            this.wlSMSConsentFilters = [];
        }
}