export enum appPageName{
    login = "/login",
    logout = "/logout",
    dashboard = "/dashboard",
    customer = "/customer",
    reports = "/reports",
    activity = "/activity",
    notifications = "/notifications",
    settings = "/settings",
    error = "/error"
}