<div class="backgroundWhite">
    <!-- Providers -->
    <div>
        <div class="CaptionLabelSquare notify-providers-grid">
            <mat-label>Providers</mat-label>
        </div>
        <div>
            <div class="notify-providers-content-grid" style="height: 50px;">
                <div class="notify-providers-grid Padding10px" *ngFor="let translationProvider of translationProviderList">
                    <div>{{translationProvider.TranslationProviderName}}</div>
                    <div class="Caption">
                        <button mat-icon-button (click)="openTranslatorEditor(translationProvider)" title="Edit">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div> 
                </div>
                <div *ngIf="translationProviderList.length == 0" class="Padding5px">No Translators found</div>
            </div>
        </div>
    </div>

    <!-- languages -->
    <div>
        <div class="CaptionLabelSquare borderBlue">
            <mat-label>Languages</mat-label>
        </div>
        <mat-table [dataSource]="languageViewModel" class="borderBlue" matSort>
            <ng-container matColumnDef="languageName">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Language Name </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="languageCode">
                <mat-header-cell *matHeaderCellDef> Language Code </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="editDelete">
                <mat-header-cell *matHeaderCellDef>
                    <div class="alignCenter">
                        Actions
                    </div>
                </mat-header-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        </mat-table>


        <mat-table #dataTable [dataSource]="languageViewModel" class="borderBlue notify-translator-data-table" matSort [ngClass]="{ 'has-scroll': hasScroll(dataTable._elementRef) }">
            <ng-container matColumnDef="languageName">
                <mat-cell *matCellDef="let element"> 
                    <div *ngIf="!element.isEditing">
                        {{element.languageName}} 
                    </div>
                    <div *ngIf="element.isEditing">
                        <input type="text" placeholder="Language Name" [(ngModel)]="element.languageName"/>
                    </div>
                </mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="languageCode">
                <mat-cell *matCellDef="let element"> 
                    <div *ngIf="!element.isEditing">
                        {{element.languageCode}} 
                    </div>
                    <div *ngIf="element.isEditing">
                        <input type="text" placeholder="Language Code" [(ngModel)]="element.languageCode"/>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="editDelete">
                <mat-cell *matCellDef="let element"> 
                    <div *ngIf="element.isDefault == false" class="Caption">
                        <div class="alignCenter" *ngIf="element.isDeleted == true">
                            <button mat-icon-button (click)="undoSoftDeleteLanguage(element)" title="Undo">
                                <mat-icon>undo</mat-icon>
                            </button>
                        </div>
                        <div class="alignCenter" *ngIf="element.isDeleted == false">
                            <div class="edit-mode" *ngIf="!element.isEditing">
                                <button mat-icon-button (click)="startEdit(element)" title="Edit">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button (click)="softDeleteLanguage(element)" title="Delete">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                            <div class="edit-mode" *ngIf="element.isEditing">
                                <button mat-icon-button (click)="editLanguage(element)" title="Update">
                                    <mat-icon>update</mat-icon>
                                </button>
                                <button mat-icon-button (click)="cancelEdit(element)" title="Cancel">
                                    <mat-icon >cancel</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-cell>
            </ng-container>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-table [dataSource]="languageViewModel" class="mat-elevation-z8">
            <ng-container matColumnDef="languageName">
                <mat-footer-cell *matFooterCellDef> 
                    <input type="text" placeholder="Language Name" style="width:70%" [(ngModel)]="newLanguage.languageName" />
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="languageCode">
                <mat-footer-cell *matFooterCellDef> 
                    <input type="text" placeholder="Language Code" style="width:70%" [(ngModel)]="newLanguage.languageCode" />
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="editDelete">
                <mat-footer-cell *matFooterCellDef>
                    <div class="alignCenter">
                        <button mat-icon-button (click)="addLanguage(newLanguage)" title="Add Language">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button mat-icon-button (click)="cancelAdd()" title="Cancel">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </div>
                </mat-footer-cell>
            </ng-container>

            <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
        </mat-table>
    </div>

</div>