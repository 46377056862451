<mat-toolbar class="secondary">
    <div>
        Utilization Data
    </div>

    <span style="flex: 1 1 auto;"></span>
    
    <mat-form-field>
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Start date" (dateInput)="dateFilterChanged('input', $event)" (dateChange)="dateFilterChanged('change', $event)">
          <input matEndDate formControlName="end" placeholder="End date" (dateInput)="dateFilterChanged('input', $event)" (dateChange)="dateFilterChanged('change', $event)">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <mat-form-field *ngIf="customerList">
        <mat-label>Customer</mat-label>
        <mat-select (selectionChange)="customerFilterChanged($event)" [(value)]="selectedCustomerId" >
            <mat-option *ngFor="let cust of customerList" [value]="cust.CustomerId">
                {{cust.CustomerName}}
            </mat-option>                                        
        </mat-select>
    </mat-form-field>
</mat-toolbar>

<div class="contentBox overflowAuto Padding10px" style="height: calc(100vh - 130px);">
    <div class="Padding10px AlignCenter colorError" *ngIf="!allFiltersValid">
        Select a proper date range and a customer to see utilization data.
    </div>
    <div class="report-body" *ngIf="allFiltersValid && selectedCustomerId != '1' &&  graphSeriesData != null">
        <!-- Chart Data -->
        
        <div *ngIf="graphSeriesData != null">
            <div>
                <ngx-charts-line-chart
                    [scheme]="colorScheme"
                    [xAxis]="showGraphProperty"
                    [yAxis]="showGraphProperty"
                    xScaleMin="0"
                    yScaleMin="0"
                    [xAxisLabel]="xAxisLabel"
                    [yAxisLabel]="yAxisLabel"
                    [showXAxisLabel]="showGraphProperty"
                    [showYAxisLabel]="showGraphProperty"
                    [showGridLines]="hideGraphProperty"
                    [timeline]="showGraphProperty"
                    [legend]="showGraphProperty"
                    [results]="graphSeriesData"
                    (select)="onSelect($event)"
                    (activate)="onActivate($event)"
                    (deactivate)="onDeactivate($event)"
                    legendPosition="below"
                    style="fill: var(--accent-color);">
                </ngx-charts-line-chart>
            </div>
        </div>
        
        <div *ngIf="graphSeriesData != null">
            <div class="contentBox borderDarkBlue">
                <div class="CaptionLabel">Success</div>
                <div class="report-body-total borderDarkBlue">
                    <div>
                        <mat-icon style="margin-top: -10px;">login</mat-icon>
                        <mat-label style="margin-left: 5px;;">Logins:&nbsp;</mat-label>
                    </div>
                    <div class="alignLeft">
                        <span *ngIf="totalLogins > 0" class="cursorPointer" (click)="openDrillDownModal('logins')"><u>{{totalLogins}}</u></span>
                        <span *ngIf="!(totalLogins > 0)">{{totalLogins}}</span>
                    </div>
                </div>
    
                <div class="report-body-total borderDarkBlue">
                    <div>
                        <mat-icon style="margin-top: -10px;">message</mat-icon>
                        <mat-label style="margin-left: 5px;;">SMS Sent:&nbsp;</mat-label>
                    </div>
                    <div class="alignLeft">{{totalSMSSent}}</div>
                </div>

                <div class="report-body-total borderDarkBlue">
                    <div>
                        <mat-icon style="margin-top: -10px;">email</mat-icon>
                        <mat-label style="margin-left: 5px;;">Email Sent:&nbsp;</mat-label>
                    </div>
                    <div class="alignLeft">{{totalEmailSent}}</div>
                </div>
    
                <div class="report-body-total borderDarkBlue">
                    <div>
                        <mat-icon style="margin-top: -10px;">translate</mat-icon>
                        <mat-label style="margin-left: 5px;;">Translations:&nbsp;</mat-label>
                    </div>
                    <div class="alignLeft">{{totalTranslations}}</div>
                </div>
                <div class="report-body-total borderDarkBlue">
                    <div>
                        <mat-icon style="margin-top: -10px;">movie</mat-icon>
                        <mat-label style="margin-left: 5px;;">Media Played (hrs):&nbsp;</mat-label>
                    </div>
                    <div class="alignLeft">
                        <span *ngIf="totalMediaPlayed > 0" class="cursorPointer" (click)="openDrillDownModal('media')"><u>{{totalMediaPlayed}}</u></span>
                        <span *ngIf="!(totalMediaPlayed > 0)">{{totalMediaPlayed}}</span>
                    </div>
                </div>
                
                <div class="CaptionLabel">Failures</div>
                <div class="report-body-total borderDarkBlue">
                    <div>
                        <mat-icon style="margin-top: -10px;">login</mat-icon>
                        <mat-label style="margin-left: 5px;;">Login Failed:&nbsp;</mat-label>
                    </div>
                    <div class="alignLeft">
                        <span *ngIf="totalLoginsFailed > 0" class="cursorPointer" (click)="openDrillDownModal('loginfailed')"><u>{{totalLoginsFailed}}</u></span>
                        <span *ngIf="!(totalLoginsFailed > 0)">{{totalLoginsFailed}}</span>
                    </div>
                </div>
    
                <div class="report-body-total borderDarkBlue">
                    <div>
                        <mat-icon style="margin-top: -10px;">message</mat-icon>
                        <mat-label style="margin-left: 5px;;">SMS Failed:&nbsp;</mat-label>
                    </div>
                    <div class="alignLeft">{{totalSMSFailed}}</div>
                </div>
    
                <div class="report-body-total borderDarkBlue">
                    <div>
                        <mat-icon style="margin-top: -10px;">email</mat-icon>
                        <mat-label style="margin-left: 5px;;">Email Failed:&nbsp;</mat-label>
                    </div>
                    <div class="alignLeft">{{totalEmailFailed}}</div>
                </div>
                
            </div>
        </div>
    </div>
    <!-- Chart Data Error -->
    <div class="Padding10px AlignCenter colorError" *ngIf="graphSeriesData == null && selectedCustomerId != '1'">
        Error! Fetching chart data.
    </div>
</div>


