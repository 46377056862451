<div>
    <div class="dashboard-grid">
        <!-- Total Customer Group By -->
        <div class="borderDarkBlue">
            <div class="CaptionLabelSquare" style="height:50px;">
                <div style="float: right;"> 
                    <mat-form-field style="width: 200px;font-size: 14px;">
                        <mat-label>Group by</mat-label>
                        <mat-select style="display: inline;" placeholder="Group by" [(ngModel)]="groupBy" (selectionChange)="onGroupBySelectionChange($event)">
                            <mat-option *ngFor="let groupByOption of groupByOptions" [value]="groupByOption.key">
                                {{groupByOption.value}}
                              </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div style="display: inline-block; margin-top:5px;">
                    <span>Customers: </span> <span>{{totalCustomers}}</span>
                </div>
            </div>
            <div class="contentBoxSquare chart-container">
                <div class="chart-wrapper" *ngIf="dataCustomersPieChart != null && dataCustomersPieChart.length > 0">
                    <ngx-charts-pie-chart
                        [scheme]="colorScheme"
                        [results]="dataCustomersPieChart"
                        [gradient]="true"
                        [legend]="true"
                        legendPosition="below"
                        [labels]="false"
                        [doughnut]="false"
                        (select)="onSelectCustomersPieChart($event)"
                        style="fill: var(--accent-color);">
                    </ngx-charts-pie-chart>
                </div>
                <div *ngIf="dataCustomersPieChart == null || dataCustomersPieChart.length == 0" class="Centered Padding10px">
                    No data found 
                </div>
            </div>
        </div>
        
        <!-- Activity Group by --> 
        <div class="borderDarkBlue">
            <div class="CaptionLabelSquare" style="height:50px;">
                <div style="float: right;"> 
                    <mat-form-field style="width: 200px;font-size: 14px;">
                        <mat-label>Time</mat-label>
                        <mat-select style="display: inline;" placeholder="Time" [(ngModel)]="activityTime" (selectionChange)="onActivityDataSelectionChange($event)">
                            <mat-option *ngFor="let activityTimeOption of activityTimeOptions" [value]="activityTimeOption.key">
                                {{activityTimeOption.value}}
                              </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div style="display: inline-block; margin-top:5px;">
                    Activity
                </div>
            </div>
            <div class="contentBoxSquare chart-container">                
                <div class="number-card-chart-container" *ngIf="dataCustomerActivity != null && dataCustomerActivity.length > 0">
                    <div *ngFor="let item of dataCustomerActivity; index as i">
 
                        <div class="Padding10px" style="width: 180px;"> 
                            <div class="borderDarkBlue" style="background-color: white; border-radius: 5px;">
                                <div class="Padding10px">
                                    <div >
                                        <mat-icon>{{getMatIcon(item.name)}}</mat-icon> <span style="font-size: 13px; margin-left: 5px;">{{item.name}}</span>
                                    </div>  
                                    <div style="font-size: 26px;" class="Centered">
                                        {{item.value}}
                                    </div>
                                </div>
                                <div  [ngStyle]="{'background-color': colorScheme2.domain[i], 'height': '10px'}"></div>
                            </div>
                        </div>

                    </div>
                                      
                </div>                
                <div *ngIf="dataCustomerActivity == null || dataCustomerActivity.length == 0" class="Centered Padding10px"> 
                    No data found 
                </div>
            </div>
        </div>

        <!-- Installations -->
        <div class="borderDarkBlue">
            <div class="CaptionLabelSquare" style="height:50px;">
                <div style="float: right;"> 
                    <mat-form-field style="width: 200px;font-size: 14px;">
                        <mat-label>Time</mat-label>
                        <mat-select style="display: inline;" placeholder="Time" [(ngModel)]="installsTime" (selectionChange)="onInstallationsDataSelectionChange($event)">
                            <mat-option *ngFor="let installsTimeOption of installsTimeOptions" [value]="installsTimeOption.key">
                                {{installsTimeOption.value}}
                              </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div style="display: inline-block; margin-top:5px;">
                    Installations
                </div>
            </div>
            <div class="contentBoxSquare chart-container">
                <div class="chart-wrapper" style="margin-top: 50px;" *ngIf="dataCustomerInstallBarChart != null && dataCustomerInstallBarChart.length > 0">
                    <ngx-charts-bar-vertical-2d
                        [scheme]="dualColorScheme"
                        [results]="dataCustomerInstallBarChart"
                        [gradient]="true"
                        [xAxis]="true"
                        [yAxis]="true"
                        [legend]="true"
                        [showXAxisLabel]="true"
                        [showYAxisLabel]="true"
                        xAxisLabel="Time"
                        yAxisLabel="Number"
                        (select)="onSelectCustomerInstallBarChart($event)"
                        style="fill: var(--accent-color);">
                    </ngx-charts-bar-vertical-2d>
                </div>
                <div *ngIf="dataCustomerInstallBarChart == null || dataCustomerInstallBarChart.length == 0" class="Centered Padding10px"> 
                    No data found 
                </div>
            </div>
        </div>

        <!-- UpComing Subscription end -->
        <div class="borderDarkBlue">
            <div class="CaptionLabelSquare" style="height:50px;">
                <div style="float: right;"> 
                    <mat-form-field style="width: 200px;font-size: 14px;">
                        <mat-label>Time</mat-label>
                        <mat-select style="display: inline;" placeholder="Time" [(ngModel)]="subscriptionEndTime" (selectionChange)="onSubscriptionDataSelectionChange($event)">
                            <mat-option *ngFor="let subscriptionEndTimeOption of subscriptionEndTimeOptions" [value]="subscriptionEndTimeOption.key">
                                {{ subscriptionEndTimeOption.value}}
                              </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div style="display: inline-block; margin-top:5px;">
                    Upcoming Subscriptions end
                </div>
            </div>
            <div class="contentBoxSquare subscriptionList-container" >
                <div *ngFor="let dataCustomerSubscriptionEnd of dataCustomerSubscriptionEndList" style="width: 100%;">
                    <div class="Padding5px" >
                        <div>
                            {{dataCustomerSubscriptionEnd.CustomerName}}
                        </div>
                        <label>End:&nbsp;</label><span>{{ getDateOnly(dataCustomerSubscriptionEnd.SubscriptionEndDate) }}</span>
                    </div>
                </div>
                <div *ngIf="dataCustomerSubscriptionEndList == null || dataCustomerSubscriptionEndList.length == 0" class="Centered Padding10px">
                    No data found
                </div>
            </div>
        </div>
    </div>
</div>
