// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  productName: 'ComfortOR1\u00ae Admin',
  companyName: 'KARL STORZ',
  
  
  // // Azure Auth Settings
  // authority: 'https://login.microsoftonline.com/ff11a263-d0d9-46fc-8290-b9debadf0eb2',
  // GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me',
  // // Azure Client Settings
  // uiClienId: '1cc11141-7f57-451d-90f4-016e38f733d5',
  // redirectUrl: 'http://localhost:4201/login',
  // postLogoutRedirectUrl: 'https://localhost:4201/logout',

  // // Admin API URL
  // apiUrl: 'http://localhost:62085/api/v1.0',
  // // API Scopes
  // APIScopeUri: 'https://karlstorzdev.onmicrosoft.com/app-comfortor1v8-adminapi-dev-cus/access_as_user',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
