<h2 mat-dialog-title class="DialogCaptionLabel">Subscription Editor</h2>
<mat-dialog-content>
  <div class="alignCenter">
    <mat-form [formGroup]="customerSubscriptionEditorForm">
      <mat-form-field>
        <mat-label>Start Date (mm/dd/yyyy)</mat-label>
        <input matInput [matDatepicker]="startdatepicker" formControlName="subscriptionStartDate"/>
        <mat-datepicker #startdatepicker></mat-datepicker>
        <mat-datepicker-toggle matSuffix [for]="startdatepicker"></mat-datepicker-toggle>
        <mat-error>
          <div *ngIf="subscriptionStartDate.hasError('required')">
            Required
          </div>
        </mat-error>
      </mat-form-field>      
      <br>
      <mat-form-field>
        <mat-label>End Date (mm/dd/yyyy)</mat-label>
        <input matInput type="text" [matDatepicker]="enddatepicker" formControlName="subscriptionEndDate"/>
        <mat-datepicker #enddatepicker></mat-datepicker>
        <mat-datepicker-toggle matSuffix [for]="enddatepicker"></mat-datepicker-toggle>
        <mat-error>
          <div *ngIf="subscriptionEndDate.hasError('required')">
            Required
          </div>
        </mat-error>
      </mat-form-field>      
      <br>
      <mat-form-field>
        <mat-label>Seats</mat-label>
        <input matInput type="text" placeholder="" formControlName="seats"/>            
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Users per Seat</mat-label>
        <input matInput type="text" placeholder="" formControlName="usersPerSeat"/>            
      </mat-form-field>

    </mat-form>
    
    <div class="customer-subscriptionOptions">
      <div></div>
      <div class="Padding5px" style="text-align: left;">
        <mat-checkbox [checked]="isWorklistEnabled" [(ngModel)]="isWorklistEnabled">WL</mat-checkbox>
        <br>        
        <mat-checkbox [checked]="isNotifyEnabled" [(ngModel)]="isNotifyEnabled">Notify</mat-checkbox>
        <br>        
        <mat-checkbox [checked]="isMediaPremierEnabled" [(ngModel)]="isMediaPremierEnabled">Media Premier</mat-checkbox>
      </div>
      <div></div>
    </div>
  </div>
</mat-dialog-content>
  
<mat-dialog-actions class="alignCenter">
  <button mat-raised-button class="btn-icon-default" style="margin-left:5px;" (click)="closeDialog(false)">Cancel
    <mat-icon>cancel</mat-icon>
  </button>   
  <button mat-raised-button class="btn-icon-default" style="margin-left:10px;margin-right: 5px;" (click)="saveCustomerSubscription()">
    Save
    <mat-icon>save</mat-icon>
  </button>
</mat-dialog-actions>