// Angular
import { Component, OnInit } from '@angular/core';
import { KeyValue } from '@angular/common';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

// Material
import { MatDialog } from '@angular/material/dialog';

// Shared Lib
import { LoadingBarService, NotificationBarService, LoggingService, CoreHelper, CloudApiResponse } from 'kscigcorelib';
import { MessageBoxComponent, MessageBoxModel, MessageBoxType } from 'kscigcorelib';
import { Notification } from 'kscigcorelib';

// Module
import { NotificationService } from './notification.service';
import { NotificationEditorComponent } from './notification-editor/notification-editor.component';
import { orderByOptionEnum } from './notification-model';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  public notificationList: Notification[] = [];
  public orderByOptions:KeyValue<orderByOptionEnum,string>[] = [];
  public orderBy:orderByOptionEnum;

  constructor( 
    private loggingService:LoggingService,
    private loadingBarService: LoadingBarService,
    private notificationBarService:NotificationBarService,
    private notificationService: NotificationService,
    private coreHelper: CoreHelper,
    public dialog: MatDialog,
    ) { 
    }

  ngOnInit(): void {
    // Start loading bar
    this.loadingBarService.startBar();
    // Load Page Data
    this.loadPageData();
  }

  private loadPageData(){
    this.loadPageConstants();
    this.getNotifications();
  }

  private loadPageConstants(){
    let _orderByOptions:KeyValue<orderByOptionEnum,string>[] = [];
    _orderByOptions.push({key:orderByOptionEnum.StartDateAsc,value:"Start Date Asc"});
    _orderByOptions.push({key:orderByOptionEnum.StartDateDesc,value:"Start Date Desc"});
    _orderByOptions.push({key:orderByOptionEnum.ExpireDateAsc,value:"Expire Date Asc"});
    _orderByOptions.push({key:orderByOptionEnum.ExpireDateDesc,value:"Expire Date Desc"});
    this.orderByOptions = _orderByOptions;
    this.orderBy = orderByOptionEnum.StartDateAsc;
  }

  /**
   * get Notifications
   */
  private getNotifications(){
    this.notificationService.getNotifications()
      .subscribe({
          next: (result:CloudApiResponse) => {
                let notifications: Notification[] = result.payload;
                this.loggingService.logVerbose(notifications);
                this.notificationList = notifications;
                this.loadingBarService.stopBar();
              },
          error: () => { this.loggingService.logError("Error getting notifications"); },
          complete: () => { this.loggingService.logVerbose("Completed getting notifications"); }
      });
  }

  
  public onOrderBySelectionChange(event:Event){
    this.loggingService.logVerbose(this.orderBy);
    if(this.orderBy == orderByOptionEnum.StartDateAsc){
      this.notificationList.sort(function(a, b){
        if(a.startDateTime < b.startDateTime) { return -1; }
        if(a.startDateTime > b.startDateTime) { return 1; }
        return 0;
      });
    } else if(this.orderBy == orderByOptionEnum.StartDateDesc){
      this.notificationList.sort(function(a, b){
        if(a.startDateTime > b.startDateTime) { return -1; }
        if(a.startDateTime < b.startDateTime) { return 1; }
        return 0;
      });
    } else if(this.orderBy == orderByOptionEnum.ExpireDateAsc){
      this.notificationList.sort(function(a, b){
        if(a.expireDateTime < b.expireDateTime) { return -1; }
        if(a.expireDateTime > b.expireDateTime) { return 1; }
        return 0;
      });
    } else if(this.orderBy == orderByOptionEnum.ExpireDateDesc){
      this.notificationList.sort(function(a, b){
        if(a.expireDateTime > b.expireDateTime) { return -1; }
        if(a.expireDateTime < b.expireDateTime) { return 1; }
        return 0;
      });
    }
    
  }

  public openNotificationEditor(notification:Notification){
    this.loggingService.logVerbose("Open Editor");
    this.loggingService.logVerbose(notification);
    const dialogRef = this.dialog.open(NotificationEditorComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: notification,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The dialog was closed');
      if(result){
        // Reload notifications
        this.getNotifications();
      }
    });
  }


  /**
   * delete Notification
   */
  public deleteNotification(notification:Notification){
    this.loggingService.logInformation(notification);

    let messageBoxData:MessageBoxModel = new MessageBoxModel();
    messageBoxData.message = "Are you sure you want to delete '" + notification.detail + "'?";
    messageBoxData.messageBoxType = MessageBoxType.yesno;
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: messageBoxData,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The dialog was closed');
      if(result){
        this.loggingService.logVerbose("Delete initiated");
        this.notificationService.deleteNotification(notification.notificationId)
          .subscribe({
              next: (result:CloudApiResponse) => {
                    let deleted: boolean = result.payload;
                    this.notificationBarService.showSuccess("Deleted Notification")
                    this.loggingService.logVerbose("Deleted Notification" + notification.notificationId);
                    // Reload notification Contacts
                    this.getNotifications();
                  },
              error: () => {
                    this.notificationBarService.showError("Error deleting Notification");
                    this.loggingService.logError("Error deleting Notification " +  notification.notificationId);
                  },
              complete: () => { this.loggingService.logVerbose("Completed deleting Notification " +  notification.notificationId); }
          });
      } else {
        this.loggingService.logVerbose("Delete was cancelled");
      }
    });
  }

  public getDateOnly(datetime:string):string{
    return this.coreHelper.getDateOnly(datetime);
  }
}
