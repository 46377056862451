// Angular
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

// Shared Lib
import { HandleErrorService, ApplicationConfig, ApiService, EnvService } from 'kscigcorelib';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService: ApiService,
    private envService: EnvService
  ) { }

  public getAllNotifyApplicationConfigs(): Observable<any> {
    return this.http.get<ApplicationConfig[]>(this.envService.apiUrl + '/NotifySetting/all', this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('getNotifyApplicationConfig', []))
    );
  }

  public getNotifyApplicationConfigByCategory(name: string): Observable<any> {
    return this.http.get<ApplicationConfig>(this.envService.apiUrl + '/NotifySetting/getConfigByCategory?categoryName=' + name, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('getNotifyApplicationConfigByCategory', []))
    );
  }

  public setNotifyApplicationConfig(applicationConfig: ApplicationConfig): Observable<any> {
    var requetPayload = this.apiService.createApiRequestPayload(applicationConfig);// alert(requetPayload.value);
    return this.http.put(this.envService.apiUrl + '/NotifySetting/setConfig', requetPayload, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('setNotifyApplicationConfig', []))
    );
  }
}
