export class DashboardChartData {
  name:string;
  value:number;

  constructor(){
      this.name = '';
      this.value = 0;
  }
}


export class DashboardGroupChartData {
  name:string;
  series:DashboardChartData[];

  constructor(){
      this.name = '';
      this.series = [];
  }
}

export enum groupByOptionEnum{
  Modules = 0,
  Hl7Feed = 1,
  Region = 2,
  Status = 3,
}

export enum installsTimeOptionEnum{
  LastSixMonths = 0, //- Months
  LastYear = 1, //- Months
  Last3Year = 2, //- Year
  Last5Year = 3, //- Year
  All = 4, //- Year
  YTD = 5, //- Months
}

export enum activityTimeOptionEnum{
  CurrentMonth = 0, // Weekly - Or daily if days < 7
  LastMonth = 1, // Weekly
  LastThreeMonths = 2, // Monthly
  LastSixMonths = 3, // Monthly
  LastYear = 4, // Monthly
}

export enum subscriptionEndTimeOptionEnum{
  Next3Months = 0, 
  NextSixMonths = 1,
  Next12Months = 2,
}