<mat-toolbar class="secondary">
    <button mat-mini-fab color="" (click)="openCustomerEditor(null)" title="Add Customer">
        <mat-icon>add</mat-icon>
    </button>
    <span style="flex: 1 1 auto;"></span>
    <div *ngIf="isFilteredResult">
        <button mat-icon-button class="Caption" *ngIf="isFilteredResultOn">
            <mat-icon (click)="toggleFilterResult()">filter_list</mat-icon>
        </button>
        <button mat-icon-button class="Caption" *ngIf="!isFilteredResultOn">
            <mat-icon (click)="toggleFilterResult()">filter_alt</mat-icon>
        </button>
    </div>
        Customers (total: {{customerList.length}})
    <span style="flex: 1 1 auto;"></span>
   
    <mat-form-field style="width: 200px;font-size: 14px;">
        <mat-label>Order by</mat-label>
        <mat-select style="display: inline;" [(ngModel)]="orderBy" (selectionChange)="onOrderBySelectionChange($event)">
            <mat-option *ngFor="let orderByOption of orderByOptions" [value]="orderByOption.key">
                {{orderByOption.value}}
              </mat-option>
        </mat-select>
    </mat-form-field>
</mat-toolbar>

<div class="customer-title-header CaptionLabelSquare">
    <div>Customer Name</div>
    <div class="AlignCenter">Install Start</div>
    <div class="AlignCenter">Go Live</div>
    <div class="AlignCenter">Subscription End</div>
    <div class="AlignCenter">Actions</div>
</div>

<div class="contentBox overflowAuto" style="height: calc(100vh - 165px);">
    <mat-accordion *ngIf="pageDataLoaded">
        <div *ngIf="customerList == null || customerList.length == 0" class="Centered Padding10px">
            No customers found
        </div>
        <mat-expansion-panel hideToggle *ngFor="let customer of customerList" (opened)="onExpansionPanelOpen(customer.CustomerId)">
            <!-- Header -->
            <mat-expansion-panel-header style="margin:10px">
                <mat-panel-title>
                    <div class="customer-title-panel">
                        <div>
                            {{ customer.CustomerName }} ({{ customer.CustomerShortName }})
                        </div>
                        <div class="alignCenter">
                            {{ getDateOnly(customer.InstallStartDate) }}
                        </div>
                        <div class="alignCenter">
                            {{ getDateOnly(customer.GoLiveDate) }}
                        </div>
                        <div class="alignCenter">
                            {{ getDateOnly(customer.SubscriptionEndDate) }}
                        </div>
                        <div class="alignCenter">
                            <button mat-icon-button color="" style="margin-right:10px;" (click)="$event.stopPropagation();$event.preventDefault();openCustomerEditor(customer)" title="Edit">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button color="" *ngIf="!customer.IsDeleted" style="margin-right:10px;" (click)="$event.stopPropagation();$event.preventDefault();setCustomerStatus(customer, false)" title="Soft Delete">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button mat-icon-button color="" *ngIf="customer.IsDeleted" style="margin-right:10px;" (click)="$event.stopPropagation();$event.preventDefault();setCustomerStatus(customer, true)" title="Undo Delete">
                                <mat-icon>undo</mat-icon>
                            </button>
                            <button mat-icon-button color="" style="margin:5px;color:white;background-color: var(--error-color);border-radius: 5%;" (click)="$event.stopPropagation();$event.preventDefault();deleteCustomer(customer)" title="Remove">
                                <mat-icon>delete_forever</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-panel-title>
            
            </mat-expansion-panel-header>
            
            <!-- Content -->
            <div>
                <mat-slide-toggle [(ngModel)]="isShowBasicSettings" (change)="onChangeBasicSettings($event)">Show basic settings</mat-slide-toggle>
                <br>
                <div *ngIf="isShowBasicSettings">
                    <br />
                    <div>
                        <b>CustomerId -</b> {{ customer.CustomerId }}
                    </div>
                    <br />
                    <!-- Customer Basic Info header -->
                    <div class="customer-details-grid CaptionLabelSquare alignCenter">
                        <div>Address&nbsp;&nbsp;
                            <button mat-mini-fab color="" (click)="openCustomerEditor(customer)" title="Edit Address">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>
                        <div>Contacts&nbsp;&nbsp;
                            <button mat-mini-fab color="" (click)="openContactEditor(null)" title="Add Contact">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                        <div>Subscriptions&nbsp;&nbsp;
                            <button mat-mini-fab color="" (click)="openSubscriptionEditor(null)" title="Add Subscription">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                        <div style="margin: auto;">User Management&nbsp;&nbsp;                            
                        </div>
                    </div>
                    <!-- Customer Basic Info details -->
                    <div class="customer-details-grid">
                        <!-- Address -->
                        <div class="contentBoxSquare backgroundWhite Padding5px">
                            <div>
                                
                                <div *ngIf="customer.StreetAddress" style="padding-left: 10px;">
                                    <mat-icon class="Caption" style="font-size: 18px !important;">location_on</mat-icon>
                                    &nbsp;{{customer.StreetAddress}}
                                </div>
                                <div style="padding-left: 40px;">
                                    <div>{{customer.City}}</div>
                                    <div>{{customer.State}}</div>
                                    <div>{{customer.Zipcode}}</div>
                                    <div>{{customer.Country}}</div>
                                </div>
                                <div *ngIf="customer.Phone" style="padding-left: 10px;">
                                    <mat-icon class="Caption" style="font-size: 18px !important;">phone</mat-icon>
                                    &nbsp;{{customer.Phone}}
                                </div>
                                <div *ngIf="customer.Region" style="padding-left: 10px;padding-top:5px;">
                                    <mat-icon class="Caption" style="font-size: 18px !important;">map</mat-icon>
                                    &nbsp;{{customer.Region}}
                                </div>
                            </div>
                        </div>
                        
                        <!-- Contacts -->
                        <div class="contentBoxSquare backgroundWhite overflowAuto" style="height: 245px">
                            <div *ngFor="let contact of contactList">
                                <div class="borderBottomBlue Padding5px">
                                    <div class="customer-contact-row">
                                        <div>
                                            <mat-icon class="Caption" style="vertical-align: middle;">account_box</mat-icon>
                                            &nbsp;{{contact.FirstName}}&nbsp;{{contact.LastName}}&nbsp;&nbsp;
                                            <span *ngIf="contact.Alias">(</span>{{contact.Alias}}<span *ngIf="contact.Alias">)</span>
                                        </div>
                                        <div class="Caption" style="text-align: right;">
                                            <button mat-icon-button (click)="openContactEditor(contact)" title="Edit">
                                                <mat-icon style="vertical-align: middle;">edit</mat-icon>
                                            </button>
                                            <button mat-icon-button (click)="deleteCustomerContact(contact)" title="Delete">
                                                <mat-icon class="cursorPointer" style="vertical-align: middle;">delete</mat-icon>
                                            </button>                                        
                                        </div>
                                    </div>
                                    <div *ngIf="contact.Mobile" style="padding-left: 10px;">
                                        <mat-icon class="Caption" style="font-size: 16px !important;vertical-align: middle;">phone</mat-icon>
                                        &nbsp;{{contact.Mobile}}
                                    </div>
                                    <div *ngIf="contact.Email" style="padding-left: 10px;">
                                        <mat-icon class="Caption" style="font-size: 16px !important;vertical-align: middle;">mail_outline</mat-icon>
                                        &nbsp;{{contact.Email}}
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="contactList.length == 0" class="alignCenter">
                                <label>No Contacts found.</label>
                            </div>
                        </div>

                        <!-- Subscription -->
                        <div class="contentBoxSquare backgroundWhite overflowAuto" style="height: 245px">
                            <div *ngFor="let customerSubscription of customerSubscriptions">
                                <div class="Padding5px">
                                    <div class="Caption" style="float: right;">
                                        <button mat-icon-button (click)="openSubscriptionEditor(customerSubscription)" title="Edit">
                                            <mat-icon>edit</mat-icon>
                                        </button> 
                                        <button mat-icon-button (click)="deleteCustomerSubscription(customerSubscription)" title="Delete">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>     

                                    <div class="customer-subscription-row">
                                        <div>
                                            <label>Start:&nbsp;</label>
                                            <span>{{getDateOnly(customerSubscription.SubscriptionStartDate)}}</span>
                                        </div>
                                        <div>
                                            <label>End:&nbsp;</label>
                                            <span>{{getDateOnly(customerSubscription.SubscriptionEndDate)}}</span>
                                        </div>
                                                                                                                   
                                    </div>
                                    <div class="paddingLeft10">
                                        <div>
                                            <label>Seats:&nbsp;</label>
                                            <span>{{customerSubscription.Seats}}</span>
                                        </div>
                                        <div>
                                            <label>Users per Seat:&nbsp;</label>
                                            <span>{{customerSubscription.UsersPerSeat}}</span>
                                        </div>
                                    </div>  
                                    <div class="paddingLeft10">
                                        <mat-checkbox style="width: 150px;" [checked]="customerSubscription.IsWorklistEnabled" disabled><label>Worklist</label></mat-checkbox>
                                        <br>
                                        <mat-checkbox style="width: 150px;" [checked]="customerSubscription.IsNotifyEnabled" disabled><label>Notify</label></mat-checkbox>
                                        <br>                                        
                                        <mat-checkbox style="width: 150px;" [checked]="customerSubscription.IsMediaPremierEnabled" disabled><label>Media Premier</label></mat-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="customerSubscriptions.length == 0" class="alignCenter">
                                <label>No subscriptions found.</label>
                            </div>
                        </div>

                        <!-- User Management -->
                        <div class="contentBoxSquare backgroundWhite overflowAuto paddingLeft10 Padding5px" style="height: 245px">
                            <div *ngFor="let usr of domainUsers">
                                <div class="user-management-grid">                                    
                                        <div class="valign-center">
                                        <span>{{usr.Name}}</span>
                                        </div>
                                        <div class="valign-center">
                                        <span >
                                            <a (click)="showUserSessions(usr.UserId)">
                                                Sessions                                                
                                            </a>                                            
                                        </span>
                                        </div> 
                                        <div>
                                        <span class="Caption">                                            
                                            <button mat-icon-button *ngIf="usr.IsDeleted" (click)="undoDeleteUser(usr.UserId)">
                                                <mat-icon>undo</mat-icon>
                                            </button>                                            
                                            <button mat-icon-button *ngIf="!usr.IsDeleted" (click)="deleteUser(usr.UserId)">
                                                <mat-icon>delete</mat-icon>
                                            </button>                                        
                                        </span>
                                    </div>                                    
                                </div>
                            </div>                                               
                        </div>

                    </div>
                </div>
                
                <br>
                <mat-slide-toggle [(ngModel)]="isShowAdvanceSettings" (change)="onChangeAdvanceSettings($event)">Show advance settings</mat-slide-toggle>
                <br>
                <div *ngIf="isShowAdvanceSettings">
                    <!-- Customer Module related Settings header -->     
                    <div class="customer-admin-details-grid CaptionLabelSquare alignCenter">
                        <div>Connections&nbsp;&nbsp;
                            <button mat-mini-fab color="" (click)="openConnectionInfoEditor()" title="Edit Connection Settings">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>
                        <div>Worklist&nbsp;&nbsp;
                            <button mat-mini-fab color="" (click)="openWorklistSettingsEditor()" title="Edit Worklist Filters">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>
                        <div>Notify&nbsp;&nbsp;
                            <button mat-mini-fab color="" (click)="openNotifySettingsEditor()" title="Edit Notify Settings">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>
                        <div style="margin: auto;">Media&nbsp;&nbsp;                                                     
                        </div>
                    </div>

                    <!-- Customer Module related Settings Details -->     
                    <div class="customer-admin-details-grid">
                        <!-- Connections  -->
                        <div class="contentBoxSquare backgroundWhite overflowAuto" style="height: 245px">
                            <!-- Interfaces -->
                            <div class="CaptionLabel">Interfaces:</div>
                            <div class="Centered Padding5px">
                                <mat-checkbox [checked]="customerConnectionInfo.ADT" disabled>ADT</mat-checkbox>&nbsp;
                                <mat-checkbox [checked]="customerConnectionInfo.SIU" disabled>SIU</mat-checkbox>&nbsp;
                            </div>
                            <!-- DB Connections-->
                            <div class="CaptionLabel">Cloud DB & AD:</div>
                            <div> 
                                <div class="borderBottomBlue Padding5px">
                                    <label>Database:&nbsp;</label>
                                    <span>{{customerConnectionInfo.CloudDatabaseName}}</span>
                                </div>
                                <div class="borderBottomBlue Padding5px">
                                    <label>Domain:&nbsp;</label>
                                    <span>{{customerConnectionInfo.CloudDomainName}}</span>
                                </div>
                                <div class="borderBottomBlue Padding5px">
                                    <label>TenantId:&nbsp;</label>
                                    <span>{{customerConnectionInfo.CloudTenantId}}</span>
                                </div>
                                <div class="borderBottomBlue Padding5px">
                                    <label>Idle Timeout (minutes):&nbsp;</label>
                                    <span>{{customerConnectionInfo.IdleTimeoutMinutes}}</span>
                                </div>
                            </div>
                            <!-- On Prem Urls-->
                            <div class="CaptionLabel">OnPrem:</div>
                            <div>
                                <div class="borderBottomBlue Padding5px">
                                    <label>API URL:&nbsp;</label>
                                    <span>{{customerConnectionInfo.OnPremAPIServerURL}}</span>
                                </div>
                                <div class="borderBottomBlue Padding5px">
                                    <label>Server IP:&nbsp;</label>
                                    <span>{{customerConnectionInfo.OnPremAPIServerIPAddress}}</span>
                                </div>
                            </div>
                        </div>

                        <!-- Worklist -->
                        <div class="contentBoxSquare backgroundWhite overflowAuto" style="height: 245px">
                            <div class="CaptionLabel">Time Filters:</div>
                            <div *ngFor="let wlTimeFilter of wlTimeFilters">
                                <div class="borderBottomBlue Padding5px">
                                    <label style="margin-left: 0px;width: 120px;">{{wlTimeFilter.filterName}}</label>
                                    <mat-slide-toggle
                                        [checked]="!wlTimeFilter.isDisabled" 
                                        disabled></mat-slide-toggle>
                                </div>
                            </div>
                            <div class="CaptionLabel">Consent Filters:</div>
                            <div *ngFor="let wlSMSConsentFilter of wlSMSConsentFilters">
                                <div class="borderBottomBlue Padding5px">
                                    <label style="margin-left: 0px;width: 120px;">{{wlSMSConsentFilter.filterName}}</label>
                                    <mat-slide-toggle
                                        [checked]="!wlSMSConsentFilter.isDisabled" 
                                        [ngModel]="!wlSMSConsentFilter.isDisabled" (ngModelChange)="wlSMSConsentFilter.isDisabled = $event" disabled></mat-slide-toggle>
                                </div>
                            </div>
                        </div>
                        <!-- Notify -->
                        <div class="contentBoxSquare backgroundWhite overflowAuto" style="height: 245px">
                            <div class="CaptionLabel">Options:</div>
                            <div *ngFor="let config of customerNotifyConfigs">
                                <div class="borderBottomBlue Padding5px" *ngIf="config.Name == 'IsSMSOptInEnabled'">
                                    <mat-icon>textsms</mat-icon>
                                    <label style="margin-left: 20px;">SMS Opt-In</label>
                                    <mat-slide-toggle style="margin-left: 5px" 
                                        [checked]="isSMSOptInEnabled" 
                                        [(ngModel)]="isSMSOptInEnabled" disabled></mat-slide-toggle>
                                </div>
                            </div>
                            <div *ngFor="let config of customerNotifyConfigs">
                                <div class="borderBottomBlue Padding5px" *ngIf="config.Name != 'IsSMSOptInEnabled'">
                                    <label>{{config.DisplayName}}:&nbsp;</label>
                                    <span>{{config.Value}}</span>
                                </div>
                            </div>
                            <div class="borderBottomBlue Padding5px">
                                <label>Welcome Text:&nbsp;</label>
                                <span>{{this.welcomeMessageText}}</span>
                            </div>

                            <div>
                                <div class="CaptionLabel">Languages:</div>
                                <div *ngFor="let language of customerLanguagePreference">
                                    <div class="Padding10px borderBottomBlue">
                                        <mat-icon>translate</mat-icon>
                                        <label style="margin-left: 20px;">{{language.languageName}}</label>
                                        <mat-slide-toggle style="margin-left: 10px" 
                                            [checked]="language.isEnabledForNotify" 
                                            [(ngModel)]="language.isEnabledForNotify" disabled></mat-slide-toggle>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Media -->
                        <div class="contentBoxSquare backgroundWhite overflowAuto">
                            <div class="CaptionLabel">Media Settings</div>
                            
                            <div *ngIf="customerSubscriptionLatest.IsMediaRelaxationEnabled || customerSubscriptionLatest.IsMediaPremierEnabled">
                                <div *ngIf="mediaPortalList != undefined && mediaPortalList.length > 0">
                                    <mat-form-field class="Padding10px">                                        
                                            <mat-label>Choose Media Portal &nbsp;&nbsp;</mat-label>                                            
                                                <mat-select required
                                                    [(ngModel)]="customerMediaPortalId"
                                                    (selectionChange)="onMediaPortalSelectionChange()" >
                                                    <ng-container *ngFor="let mediaPortal of mediaPortalList">
                                                        <mat-option [value]="mediaPortal.ExMediaPortalId">                                                        
                                                        {{mediaPortal.ExMediaPortalId}} 
                                                        (<span>{{mediaPortal.Description}}</span>)                                                                                                             
                                                        </mat-option>
                                                    </ng-container>                                                    
                                                </mat-select>                                                                                            
                                    </mat-form-field>                                                                  
                                </div>
                                <div *ngIf="mediaList != undefined && mediaList.length > 0">
                                    <mat-form-field class="Padding10px">                                        
                                            <mat-label>Choose Media &nbsp;&nbsp;</mat-label>                                            
                                            <mat-select required
                                                [(ngModel)]="customerDefaultMediaPreference.MediaId"
                                                (selectionChange)="onDefaultMediaSelectionChange()" >
                                                <ng-container *ngFor="let media of mediaList">
                                                    <mat-option *ngIf="media.MediaTypeId === 2" [value]="media.MediaId">                                                        
                                                    {{media.Title}} 
                                                    (<span *ngIf="media.MediaTypeId === 2">video</span>)                                                                                                            
                                                    </mat-option>
                                                </ng-container>                                                    
                                            </mat-select>                                                   
                                    </mat-form-field>                                                                  
                                </div>
                                <div class="Padding5px" *ngIf="mediaPortalList == undefined || mediaPortalList.length === 0">
                                    <mat-form-field>
                                        <mat-label>Media Portal &nbsp;&nbsp;</mat-label>
                                        <mat-select>
                                          <mat-option value="None">None</mat-option>                                          
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="Padding5px" *ngIf="mediaList == undefined || mediaList.length === 0">
                                    <mat-form-field>
                                        <mat-label>Media &nbsp;&nbsp;</mat-label>
                                        <mat-select>
                                          <mat-option value="None">None</mat-option>                                          
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="Padding5px" *ngIf="!customerSubscriptionLatest.IsMediaRelaxationEnabled && !customerSubscriptionLatest.IsMediaPremierEnabled">
                                Media module Subscription not available
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <div *ngIf="!pageDataLoaded" class="alignCenter" style="margin-top:20px;text-align: center;">
        <div>Loading...</div>
    </div>
</div>
