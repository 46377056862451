// Angular
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

// Shared Lib
import { HandleErrorService, ApplicationConfig, ApiService, EnvService } from 'kscigcorelib';


@Injectable({
  providedIn: 'root'
})
export class MediaSettingsService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService: ApiService,
    private envService: EnvService
  ) { }


  
  public getAllMediaApplicationConfigs(): Observable<any> {
    return this.http.get<ApplicationConfig[]>(this.envService.apiUrl + '/MediaSetting/getAllConfigs', this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('getAllMediaApplicationConfigs', []))
    );
  }

  public getMediaApplicationConfigByName(name: string): Observable<any> {
    return this.http.get<ApplicationConfig>(this.envService.apiUrl + '/MediaSetting/getConfigByName?name=' + name, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('getMediaApplicationConfigByName', []))
    );
  }

  public getMediaApplicationConfigsByCategory(category: string): Observable<any> {
    return this.http.get<ApplicationConfig>(this.envService.apiUrl + '/MediaSetting/getConfigsByCategory?category=' + category, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('getMediaApplicationConfigsByCategory', []))
    );
  }

  public setMediaApplicationConfig(applicationConfig: ApplicationConfig): Observable<any> {
    return this.http.put(this.envService.apiUrl + '/MediaSetting/setConfig', this.apiService.createApiRequestPayload(applicationConfig), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('setMediaApplicationConfig', []))
    );
  }

  public syncMedia(mediaModuleType: string): Observable<any> {
    return this.http.post(this.envService.apiUrl + '/Media/syncMedia?mediaModuleType=' + mediaModuleType, null, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('syncMedia', []))
    );
  }
}
