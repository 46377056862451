// Angular
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

// Shared Lib
import { Notification, HandleErrorService, ApiService, EnvService } from 'kscigcorelib';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService:ApiService,
    private envService: EnvService
  ) { }

  public getNotifications(): Observable<any> {
    return this.http.get<Notification[]>(this.envService.apiUrl + '/Notification/Get', this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('getNotifications', []))
    );
  }

  public addNotification(notification: Notification): Observable<any> {
    return this.http.post(this.envService.apiUrl + '/Notification/Add', this.apiService.createApiRequestPayload(notification, false), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('addNotification', []))
    );
  }

  public updateNotification(notification: Notification): Observable<any> {
    return this.http.put(this.envService.apiUrl + '/Notification/Update', this.apiService.createApiRequestPayload(notification, false), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('updateNotificationId', []))
    );
  }

  public deleteNotification(notificationId: string): Observable<any> {
    return this.http.delete<boolean>(this.envService.apiUrl + '/Notification/Delete?notificationId=' + notificationId, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('deleteNotification', []))
    );
  }
}
