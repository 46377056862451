<h2 mat-dialog-title class="DialogCaptionLabel">Notification Editor</h2>
<mat-dialog-content>
  <div class="alignCenter">
    <mat-form [formGroup]="notificationEditorForm">
      <mat-form-field style="width: 350px;">
        <mat-label>Title</mat-label>
        <input matInput type="text" formControlName="title" style="width: 680px !important;"/>
        <mat-error>
          <div *ngIf="title.hasError('required')">
            Required
          </div>
          <div *ngIf="title.hasError('maxlength')">
            1024 chars only
          </div>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field style="width: 650px;">
          <mat-label>Detail</mat-label>
          <input matInput type="text" formControlName="detail"/>
          <mat-error>
            <div *ngIf="detail.hasError('required')">
              Required
            </div>
            <div *ngIf="detail.hasError('maxlength')">
              4096 chars only
            </div>
          </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field style="width: 150px;">
        <mat-label>Start Date</mat-label>
        <input matInput type="text" [matDatepicker]="startDateTimedatepicker" formControlName="startDateTime" />
        <mat-datepicker #startDateTimedatepicker></mat-datepicker>
        <mat-error>
          <div *ngIf="startDateTime.hasError('required')">
            Required
          </div>
        </mat-error>
      </mat-form-field>
      <mat-datepicker-toggle matSuffix [for]="startDateTimedatepicker"></mat-datepicker-toggle>
      <mat-form-field style="width: 80px">
        <mat-label>HH</mat-label>
        <mat-select style="display: inline;" formControlName="startTimeHour">
            <mat-option *ngFor="let startTimeHr of startTimeHourList" [value]="startTimeHr.key">
                {{startTimeHr.value}}
              </mat-option>
        </mat-select>
        <mat-error>
          <div *ngIf="startTimeHour.hasError('required')">
            Required
          </div>
        </mat-error>
      </mat-form-field>:
      <mat-form-field style="width: 80px;">
        <mat-label>MM</mat-label>
        <mat-select style="display: inline;" formControlName="startTimeMinute">
            <mat-option *ngFor="let startTimeMin of startTimeMinuteList" [value]="startTimeMin.key">
                {{startTimeMin.value}}
              </mat-option>
        </mat-select>
        <mat-error>
          <div *ngIf="startTimeMinute.hasError('required')">
            Required
          </div>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field style="width: 150px;">
          <mat-label>Expire Date</mat-label>
          <input matInput type="text" [matDatepicker]="expireDateTimedatepicker" formControlName="expireDateTime"/>
          <mat-datepicker #expireDateTimedatepicker></mat-datepicker>
          <mat-error>
            <div *ngIf="expireDateTime.hasError('required')">
              Required
            </div>
          </mat-error>
      </mat-form-field>
      <mat-datepicker-toggle matSuffix [for]="expireDateTimedatepicker"></mat-datepicker-toggle>
      <mat-form-field style="width: 80px;">
        <mat-label>HH</mat-label>
        <mat-select style="display: inline;" formControlName="expireTimeHour">
            <mat-option *ngFor="let expireTimeHr of expireTimeHourList" [value]="expireTimeHr.key">
                {{expireTimeHr.value}}
              </mat-option>
        </mat-select>
        <mat-error>
          <div *ngIf="expireTimeHour.hasError('required')">
            Required
          </div>
        </mat-error>
      </mat-form-field>:
      <mat-form-field style="width: 80px;">
        <mat-label>MM</mat-label>
        <mat-select style="display: inline;" formControlName="expireTimeMinute">
            <mat-option *ngFor="let expireTimeMin of expireTimeMinuteList" [value]="expireTimeMin.key">
                {{expireTimeMin.value}}
              </mat-option>
        </mat-select>
        <mat-error>
          <div *ngIf="expireTimeMinute.hasError('required')">
            Required
          </div>
        </mat-error>
      </mat-form-field>
      <mat-error>
        <div *ngIf="notificationEditorForm.hasError('fromToDate')">
          Start date cannot be after expire date
        </div>
      </mat-error>
    </mat-form>
  </div>
</mat-dialog-content>
  
<mat-dialog-actions class="alignCenter">
  <button mat-raised-button class="btn-icon-default" style="margin-left:5px;" (click)="closeDialog(false)">Cancel
    <mat-icon>cancel</mat-icon>
  </button>   
  <button mat-raised-button class="btn-icon-default" style="margin-left:10px;margin-right: 5px;" [disabled]="!notificationEditorForm.dirty" (click)="saveNotification()">Save
    <mat-icon>save</mat-icon>
  </button>
</mat-dialog-actions>