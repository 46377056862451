<h2 mat-dialog-title class="DialogCaptionLabel">
    Email Provider Editor
  </h2>
  <mat-dialog-content>
  
    <div class="alignCenter">
      <mat-form [formGroup]="notifyEmailProviderForm">
        <mat-form-field>
          <mat-label>Provider Name</mat-label>
          <input matInput type="text" formControlName="providerName"/>
          <mat-error>
            <div *ngIf="providerName.hasError('required')">
              Required
            </div>
            <div *ngIf="providerName.hasError('maxlength')">
              50 chars only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Token</mat-label>
          <input matInput type="text" formControlName="token"/>
          <mat-error>
            <div *ngIf="token.hasError('maxlength')">
              250 chars only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>SMTP Server</mat-label>
          <input matInput type="text" formControlName="smtpServer"/>
          <mat-error>
            <div *ngIf="smtpServer.hasError('maxlength')">
              100 chars only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Username</mat-label>
          <input matInput type="text" formControlName="username"/>
          <mat-error>
            <div *ngIf="username.hasError('maxlength')">
              50 chars only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input matInput type="text" formControlName="password"/>
          <mat-error>
            <div *ngIf="password.hasError('maxlength')">
              50 chars only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>From Address</mat-label>
          <input matInput type="text" formControlName="fromAddress"/>
          <mat-error>
            <div *ngIf="fromAddress.hasError('maxlength')">
              50 chars only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field style="width: 120px;">
          <mat-label>Port</mat-label>
          <input matInput type="text" formControlName="port"/>
          <mat-error>
            <div *ngIf="port.hasError('pattern')">
              Number only
            </div>
          </mat-error>
        </mat-form-field>
        <label>&nbsp;&nbsp;&nbsp;&nbsp;Encryption:&nbsp;&nbsp;</label>
        <mat-slide-toggle matInput formControlName="isEncrypted" 
                          [checked]="isEncrypted">
        </mat-slide-toggle>
        <br>
        <mat-form-field style="width: 120px;">
          <mat-label>Priority</mat-label>
          <input matInput type="text" formControlName="priority"/>
          <mat-error>
            <div *ngIf="priority.hasError('pattern')">
              Number only
            </div>
          </mat-error>
        </mat-form-field>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <mat-form-field style="width: 120px;">
          <mat-label>Price Per Email</mat-label>
          <input matInput type="text" formControlName="pricePerEmail"/>
          <mat-error>
            <div *ngIf="pricePerEmail.hasError('pattern')">
              Number only
            </div>
          </mat-error>
        </mat-form-field>
        <br>
      </mat-form>
    </div>
  
  </mat-dialog-content>
  
  <mat-dialog-actions class="alignCenter">
    <button mat-raised-button class="btn-icon-default" style="margin-left:5px;" (click)="closeDialog(false)">Cancel
      <mat-icon>cancel</mat-icon>
    </button>   
    <button mat-raised-button class="btn-icon-default" style="margin-left:10px;margin-right: 5px;" [disabled]="!notifyEmailProviderForm.dirty" (click)="saveEmailProvider()">Save
      <mat-icon>save</mat-icon>
    </button>
  </mat-dialog-actions>