// Angular
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";

// Shared Lib
import { CoreHelper, LoggingService, Notification } from 'kscigcorelib';
import { coreAppPageMode } from 'kscigcorelib';

// Application Core
import { RouteHelper } from '../shared/helpers/route.helper';
import { SessionHelper } from '../shared/helpers/session.helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

  @Output() public sidenavToggle = new EventEmitter();

  public username:string;
  public userRole:string;
  public coreAppPageModeEnum = coreAppPageMode;
  public coreAppPageMode = coreAppPageMode.none;
  public isUserLoggedIn = false;
  public productName:string = "";
  
  public showProgressBar:boolean = false;
  public patModeEnabled = false;
  public notifications:Notification[] = [];
  public notificationCount:number = 0;

  constructor(
    private coreHelper: CoreHelper,
    private routeHelper: RouteHelper,
    private sessionHelper: SessionHelper,
    private loggingService:LoggingService,
    router: Router) { 
      router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.initHeader();
        }
      });
    }

  ngOnInit() {
    this.initHeader();
  }

  initHeader(){
    this.coreAppPageMode = this.coreHelper.getApplicationPageMode();
    this.productName = environment.productName;
    this.isUserLoggedIn = this.sessionHelper.isValidUserSession();
    if(this.isUserLoggedIn){
      var userInfo = this.sessionHelper.getLoggedInUser();
      if(userInfo != null && userInfo != undefined){
        this.username = userInfo.Name;
        this.userRole = this.coreHelper.getRoleDisplayName(userInfo.Role);
      }
    } else {
      this.loggingService.logVerbose("No Profile fetched as user is not logged in");
    }
    if(this.coreAppPageMode == this.coreAppPageModeEnum.appui){
      this.showProgressBar = false;
    }
  }

  login(){
    this.routeHelper.NavigateToLoginPage();
  }

  public logout(){
    this.routeHelper.NavigateToLogoutPage();
  }

  navigateToApp(){
    this.routeHelper.NavigateToApp();
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }
}