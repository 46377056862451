// Angular
import { Component, OnInit } from '@angular/core';
import { KeyValue } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

// Material
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

// Shared Lib
import { LoadingBarService, NotificationBarService, LoggingService, CoreHelper, CloudApiResponse, EncryptionService, SessionInfo, notifyConfigNames, MediaPortal } from 'kscigcorelib';
import { MessageBoxComponent, MessageBoxModel, MessageBoxType, WLSMSConsentFilter, WLTimeFilter } from 'kscigcorelib';
import { Contact, Customer, CustomerConnectionInfo, CustomerSubscription, CustomerLanguagePreference, UserInfo } from 'kscigcorelib';
import { MediaModel, CustomerDefaultMediaPreference, EnumMediaModuleType , ApplicationConfig} from 'kscigcorelib';

// Module
import { ContactEditorData, OrderByOptionEnum, NotifySettingsEditorData, Message } from './customer-model';
import { CustomerService } from './customer.service';
import { WorklistService } from './worklist.service';
import { NotifyService } from './notify.service';
import { SettingsService } from '../settings/settings.service';
import { CustomerMediaService } from './customer-media.service';
import { CustomerEditorComponent } from './customer-editor/customer-editor.component';
import { CustomerContactEditorComponent } from './customer-contact-editor/customer-contact-editor.component';
import { CustomerSubscriptionEditorComponent } from './customer-subscription-editor/customer-subscription-editor.component';
import { CustomerConnectionInfoEditorComponent} from './customer-connection-info-editor/customer-connection-info-editor.component';
import { UserSessionEditorComponent} from './user-session-editor/user-session-editor.component';
import { WorklistSettingsEditorComponent} from './worklist-settings-editor/worklist-settings-editor.component';
import { NotifySettingsEditorComponent} from './notify-settings-editor/notify-settings-editor.component';
import { WorklistEditorData } from './worklist-model';
import { UserService } from '../shared/services/user.service';
import { ListKeyManager } from '@angular/cdk/a11y';


@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {

  public pageDataLoaded:boolean = false;
  public selectedCustomerId:string = null;
  public customerList: Customer[] = [];
  public contactList: Contact[] = [];
  public customerConnectionInfo:CustomerConnectionInfo = new CustomerConnectionInfo();
  public customerSubscriptions:CustomerSubscription[] = [];
  public customerSubscriptionCurrent:CustomerSubscription = new CustomerSubscription();
  public customerSubscriptionLatest: CustomerSubscription = new CustomerSubscription();
  public domainUsers:UserInfo[] = [];
  public wlSMSConsentFilters:WLSMSConsentFilter[] = [];
  public wlTimeFilters:WLTimeFilter[] = [];
  public globalNotifySettings: ApplicationConfig[] = []; //GlobalNotifySettings = new GlobalNotifySettings();
  public customerNotifyConfigs:ApplicationConfig[] = []; 
  public customerLanguagePreference:CustomerLanguagePreference[] = [];
  public orderByOptions:KeyValue<OrderByOptionEnum,string>[] = [];
  public orderBy:OrderByOptionEnum;
  public isShowBasicSettings:boolean;
  public isShowAdvanceSettings:boolean;
  public mediaPortalList: MediaPortal[];
  public customerMediaPortalId: string;
  public customerDefaultMediaPreference: CustomerDefaultMediaPreference = new CustomerDefaultMediaPreference();  
  public mediaList: MediaModel[];
  public isSMSOptInEnabled:boolean = false;
  public isFilteredResult:boolean = false;
  public isFilteredResultOn:boolean = true;
  private queryString_filter:string = "";
  public welcomeMessage:Message = null;
  public welcomeMessageText:string = "";
  public messageList: Message[] = [];

  constructor( 
    private loggingService:LoggingService,
    private loadingBarService: LoadingBarService,
    private notificationBarService:NotificationBarService,
    private encryptionService:EncryptionService,
    private coreHelper:CoreHelper,
    private customerService: CustomerService,
    private worklistService: WorklistService,
    private notifyService: NotifyService,
    private customerMediaService: CustomerMediaService,
    private settingsService: SettingsService,
    private domainUserService: UserService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute
    ) { 
      this.isShowBasicSettings = false;
      this.isShowAdvanceSettings = false;
    }

  ngOnInit(): void {
     // Start loading bar
     this.loadingBarService.startBar();
     // Load Page Data
    this.loadPageData();
  }

  private loadPageData(){
    this.loadPageConstants();
    this.queryString_filter = this.activatedRoute.snapshot.queryParamMap.get("filter");
    if(this.queryString_filter == "1"){
      this.isFilteredResult = true;
    } else {
      this.isFilteredResult = false;
    }
    this.loadCustomerData(this.isFilteredResult);
  }

  private loadCustomerData(isFilteredResult:boolean){
    if(isFilteredResult){
      this.loggingService.logVerbose("Filtered Customer list");
      let queryString_groupBy:string = this.activatedRoute.snapshot.queryParamMap.get("groupBy");
      let queryString_groupByItem:string = this.activatedRoute.snapshot.queryParamMap.get("groupByItem");

      let queryString_installDate = this.activatedRoute.snapshot.queryParamMap.get("installDate");
      let queryString_time = this.activatedRoute.snapshot.queryParamMap.get("time");
      let queryString_state = this.activatedRoute.snapshot.queryParamMap.get("state");

      this.loggingService.logVerbose("groupBy: " + queryString_groupBy);
      this.loggingService.logVerbose("groupByItem: " + queryString_groupByItem);
      this.loggingService.logVerbose("installDate: " + queryString_installDate);
      this.loggingService.logVerbose("time: " + queryString_time);
      this.loggingService.logVerbose("state: " + queryString_state);

      if(queryString_groupBy != null && queryString_groupByItem != null){
        this.getCustomersGroupBy(queryString_groupBy, queryString_groupByItem);
      }
      if(queryString_installDate != null && queryString_time != null && queryString_state != null) {
        this.getCustomersByInstallDate(queryString_installDate, queryString_time, queryString_state);
      }
    } else  {
      this.loggingService.logVerbose("All Customer list");
      this.getCustomers();
    }
  }

  private getCustomers(){
    this.customerService.getCustomers(false)
      .subscribe({
          next: (result: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
                let customers: Customer[] = JSON.parse(decryptedPayload);  
                this.loggingService.logVerbose(customers);
                this.customerList = customers;
                this.loadingBarService.stopBar();
                this.pageDataLoaded = true;
              },
          error: () => { this.loggingService.logError("Error loading Customers"); },
          complete: () => { this.loggingService.logVerbose("Completed loading Customers"); }
      });
  }

  private getCustomersGroupBy(groupBy:string, groupByItem:string){
    this.customerService.getCustomersForGroupByData(groupBy, groupByItem)
      .subscribe({
          next: (result: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
                let customers: Customer[] = JSON.parse(decryptedPayload);  
                this.loggingService.logVerbose(customers);
                this.customerList = customers;
                this.loadingBarService.stopBar();
                this.pageDataLoaded = true;
              },
          error: () => { this.loggingService.logError("Error getting customers for group by data"); },
          complete: () => { this.loggingService.logVerbose("Completed getting customers for group by data"); }
      });
  }

  private getCustomersByInstallDate(installDate: string, time:string, state:string){
    installDate = (installDate.toLowerCase()).replace(' ', '').replace(' ', '');
    time = (time.toLowerCase()).replace(' ', '').replace(' ', '');
    state = (state.toLowerCase()).replace(' ', '').replace(' ', '');
    this.customerService.getCustomersForInstallData(installDate, time, state)
      .subscribe({
          next: (result: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
                let customers: Customer[] = JSON.parse(decryptedPayload);   
                this.loggingService.logVerbose(customers);
                this.customerList = customers;
                this.loadingBarService.stopBar();
                this.pageDataLoaded = true;
              },
          error: () => { this.loggingService.logError("Error fetching customers by install date"); },
          complete: () => { this.loggingService.logVerbose("Completed fetching customers by install date"); }
      });
  }

  private loadPageConstants(){
    let _orderByOptions:KeyValue<OrderByOptionEnum,string>[] = [];
    _orderByOptions.push({key:OrderByOptionEnum.NameAsc,value:"Name Asc"});
    _orderByOptions.push({key:OrderByOptionEnum.NameDesc,value:"Name Desc"});
    _orderByOptions.push({key:OrderByOptionEnum.GoLiveDateAsc,value:"Go Live Date Asc"});
    _orderByOptions.push({key:OrderByOptionEnum.GoLiveDateDesc,value:"Go Live Date Desc"});
    _orderByOptions.push({key:OrderByOptionEnum.InstallStartAsc,value:"Install Start Asc"});
    _orderByOptions.push({key:OrderByOptionEnum.InstallStartDesc,value:"Install Start Desc"});
    this.orderByOptions = _orderByOptions;
    this.orderBy = OrderByOptionEnum.NameAsc;
  }

  public onExpansionPanelOpen(customerId:string){
    this.isShowBasicSettings = true;
    this.selectedCustomerId = customerId;
    // get customer data
    this.getCustomerData(customerId);
    // get customer advance data
    this.getCustomerAdvanceData(customerId);
  }

  public onChangeBasicSettings(event:MatSlideToggleChange){
    if(event.checked){
      this.loggingService.logVerbose("Basic setting opened");
    } else{
      this.loggingService.logVerbose("Basic setting closed");
    }
  }

  public onChangeAdvanceSettings(event:MatSlideToggleChange){
    if(event.checked){
      this.loggingService.logVerbose("Advance setting opened");
    } else{
      this.loggingService.logVerbose("Advance setting closed");
    }
  }

  public getCustomerData(customerId:string){
    this.getCustomerContacts(customerId);
    this.getCustomerSubscriptions(customerId);  
    this.getDomainUsers(customerId);
    this.getNotifyMessages(customerId);    
  }

  public getCustomerAdvanceData(customerId:string) {    
      this.getCustomerConnectionInfo(customerId);
      this.getCustomerNotifyWelcomeMessage(customerId);
      this.getCustomerNotifyConfigs(customerId);
      this.getCustomerNotifyLanguagePreferences(customerId);
      this.getCustomerDefaultMediaPreference(customerId);
      this.getCustomerMediaPortal(customerId);
      this.getWLTimeFilter(customerId);
      this.getWLSMSConsentFilter(customerId);    
  }

  private getCustomerContacts(customerId:string){
    this.contactList = [];
    this.customerService.getCustomerContacts(customerId)
      .subscribe({
          next: (contactsResult: CloudApiResponse) => {
                this.loggingService.logVerbose(contactsResult);
                var decryptedContactPayload = this.encryptionService.decryptUsingAES256(contactsResult.payload);
                this.contactList = JSON.parse(decryptedContactPayload);
                this.loggingService.logVerbose(this.contactList);
              },
          error: () => {
                  this.loggingService.logError("Error Fetching Customer Contacts");
                  this.notificationBarService.showError("Error Fetching Customer Contacts");
              },
          complete: () => { this.loggingService.logVerbose("Completed Fetching Customer Contacts"); }
      });
  }

  private getCustomerSubscriptions(customerId:string){
    this.customerSubscriptions = [];
    
    this.customerService.getCustomerSubscriptions(customerId)
      .subscribe({
          next: (result: CloudApiResponse) => {  
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
                let customerSubscriptions: CustomerSubscription[] = JSON.parse(decryptedPayload);   
                this.loggingService.logVerbose(customerSubscriptions);
                this.customerSubscriptions = customerSubscriptions;  

                if (this.customerSubscriptions.length > 0) {
                  this.customerSubscriptionLatest = this.customerSubscriptions[0];
                  if (this.customerSubscriptionLatest.IsMediaPremierEnabled) {
                    this.getCustomerMediaPortal(customerId);
                    this.getCustomerActiveMedia(customerId);                    
                  }
                }                            
              },
          error: () => {
                this.loggingService.logError("Error Fetching Customer Subscriptions");
                this.notificationBarService.showError("Error Fetching Subscriptions");  
              },
          complete: () => { this.loggingService.logVerbose("Completed Fetching Customer Subscriptions"); }
      });
  }

  private getDomainUsers(customerId:string){
    this.domainUsers = [];
    
    this.domainUserService.getUsers(customerId)
      .subscribe({
          next: (result: CloudApiResponse) => {        
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
                this.loggingService.logVerbose(decryptedPayload);
                this.domainUsers = JSON.parse(decryptedPayload);                                                
              },
          error: () => {
                this.loggingService.logError("Error Fetching Domain Users");
                this.notificationBarService.showError("Error Fetching Domain Users");  
              },
          complete: () => { this.loggingService.logVerbose("Completed Fetching Domain Users"); }
      });
  }

  private getCustomerConnectionInfo(customerId:string){
    this.customerConnectionInfo = new CustomerConnectionInfo();
    this.customerService.getCustomerConnectionInfo(customerId)
      .subscribe({
          next: (result: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
                this.loggingService.logVerbose(decryptedPayload);
                this.customerConnectionInfo = JSON.parse(decryptedPayload);                               
              },
          error: () => {
                this.loggingService.logError("Error Fetching Customer Connections");
                this.notificationBarService.showError("Error Fetching Customer Connections");  
              },
          complete: () => { this.loggingService.logVerbose("Completed Fetching Customer Connections"); }
      });
  }

  private getWLSMSConsentFilter(customerId:string){
    this.wlSMSConsentFilters = [];
    this.worklistService.getWLSMSConsentFilter(customerId)
      .subscribe({
          next: (result) => {
                let wlConsentFilterList:WLSMSConsentFilter[] = result;
                this.loggingService.logVerbose(wlConsentFilterList);
                this.wlSMSConsentFilters = wlConsentFilterList;
              },
          error: () => {
                this.loggingService.logVerbose("Error Fetching WLSMSConsentFilter");
                this.notificationBarService.showError("Error Fetching Consent Filters");
              },
          complete: () => { this.loggingService.logVerbose("Completed Fetching Consent Filters"); }
      });
  }

  private getWLTimeFilter(customerId:string){
    this.wlTimeFilters = [];
    this.worklistService.getWLTimeFilter(customerId)
      .subscribe({
          next: (result) => {
                let wlTimeFilterList:WLTimeFilter[] = result;
                this.loggingService.logVerbose(wlTimeFilterList);
                this.wlTimeFilters = wlTimeFilterList;
              },
          error: () => {
                this.loggingService.logError("Error Fetching WLTimeFilter");
                this.notificationBarService.showError("Error Fetching Time Filter");
              },
          complete: () => { this.loggingService.logVerbose("Completed Fetching Time Filter"); }
      });
  }

  private getCustomerNotifyConfigs(customerId:string){
    this.customerNotifyConfigs = [];
    this.notifyService.getCustomerNotifyConfig(customerId)
      .subscribe({
          next: (result: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
                let applicationConfigs: ApplicationConfig[] = JSON.parse(decryptedPayload);
                this.loggingService.logVerbose(applicationConfigs);
                applicationConfigs.forEach(config => {
                  if(config.Name == "IsSMSOptInEnabled"){
                    this.isSMSOptInEnabled = (config.Value == "1") ? true: false;
                  }
                });
                this.customerNotifyConfigs = applicationConfigs;
              },
          error: () => {
                this.loggingService.logError("Error Fetching Customer Notify Settings");
                this.notificationBarService.showError("Error Fetching Customer Notify Settings");  
              },
          complete: () => { this.loggingService.logVerbose("Completed Fetching Customer Notify Settings"); }
      });
  }

  private getCustomerNotifyWelcomeMessage(customerId:string){
    this.customerNotifyConfigs = [];
    this.notifyService.getCustomerWelcomeMessage(customerId)
      .subscribe({
          next: (result: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
                this.loggingService.logDebug(decryptedPayload);
                this.welcomeMessage = JSON.parse(decryptedPayload);
                if(this.welcomeMessage != null && this.welcomeMessage.MessageText != null) {
                    this.welcomeMessageText = this.welcomeMessage.MessageText;
                  }
              },
          error: () => {
                this.loggingService.logError("Error Fetching Customer Welcome Message");
                this.notificationBarService.showError("Error Fetching Customer Welcome Message");  
              },
          complete: () => { this.loggingService.logVerbose("Completed Fetching Customer Welcome Message"); }
      });
  }
  

  private getNotifyMessages(customerId:string) {
    this.notifyService.getMessage(customerId)
      .subscribe({
          next: (messageResult: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(messageResult.payload);
                this.loggingService.logDebug(decryptedPayload);
                var messages: Message[] = JSON.parse(decryptedPayload);                 
                this.messageList = messages.filter(m => m.MessageCategoryId.toLowerCase() != '336CCC86-0ADF-4981-85AD-EBBF33976CD5'.toLowerCase()
                                                      && m.LanguageName.toLowerCase() == 'english');                          
              },
          error: () => { this.loggingService.logError("Error loading messages"); },
          complete: () => { this.loggingService.logVerbose("Completed loading messages"); }
      });                
  }

  private getCustomerNotifyLanguagePreferences(customerId:string){
    this.customerLanguagePreference = [];
    this.notifyService.getCustomerLanguagePreference(customerId, false)
      .subscribe({
          next: (result: CloudApiResponse) => {
                let customerLanguagePreference: CustomerLanguagePreference[] = result.payload;
                this.loggingService.logVerbose(customerLanguagePreference);
                this.customerLanguagePreference = customerLanguagePreference;
              },
          error: () => {
                this.loggingService.logError("Error Fetching Customer Notify Language Settings");
                this.notificationBarService.showError("Error Fetching Customer Notify Language Settings");  
              },
          complete: () => { this.loggingService.logVerbose("Completed Fetching Customer Notify Language Settings"); }
      });
  }

  private getCustomerDefaultMediaPreference(customerId:string){
    
    this.customerMediaService.getCustomerDefaultMediaPreference(customerId)
      .subscribe({
          next: (result: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
                let customerDefaultMediaPreference: CustomerDefaultMediaPreference = JSON.parse(decryptedPayload);
                this.loggingService.logVerbose(customerDefaultMediaPreference);
                if (customerDefaultMediaPreference != null) {
                  this.customerDefaultMediaPreference = customerDefaultMediaPreference;
                  this.resetCustomerDefaultMediaType();
                } 
              },
          error: () => {
                this.loggingService.logError("Error Fetching Customer Default Media Preference");
                this.notificationBarService.showError("Error Fetching Customer Default Media Preference");  
              },
          complete: () => { this.loggingService.logVerbose("Completed Fetching Customer Default Media Preference"); }
      });
  }

  /**
   * Gets Active Media for latest customer Subscription
   */
  private getCustomerActiveMedia(customerId: string)
  {          
    if (this.customerSubscriptionLatest.CustomerId != null) {
      
      if (this.customerSubscriptionLatest.IsMediaPremierEnabled) {

        this.customerMediaService.getActiveMedia(customerId)
          .subscribe({
              next: (result: CloudApiResponse) => {
                    var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
                    let mediaModel: MediaModel[] = JSON.parse(decryptedPayload);
                    this.loggingService.logVerbose(mediaModel);
                    this.mediaList = mediaModel;
                    this.resetCustomerDefaultMediaType();
                  },
              error: () => {
                    this.loggingService.logError("Error Fetching Active Media");
                    this.notificationBarService.showError("Error Fetching Active Media");  
                  },
              complete: () => { this.loggingService.logVerbose("Complete Fetching Active Media"); }
          });
          
      } 
    } else {
      this.loggingService.logVerbose("Customer has no active Subscription");
      this.notificationBarService.showError("Customer has no active Subscription");  
    }
  }
  
  private getCustomerMediaPortal(customerId: string) {
    
      this.customerMediaService.getMediaPortals()
        .subscribe({
            next: (result: CloudApiResponse) => {
                  var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
                  let portals: MediaPortal[] = JSON.parse(decryptedPayload);
                  this.loggingService.logVerbose(portals);
                  this.mediaPortalList = portals;
                  
                  this.customerMediaService.getCustomerMediaPortalId(customerId)
                        .subscribe({
                            next: (customerResult: CloudApiResponse) => {
                                  var decryptedPayload = this.encryptionService.decryptUsingAES256(customerResult.payload);
                                  let mediaPortalId: string = JSON.parse(decryptedPayload);
                                  this.loggingService.logVerbose(mediaPortalId);
                                  
                                  this.customerMediaPortalId = mediaPortalId;                                  
                                },
                            error: () => {
                                  this.loggingService.logError("Error Fetching Customer Media PortalId");
                                  this.notificationBarService.showError("Error Fetching Media PortalId");  
                                },
                            complete: () => { this.loggingService.logVerbose("Complete Fetching Customer Media PortalId"); }
                        });
        
                },
            error: () => {
                  this.loggingService.logError("Error Fetching Media Portals");
                  this.notificationBarService.showError("Error Fetching Media Portals");  
                },
            complete: () => { this.loggingService.logVerbose("Complete Fetching Media Portals"); }
        });
     
  }

  private resetCustomerDefaultMediaType() { 
    if (this.mediaList != undefined && this.mediaList.length > 0) {            
      let media = this.mediaList.find(x => x.MediaId === this.customerDefaultMediaPreference.MediaId);
      if (media != undefined) {
        this.customerDefaultMediaPreference.MediaTypeId = media.MediaTypeId;          
      }         
    }
  }

  public onDefaultMediaSelectionChange() {
    this.resetCustomerDefaultMediaType();
    this.saveCustomerMediaPreferences();
  }

  public onMediaPortalSelectionChange() {
    // save media portal preference
    if (this.mediaPortalList != null && this.mediaPortalList.length > 0) {
      let customerPortal = {
        ExMediaPortalId: this.customerMediaPortalId,
        CustomerId: this.selectedCustomerId
      };
      
      this.customerMediaService.setCustomerMediaPortal(customerPortal)
        .subscribe({
            next: (result: CloudApiResponse) => {                
                  this.loggingService.logVerbose("Successfully Saved Customer media portal");
                  this.notificationBarService.showSuccess("Successfully Saved Customer Media Portal");

                  this.getCustomerActiveMedia(this.selectedCustomerId); 
                },
            error: () => {
                  this.loggingService.logError("Error Saving Customer Media Portal");
                  this.notificationBarService.showError("Error Saving Customer Media Portal");  
                },
            complete: () => { this.loggingService.logVerbose("Completed Saving Customer Media Portal"); }
        });
    }
  }

  private saveCustomerMediaPreferences(){
      
    // save default media preference    
    if (this.mediaList != null && this.mediaList.length > 0) {
      this.customerDefaultMediaPreference.CustomerId = this.selectedCustomerId;
      this.customerMediaService.setCustomerDefaultMediaPreference(this.customerDefaultMediaPreference)
        .subscribe({
            next: (result: CloudApiResponse) => {                
                  this.loggingService.logVerbose("Successfully Saved Customer media preferences");
                  this.notificationBarService.showSuccess("Successfully Saved Customer Media Preferences");
                },
            error: () => {
                  this.loggingService.logError("Error Saving Customer Media Preferences");
                  this.notificationBarService.showError("Error Saving Customer Media Preferences");  
                },
            complete: () => { this.loggingService.logVerbose("Completed Saving Customer Media Preferences"); }
        });
    }
  }
  
  public onOrderBySelectionChange(event:Event){
    this.loggingService.logVerbose(this.orderBy);
    if(this.orderBy == OrderByOptionEnum.NameAsc){
      this.customerList.sort(function(a, b){
        if(a.CustomerName < b.CustomerName) { return -1; }
        if(a.CustomerName > b.CustomerName) { return 1; }
        return 0;
      });
    } else if(this.orderBy == OrderByOptionEnum.NameDesc){
      this.customerList.sort(function(a, b){
        if(a.CustomerName > b.CustomerName) { return -1; }
        if(a.CustomerName < b.CustomerName) { return 1; }
        return 0;
      });
    } else if(this.orderBy == OrderByOptionEnum.GoLiveDateAsc){
      this.customerList.sort(function(a, b){
        if(a.GoLiveDate < b.GoLiveDate) { return -1; }
        if(a.GoLiveDate > b.GoLiveDate) { return 1; }
        return 0;
      });
    } else if(this.orderBy == OrderByOptionEnum.GoLiveDateDesc){
      this.customerList.sort(function(a, b){
        if(a.GoLiveDate > b.GoLiveDate) { return -1; }
        if(a.GoLiveDate < b.GoLiveDate) { return 1; }
        return 0;
      });
    }else if(this.orderBy == OrderByOptionEnum.InstallStartAsc){
      this.customerList.sort(function(a, b){
        if(a.InstallStartDate < b.InstallStartDate) { return -1; }
        if(a.InstallStartDate > b.InstallStartDate) { return 1; }
        return 0;
      });
    } else if(this.orderBy == OrderByOptionEnum.InstallStartDesc){
      this.customerList.sort(function(a, b){
        if(a.InstallStartDate > b.InstallStartDate) { return -1; }
        if(a.InstallStartDate < b.InstallStartDate) { return 1; }
        return 0;
      });
    }
    
  }
  
  public openCustomerEditor(customer:Customer){
    this.loggingService.logVerbose("Open Customer Editor");
    this.loggingService.logVerbose(customer);
    const dialogRef = this.dialog.open(CustomerEditorComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: customer,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('Customer Editor was closed');
      if(result){
        // Reload customers
        this.loadCustomerData(this.isFilteredResult);
      }
    });
  }

  public openContactEditor(contact:Contact){
    this.loggingService.logVerbose("Open Contact Editor");
    this.loggingService.logVerbose(contact);
    let contactEditorData:ContactEditorData = new ContactEditorData();
    if(contact == null){
      // Add new
      contactEditorData.customerId = this.selectedCustomerId;
    } else {
      // Update
      contactEditorData.contactData = contact;
      contactEditorData.customerId = this.selectedCustomerId;
    }
    const dialogRef = this.dialog.open(CustomerContactEditorComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: contactEditorData,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('Contact Editor was closed');
      if(result){
        // Reload Contacts
        this.getCustomerContacts(this.selectedCustomerId);
      }
    });
  }

  public openSubscriptionEditor(customerSubscription:CustomerSubscription){
    this.loggingService.logVerbose("Open Subscription Editor");
    this.loggingService.logVerbose(customerSubscription);
    let customerSubscriptionData:CustomerSubscription = new CustomerSubscription();
    if(customerSubscription == null){
      customerSubscriptionData.CustomerId = this.selectedCustomerId;
    } else {
      customerSubscriptionData = customerSubscription;
    }
    const dialogRef = this.dialog.open(CustomerSubscriptionEditorComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: customerSubscriptionData,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('Subscription Editor was closed');
      if(result){
        // Reload Subscriptions
        this.getCustomerSubscriptions(this.selectedCustomerId);
        this.loadCustomerData(this.isFilteredResult);
      }
    });
  }
  
  public openConnectionInfoEditor(){
    var customerConnectionInfo = this.customerConnectionInfo;
    if(customerConnectionInfo.CustomerId == null)
    {
      customerConnectionInfo.CustomerId = this.selectedCustomerId;
    }
    
    this.loggingService.logVerbose("Open Connection Editor");
    this.loggingService.logVerbose(customerConnectionInfo);
    let customerConnectionInfoData:CustomerConnectionInfo = new CustomerConnectionInfo();
    if(customerConnectionInfo == null){
      customerConnectionInfoData.CustomerId = this.selectedCustomerId;
      customerConnectionInfoData.IdleTimeoutMinutes = 20;
    } else {
      customerConnectionInfoData = customerConnectionInfo;
    }
    const dialogRef = this.dialog.open(CustomerConnectionInfoEditorComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: customerConnectionInfoData,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('Connection Editor was closed');
      if(result){
        // Reload Subscriptions
        this.getCustomerConnectionInfo(this.selectedCustomerId);
      }
    });
  }

  public openWorklistSettingsEditor(){
    this.loggingService.logVerbose("Open WL Editor");
    var wlEditorData:WorklistEditorData = new WorklistEditorData();
    wlEditorData.customerId = this.selectedCustomerId;
    wlEditorData.wlSMSConsentFilters = this.wlSMSConsentFilters;
    wlEditorData.wlTimeFilters = this.wlTimeFilters;
    const dialogRef = this.dialog.open(WorklistSettingsEditorComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: wlEditorData,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('WL Editor was closed');
      if(result){
        // Reload notifySettings
        this.getWLSMSConsentFilter(this.selectedCustomerId);
        this.getWLTimeFilter(this.selectedCustomerId);
      }
    });
  }

  public openNotifySettingsEditor(){
    this.loggingService.logVerbose("Open Notify Editor");
    let notifySettingsEditorData = new NotifySettingsEditorData();
    notifySettingsEditorData.customerId = this.selectedCustomerId;
    notifySettingsEditorData.customerNotifyConfigs = this.customerNotifyConfigs;
    notifySettingsEditorData.message = this.welcomeMessage;
    notifySettingsEditorData.customerLanguagePreference = this.customerLanguagePreference;
    notifySettingsEditorData.messageList = this.messageList;
    //notifySettingsEditorData.globalNotifySettings = this.globalNotifySettings;
    //this.loggingService.logVerbose(this.customerNotifyConfigs);
    const dialogRef = this.dialog.open(NotifySettingsEditorComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: notifySettingsEditorData,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('Notify Editor was closed');
      if(result){
        // Reload notifySettings
        this.getCustomerNotifyConfigs(this.selectedCustomerId);
        this.getCustomerNotifyLanguagePreferences(this.selectedCustomerId);
        this.getCustomerNotifyWelcomeMessage(this.selectedCustomerId);
      }
    });
  }

  public deleteCustomerContact(contact:Contact){
    this.loggingService.logInformation(contact);

    let messageBoxData:MessageBoxModel = new MessageBoxModel();
    messageBoxData.message = "Are you sure you want to delete '" + contact.FirstName + "," + contact.LastName + "'?";
    messageBoxData.messageBoxType = MessageBoxType.yesno;
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: messageBoxData,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('Delete dialog was closed');
      if(result){
        this.loggingService.logVerbose("Delete initiated");
        this.customerService.deleteCustomerContact(contact.ContactId)
          .subscribe({
             next: (result:CloudApiResponse) => {
                    let deleted: boolean = result.payload;
                    this.notificationBarService.showSuccess("Deleted " + contact.FirstName + " " + contact.LastName)
                    this.loggingService.logVerbose("Deleted " + contact.FirstName + " " + contact.LastName);
                    // Reload Customer Contacts
                    this.getCustomerContacts(this.selectedCustomerId);
                  },
             error: () => {
                    this.notificationBarService.showError("Error deleting " +  contact.FirstName + " " + contact.LastName);
                    this.loggingService.logError("Error deleting contact " +  contact.FirstName  + " " + contact.LastName);
                  },
             complete: () => { this.loggingService.logVerbose("Completed deleting contact " +  contact.FirstName  + " " + contact.LastName); }
          });
      } else {
        this.loggingService.logVerbose("Delete was cancelled");
      }
    });
  }

  public deleteCustomerSubscription(customerSubscription:CustomerSubscription){
    this.loggingService.logInformation(customerSubscription);
    let subscriptionStartDateOnly = this.getDateOnly(customerSubscription.SubscriptionStartDate);
    let messageBoxData:MessageBoxModel = new MessageBoxModel();
    messageBoxData.message = "Are you sure you want to delete Subscription staring from '" + subscriptionStartDateOnly + "'?";
    messageBoxData.messageBoxType = MessageBoxType.yesno;
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: messageBoxData,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The dialog was closed');
      if(result){
        this.loggingService.logVerbose("Delete initiated");
        this.customerService.deleteCustomerSubscription(customerSubscription.CustomerSubscriptionId)
          .subscribe({
               next: (result:CloudApiResponse) => {
                    let deleted: boolean = result.payload;
                    this.notificationBarService.showSuccess("Deleted Subscription " + subscriptionStartDateOnly)
                    this.loggingService.logVerbose("Deleted Subscription " + subscriptionStartDateOnly);
                    // Reload Customer Contacts
                    this.getCustomerSubscriptions(customerSubscription.CustomerId);
                  }, 
               error: () => {
                    this.notificationBarService.showError("Error deleting Subscription " + subscriptionStartDateOnly);
                    this.loggingService.logError("Error deleting Subscription " + subscriptionStartDateOnly);
                  },
               complete: () => { this.loggingService.logVerbose("Completed deleting Subscription " + subscriptionStartDateOnly); }
          });
      } else {
        this.loggingService.logVerbose("Delete was cancelled");
      }
    });
  }

  public deleteCustomer(customer:Customer){
    this.loggingService.logInformation(customer);

    let messageBoxData:MessageBoxModel = new MessageBoxModel();
    messageBoxData.message = "This action will delete the customer and all its details from the database. Are you sure you want to delete the customer '" + customer.CustomerName + "'?";
    messageBoxData.messageBoxType = MessageBoxType.yesno;
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: messageBoxData,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The dialog was closed');
      if(result){
        this.loggingService.logVerbose("Delete initiated");
        this.customerService.deleteCustomer(customer.CustomerId)
          .subscribe({
              next: (result:CloudApiResponse) => {
                    let deleted: boolean = result.payload;
                    this.notificationBarService.showSuccess("Deleted " + customer.CustomerName)
                    this.loggingService.logVerbose("Deleted " + customer.CustomerName);
                    // Reload Customer Contacts
                    this.loadCustomerData(this.isFilteredResult);
                  },
              error: () => {
                    this.notificationBarService.showError("Error deleting " + customer.CustomerName);
                    this.loggingService.logError("Error deleting " + customer.CustomerName);
                  },
              complete: () => { this.loggingService.logVerbose("Completed deleting " + customer.CustomerName); }
          });
      } else {
        this.loggingService.logVerbose("Delete was cancelled");
      }
    });
  }
  
  public setCustomerStatus(customer:Customer, isActivate:boolean){
    this.loggingService.logInformation(customer);
    let status = 'deactivate';
    if(isActivate) {
      status = 'activate'
    }

    let messageBoxData:MessageBoxModel = new MessageBoxModel();
    messageBoxData.message = "Are you sure you want to " + status + " '" + customer.CustomerName + "'?";
    messageBoxData.messageBoxType = MessageBoxType.yesno;
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: messageBoxData,
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('The dialog was closed');
      if(result){
        this.loggingService.logVerbose(status + " initiated");
        this.customerService.setCustomerStatus(customer.CustomerId, isActivate)
          .subscribe({
              next: (result: CloudApiResponse) => {
                    let deleted: boolean = result.payload;
                    this.notificationBarService.showSuccess(status + "d " + customer.CustomerName)
                    this.loggingService.logVerbose(status + "d " + customer.CustomerName);
                    // Reload Customer
                    this.loadPageData();
                  },
              error: () => {
                    this.notificationBarService.showError("Error " +  customer.CustomerName);
                    this.loggingService.logError("Error " +  customer.CustomerName);
                  },
              complete: () => { this.loggingService.logVerbose("Completed setting customer status for " +  customer.CustomerName); }
          });
      } else {
        this.loggingService.logVerbose(status + " was cancelled for " + customer.CustomerName);
      }
    });
  }

  public toggleFilterResult(){
    if(this.isFilteredResult){
      if(this.isFilteredResultOn){
        this.isFilteredResultOn = false;
        this.loadCustomerData(false);
      } else {
        this.isFilteredResultOn = true;
        this.loadCustomerData(true);
      }
    }
  }

  public getDateOnly(datetime:string):string{
    return this.coreHelper.getDateOnly(datetime);
  }

  public deleteUser(userId:string) { 
    this.domainUserService.deleteUser(userId)
          .subscribe({
              next: (result:CloudApiResponse) => {
                    let deleted: boolean = result.payload;
                    this.notificationBarService.showSuccess("Deleted user " + userId)
                    this.loggingService.logVerbose("Deleted user " + userId);
                    // update user status locally
                    this.domainUsers.find(m => m.UserId == userId).IsDeleted = true;
                    }, 
              error: () => {
                    this.notificationBarService.showError("Error deleting user " + userId);
                    this.loggingService.logError("Error deleting user " + userId);
                    },
              complete: () => { this.loggingService.logVerbose("Completed deleting user " + userId); }
          });
  }

  public undoDeleteUser(userId:string) {
    this.domainUserService.undoDeleteUser(userId)
          .subscribe({
              next: (result: CloudApiResponse) => {
                    let deleted: boolean = result.payload;
                    this.notificationBarService.showSuccess("Undone delete user " + userId)
                    this.loggingService.logVerbose("Undone delete user " + userId);
                    // update user status locally
                    this.domainUsers.find(m => m.UserId == userId).IsDeleted = false;
                    }, 
              error: () => {
                    this.notificationBarService.showError("Error undoing delete user " + userId);
                    this.loggingService.logError("Error undoing delete user " + userId);
                    },
              complete: () => { this.loggingService.logVerbose("Completed undoing delete user " + userId); }
          });
  }

  public showUserSessions(userId:string) {
    this.domainUserService.getUserSessions(userId)
      .subscribe({
          next: (result: CloudApiResponse) => {                
                var decryptedContactPayload = this.encryptionService.decryptUsingAES256(result.payload);
                var sessionList = JSON.parse(decryptedContactPayload);            
                this.loggingService.logVerbose(sessionList);
                this.loggingService.logVerbose("Loaded user sessions. UserId: " + userId + " UserSessions: " + sessionList);
                
                this.openUserSessionEditor(userId, sessionList);
              },
          error: () => { this.loggingService.logError("Error loading user sessions. UserId: " + userId + " and UserSessions"); },
          complete: () => { this.loggingService.logVerbose("Completed loading user sessions. UserId: " + userId + " and UserSessions"); }
      });
  }

  public openUserSessionEditor(userId:string, userSessions:SessionInfo[]){
    this.loggingService.logVerbose("Open User Session Editor");
    this.loggingService.logVerbose(userSessions);
        
    const dialogRef = this.dialog.open(UserSessionEditorComponent, {
      backdropClass: '',
      panelClass:'[{ContentBox}]',
      data: { userId: userId, userSessions: userSessions },
      minWidth: '50vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loggingService.logVerbose('Uer Session Editor was closed');
      if(result){
        // Reload user sessions
        
      }
    });
  }

}
