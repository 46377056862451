<mat-nav-list>
  <a mat-menu-item [routerLink]="'/dashboard'" (click)="onSidenavClose()"> 
    <mat-icon>home</mat-icon>Dashboard
  </a>
  <mat-divider></mat-divider>
  <a mat-menu-item [routerLink]="'/customer'" (click)="onSidenavClose()" *ngIf="isAdminUser"> 
    <mat-icon>local_hospital</mat-icon>Customers
  </a>
  <mat-divider></mat-divider>
  <a mat-menu-item [routerLink]="'/reports'" (click)="onSidenavClose()"> 
    <mat-icon>assessment</mat-icon>Utilization
  </a>
  <mat-divider></mat-divider>
  <a mat-menu-item [routerLink]="'/activity'" (click)="onSidenavClose()" *ngIf="isAdminUser"> 
    <mat-icon>list</mat-icon>Activity
  </a>
  <mat-divider></mat-divider>
  <a mat-menu-item [routerLink]="'/notifications'" (click)="onSidenavClose()" *ngIf="isAdminUser"> 
    <mat-icon>notifications</mat-icon>Notifications
  </a>
  <mat-divider></mat-divider>
  <a mat-menu-item [routerLink]="'/settings'" (click)="onSidenavClose()" *ngIf="isAdminUser"> 
    <mat-icon>settings</mat-icon>Settings
  </a>
  <mat-divider></mat-divider>
</mat-nav-list>