<div class="login-grid loginPage">
    <div></div>
    <div class="loginBox contentBox">
      <h4><mat-label>Sign In</mat-label></h4>
      <div>
        <form [formGroup]="loginForm">
          <mat-form-field style="width: 300px;">
            <mat-label>Email</mat-label>
            <input matInput placeholder="username@site.com" formControlName="email" required>
            <mat-error>
              <div *ngIf="email.hasError('required')">
                Required
              </div>
              <div *ngIf="email.hasError('email')">
                Invalid email
              </div>
            </mat-error>
  
          </mat-form-field>
          <div>
            <button mat-raised-button (click)="onLoginButtonClick()" [disabled]="!loginForm.valid" class="btn-default" style="width:300px;margin-top:8px;">Next</button>
          </div>
          <div class="login-grid-message">
            <div></div>
            <div class="{{logInMessageClass}}">
              {{logInMessage}}
            </div>
            <div></div>
          </div>
        </form>
      </div>
    </div>
    <div></div>
  </div>
  