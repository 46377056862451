<h2 mat-dialog-title class="DialogCaptionLabel">{{this.inputData.Name}}</h2>
<mat-dialog-content>
<div class="modal-drilldown-data-grid CaptionLabelSquare Padding10px">
    <div>Name</div>
    <div>Total</div>
</div>
<div *ngFor="let modalData of this.inputData.modalData">
    <div class="modal-drilldown-data-grid backgroundWhite Padding10px borderBottomBlue">
        <div>{{modalData.Name}}</div>
        <div>{{modalData.Total}}</div>
    </div>
    
</div>
</mat-dialog-content>

<mat-dialog-actions class="alignCenter">
    <button mat-raised-button class="btn-icon-default" style="margin-left:5px;" (click)="closeDialog(false)">Close
      <mat-icon>cancel</mat-icon>
    </button>   
  </mat-dialog-actions>