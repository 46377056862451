// Angular
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

// Shared Lib
import { HandleErrorService, CustomerLanguagePreference, ApiService, CloudApiRequest, CloudApiResponse, EnvService, ApplicationConfig } from 'kscigcorelib';
import { Message } from './customer-model';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor(
    private http: HttpClient,
    private handleError: HandleErrorService,
    private apiService:ApiService,
    private envService: EnvService
  ) { }

  public getCustomerNotifyConfig(customerId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/Customer/GetNotifyConfig?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get CustomerNotifyConfig data'))
    );
  }

  public setCustomerNotifyConfig(customerId: string, applicationConfig: ApplicationConfig):Observable<CloudApiResponse> {
    return this.http.put<CloudApiResponse>(this.envService.apiUrl + '/Customer/SetNotifyConfig?ecid='+ this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(applicationConfig), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Set CustomerNotifyConfig data'))
    );
  }

  public getCustomerLanguagePreference(customerId: string, enabledForNotifyOnly: boolean): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/Customer/GetLanguagePref?ecid=' + this.apiService.secureApiQueryString(customerId) + '&enabledForNotifyOnly=' + enabledForNotifyOnly, this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Customer LanguagePreference data'))
    );
  }

  public setCustomerLanguagePreference(customerId: string,languagePreference: CustomerLanguagePreference):Observable<CloudApiResponse> {
    return this.http.post<CloudApiResponse>(this.envService.apiUrl + '/Customer/SetLanguagePref?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(languagePreference), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Set Customer LanguagePreference data'))
    );
  }

  public getCustomerWelcomeMessage(customerId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/Customer/getWelcomeMsg?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get getWelcomeMessage data'))
    );
  }

  public setCustomerWelcomeMessage(customerId: string, message: Message):Observable<CloudApiResponse> {
    return this.http.post<CloudApiResponse>(this.envService.apiUrl + '/Customer/setWelcomeMsg?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.createApiRequestPayload(message), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Set Customer Welcome Message'))
    );
  }

  public getMessage(customerId: string): Observable<CloudApiResponse> {
    return this.http.get<CloudApiResponse>(this.envService.apiUrl + '/Customer/getMsgs?ecid=' + this.apiService.secureApiQueryString(customerId), this.apiService.getCustomHeader())
    .pipe(
      catchError(this.handleError.handleError<any>('Get Message data', []))
    );
  }

}
